
import {element_id, formToHeaders} from "../../table/utilities";
export let
    getHeaders = (form) => {
        let {headers_all = []} = formToHeaders(form, null)
        return headers_all.filter(item => !(['table', 'tab', 'custom', 'reference', 'heading', 'html', 'file', 'calculated'].includes(item.type) || item.table) || item.type === undefined || (item.type === 'reference' && item.disabled === false))
    },
    getFields = (headers) => {
        return headers.map(header => ({value: element_id(header.id), label: header.name}))
        // return options
    },
    getRequired = (headers) => {
        return headers.filter(item => item.required && item.required === true).map(item => ({id: element_id(item.id), name: item.name}))
    }