import {current_date} from "../../../components/utilities";
import foreign_keys from "../foreign_keys";
export const store_stocks =  {
    type: 'table',
    name: 'store_stocks',
    tab_name: 'Store Stocks',
    table: 'store_stocks',
    title: ['date', 'item', 'quantity'],
    lines: [
        [
            {name: 'Store', id: 'store_id', type: 'select', source: 'stores.description', required: true},
            {name: 'Date', id: 'date', type: 'date', required: true, defaultValue: current_date()},
            {name: 'Item', id: 'item_id', type: 'select', source: 'items.description', required: true, showInTable: true, show_pk_in_list: false},
        ], [
            {name: 'Buying Price', id: 'items.unit_cost', type: 'currency', reference: 'item_id', disabled: false, required: true},
            {name: 'Quantity', id: 'quantity', type: 'number', required: true},
            {name: 'Total', id: 'total', type: 'currency', disabled: true},
        ], [
            {name: 'Investor', id: 'investor_id', type:'select', source:'investors.name', span:1, show_pk_in_list: false, required: true},
            {name: 'Notes', id: 'notes', type: 'textarea', span:1},
            {name: 'Batch', id: 'batch_item_id', type: 'select', source: 'store_batch_items', filterable: true, filter_reference: 'item_id', options: {label: 'description'}},
            {name: 'Cleared', id: 'cleared', ...foreign_keys.yes_no_boolean, defaultValue: false}
        ]
    ]
}

export const store_batch_items = {
    type: 'table',
    name: 'store_batch_items',
    tab_name: 'Store Batch',
    table: 'store_batch_items',
    title: ['date', 'description', 'quantity'],
    lines: [
        [
            {name: 'Item', id: 'item_id', type: 'select', source: 'items.description', required: true, showInTable: true, show_pk_in_list: false},
            {name: 'Quantity', id: 'quantity', type: 'number'},
            {name: 'Buying Price', id: 'buying_price', type: 'currency'},
        ], [
            {...store_stocks, below: true}
        ]
    ]
}
export const store_batch = {
    type: 'table',
    name: 'store_batch',
    tab_name: 'Store Batch',
    table: 'store_batch',
    title: ['date', 'description', 'quantity'],
    lines: [
        [
            {name: 'Store', id: 'store_id', type: 'select', source: 'stores.description', required: true},
            {name: 'Date', id: 'date', type: 'date', required: true, defaultValue: current_date()},
            {name: 'Description', id: 'description', type: 'textarea', span:1},
        ], [
            {name: 'Items', id: 'items', type: 'multiselect', source: 'items.description', span:3, showInTable: false},
        ], [
            {name: 'Items Bought', id: 'items_bought', type: 'textarea', showInModal: false}
        ], [
            {name: 'Quantity', id: 'quantity', type: 'number', required: true},
            {name: 'Total Cost', id: 'buying_price', type: 'currency'},
        ], [
            {...store_batch_items, below: true}
        ]
    ]
}

export default store_stocks