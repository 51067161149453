import budget_allocation_form from "./budget_allocation_form";
import hod_budget_allocation_form from "./hod_budget_allocation_form";
import {Actions} from "../../../../../components/action_utilities";
import {can} from "../../../../roles";


let handleBudgetApproval = (e, record) => {
    let {status, date_approved, ...others_record} = record
    return {
        form_state: 'update',
        record: others_record,
        form: budget_allocation_form,
    }
}
let handleAllocateBudget = (e, record) => {
    let {status, date_approved, ...others_record} = record
    return {
        form_state: 'update',
        record: others_record,
        form: hod_budget_allocation_form,
    }
}

export default Actions(record => [
        record.budget_id!=null && record.department_budget && {name: (record.allocated_amount!==null || record.allocated_amount!==0)? 'UPDATE ALLOCATION':'ALLOCATE BUDGET', fa: 'pencil', action: e => handleBudgetApproval(e, record), color: 'primary', roles: can('allocate', 'budget')}, //finance
    //HODs
        record.budget_allocated && (record.sub_department_code===record.current_user_sub_department) && {name: (record.allocated_amount!==null || record.allocated_amount!==0) ? 'REVIEW ALLOCATED':'ALLOCATE BUDGET', fa: 'pencil', action: e => handleAllocateBudget(e, record), color: 'primary', roles: can('allocate_budget', 'budget_vote_heads')},

    ]
)