

export const vehicle_model = {
    type: 'table',
    name: 'vehicle_model',
    tab_name: 'Vehicle Model',
    table: 'vehicle_model',
    fa: 'car-bus',
    lines: [
        [
            {name: 'Vehicle Make', id: 'make', type: 'select', source: 'vehicle_make.make'},
            {name: 'Vehicle Model', id: 'model', type: 'text'},
            {name: 'Battery Type', id: 'item_id', type: 'multiselect', source: 'items.description', show_pk_in_list: false}
        ]
    ]
}

export const vehicle_make = {
    type: 'table',
    name: 'vehicle_make',
    tab_name: 'Vehicle Make',
    table: 'vehicle_make',
    fa: 'car',
    lines: [
        [{name: 'Vehicle Make', id: 'make', type: 'text', span: 3}],
        [vehicle_model]
    ]
}