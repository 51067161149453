const initState = {
    title: null,
    target: null,
    table_data: null,
    page_items: null,
    target_title: [],
    variables: {},
    max_modal: true
}

const pageReducer = (state=initState, action) => {
    switch(action.type){
        case 'TABLE':
            return {
                ...state,
                status: 'Record updated successfully'
            }
        case 'SET_TARGET':
            return {
                ...state,
                target: action.target
            }
        case 'SET_TITLE':
            return {
                ...state,
                title: action.title
            }
        case 'SET_TARGET_TITLE':
            let target_title = state.target_title
            target_title = target_title.filter(item=>item.target !== action.params.target)
            target_title = [...target_title, action.params]
            // console.log('setting titles', target_title.length)
            return {
                ...state,
                target_title
            }
        case 'TABLE_DATA':
            return {
                ...state,
                table_data: action.data
            }
        case 'SET_FORM':
            return {
                ...state,
                page_items: action.page_items
            }
        case 'MAXIMIZE_MODAL':
            return {
                ...state,
                max_modal: !Boolean(state.max_modal)
            }
        case 'CREATE_PROJECT_ERROR':
            return state
        case 'UPDATE_ENTRIES':
            return {
                ...state,
                entries: action.data
            }
        case 'SET_VAR':
            return {
                ...state,
                variables: {...state.variables, ...action.data}
            }
        default:
            return state
    }
}
export default pageReducer