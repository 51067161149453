import React from 'react'
import store_stocks from "./store_stocks";
import {sales_modified} from "./investors";
import {ReportIframe} from "../../../components/page_route/report_iframe";

let
    battery_class = [
    {value: 'EFB'},
    {value: 'AGM'},
    {value: 'Standard'},
    {value: 'UPS/Solar'},
],
    battery_type = [
        {value: 'N40'},
        {value: 'N60'},
        {value: 'N70'},
        {value: 'NS70'},
        {value: 'DIN100'},
    ]


let ItemSalesReport = function (props) {
    let
        {form_values = {}, primary_key_columns, table, showAlert} = props,
        params = {style: {height: window.innerHeight - 150}, src: `/api/v1/${table}/${form_values[primary_key_columns[0]]}/investor_sales_item_analysis`, showAlert, signOut: _ => null}
    return <ReportIframe {...params}/>
}
export default {
    type: 'table',
    name: 'items',
    tab_name: 'Items',
    table: 'items',
    title: ['description'],
    lines: [
        [
            {name: 'Item Id', id: 'item_id', type: 'number'},
            {name: 'Short Name', id: 'description', type: 'text', required: true},
            {name: 'Long Name', id: 'long_description', type: 'text', required: true},
            {name: 'Category', id: 'category_id', type: 'select', source: 'item_category.description', required: true, defaultValue: 1},
        ], [
            {name: 'Brand', id: 'brand', type: 'text'},
            {name: 'Type', id: 'type', type: 'select', options: battery_type, creatable: true, span:1, showInTable: false},
            {name: 'Type', id: 'type', type: 'text', span:1, showInModal: false},
            {name: 'Class', id: 'battery_class', type: 'select', options: battery_class, creatable: true, span:1, showInTable: false},
            {name: 'Class', id: 'battery_class', type: 'text', span:1, showInModal: false},
            {name: 'V/Ah', id: 'vah', type: 'text', span:1}
        ], [
            {name: 'Buying Price', id: 'unit_cost', type: 'currency'},
            {name: 'Selling Price', id: 'selling_price', type: 'currency'},
            {name: 'Mark Up', id: 'mark_up', type: 'currency', disabled: true, showInModal: false},
            {name: 'Image', id: 'image', type: 'file', showInTable: false},
        ], [
            {name: 'Mark Up %', id: 'mark_up_perc', type: 'text', showInModal: false},
        ], [
            {name: 'Narrative', id: 'narrative', type: 'textarea', span:3, showInTable: false, attributes: {style: {minHeight: 300}}}
        ], [
            {name: 'Notes', id: 'notes', type: 'textarea', span:3}
        ], [
            store_stocks
        ], [
            sales_modified
        ], [
            {type: 'tab', tab_name: 'Investors Report', fa: 'file-invoice', component: ItemSalesReport}
        ]
    ]
}