import React, {useState} from 'react'
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {allowed} from "../../../role_manager";

const mapStateToProps = (state, props) => {
    return {
        titles: state.pageReducer.target_title
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};
export const

    // allowed = (user_roles, roles_allowed=[]) => {
    //     // if (roles_allowed.length === 0) {
    //     //     return true
    //     // }
    //     return user_roles.filter(value => -1 !== roles_allowed.indexOf(value)).length
    //     // return user_roles.filter(value => -1 !== roles_allowed.indexOf(value))
    // },
    is_allowed = (roles_allowed, user_roles) => {
        // let roles_defined = menu.roles !== undefined
        // return roles_defined ? user_roles.filter(value => -1 !== menu.roles.indexOf(value)) : user_roles
        return allowed(roles_allowed, user_roles)
    },
    SearchForm = connect(mapStateToProps, mapDispatchToProps)((props) => {
        let [menus, setMenus] = useState([]),
            setter = e => {
                e.preventDefault()
                if (e.target.value) {
                    setMenus(props.titles.filter(menu => menu.title.toLowerCase().includes(e.target.value.toLowerCase())).map(({target, title, name = title.split('>').pop()}) => ({target, title, name})).sort((m1, m2) => (m1.name > m2.name) ? 1 : -1))
                } else {
                    setMenus([])
                }
            }
        return (<>
                <form action="#" method="get" className="sidebar-form">
                    <div className="input-group">
                        <input type="text" name="q" className="form-control" placeholder="Search..." onChange={setter}/>
                    </div>
                </form>
                <ul className="sidebar-menu">
                    {menus.map(menu => {
                        return <li>
                            <NavLink to={`/page/${menu.target}`} activeClassName="active" title={menu.title}><i className='far fa-circle'/><span> {menu.name}</span></NavLink>
                        </li>
                    })}
                </ul>
            </>
        )
    })


// export const Multilevel = ({menu}) => {
//     return (
//         <li className="dropdown">
//             <a href="/#">
//                 <span>{menu.name}</span>
//                 <span className=" pull-right-container"><i className="fa fa-angle-left pull-right"/></span>
//             </a>
//             <ul className="treeview-menu">
//                 {
//                     menu.submenus.map((submenu, index) => {
//                         return (
//                             <li key={index}>
//                                 <a href={submenu.url}><i className={'fa ' + submenu.fa}/>{submenu.name}</a>
//                             </li>
//                         )
//                     })
//                 }
//             </ul>
//         </li>
//     )
// }