import React from 'react';
import ReactDOM from 'react-dom';
import {HotTable, HotColumn} from '@handsontable/react';
// @import '~handsontable/dist/handsontable.full.css';
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.js";

export class HotApp extends React.Component {
    constructor(props) {
        super(props);
        this.data = [
            // ['', 'Tesla', 'Mercedes', 'Toyota', 'Volvo'],
            ['2019', 10, 11, 12, 13],
            ['2020', 20, 11, 14, 13],
            ['2021', 30, 15, 12, 13]
        ];
    }

    render() {
        return (
            <HotTable
                data={this.data}
                colHeaders={true}
                rowHeaders={true}
                // width="600"
                // height="300"
                licenseKey="non-commercial-and-evaluation"
                stretchH="all"
                title={'Cars'}
                contextMenu={true}
                afterChange={(changes, source) => {
                    console.log(`changes: ${changes}, source: ${source}`)
                }}
                settings={{
                    columns: [
                        {
                            title: 'Year',
                            type: 'numeric',
                            width: 40
                        },
                        // {
                        //     data: 'image',
                        //     renderer: ImageRenderer,
                        //     width: 50,
                        // },
                        {
                            title: 'Tesla',
                            type: 'numeric',
                            // readOnly: true,
                            width: 50,
                        },
                        {
                            title: 'Mercedes',
                            type: 'numeric',
                            // readOnly: true,
                        },
                        {
                            title: 'Toyota',
                            type: 'numeric',
                            // readOnly: true,
                        },
                        {
                            title: 'Volvo',
                            name: 'volvo',
                            type: 'numeric',
                            // readOnly: true,
                        },
                        {
                            title: 'Totals',
                            // data: (row) => row.tesla + row.mercedes + row.toyota + row.volvo,
                            data: (row) => row[1]+row[2]+row[3] + row[4],
                            // data: (row) => Object.keys(row),
                            type: 'numeric',
                            readOnly: true,
                        }]
                }}
            >
                {/*<HotColumn title="Year"/>
                <HotColumn settings={{
                    title: "Tesla",
                    readOnly: true
                }}/>
                <HotColumn title="Mercedes"/>
                <HotColumn title="Toyota"/>
                <HotColumn title="Volvo"/>
                <HotColumn title="Totals" settings={{
                    // data: (row) => row.tesla + row.mercedes + row.toyota + row.volvo,
                    data: (row) => row[1]+row[2]+row[3] + row[4],
                }}/>*/}
            </HotTable>
        );
    }
}

export default {
    name: 'handson',
    type: 'custom',
    customComponent: HotApp
}

/* Good example
* https://codesandbox.io/s/broken-dawn-fq7q3
* Takes data in form of object
* */