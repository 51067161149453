import journal_entries from "../journal_entries";
import {comma_currency, current_date} from "../../../../../components/utilities";
import Notes from "./notes";

let date = {id: 'transaction_date', type: 'date', span: 1, defaultValue: current_date()},
    debit = {name: 'Debit Account', id: 'debit_account', type: 'select', source: 'account_gl.long_description', span: 1, required: true},
    link_options = [
        {value: 'PV', name: 'Payment Voucher'},
        {value: 'PR', name: 'Payment Request'},
        {value: 'PI', name: 'Purchase Invoice'},
        {value: 'SUPPLIER', name: 'Supplier'},
        {value: 'SI', name: 'Sales Invoice'},
        {value: 'CUSTOMER', name: 'Customer'},
    ]

export default {
    type: 'table',
    name: 'transactions',
    table: 'transactions',
    tab_name: 'Transaction(s)',
    fa: 'clock',
    title: ['narration', 'amount'],
    lines: [
        [
            {...date, showInModal: false},
            {id: 'transaction_no', type: 'text',showInModal: false,showInTable: true},
            {...debit, showInTable: false},
            {name: 'Credit Account', id: 'credit_account', type: 'select', source: 'account_gl.long_description', span: 1, required: true},
            {...debit, showInModal: false},
        ], [
            {...date, showInTable: false, required: true},
            {id: 'currency_code', type: 'select', source: 'currency_types.description', span: 1, defaultValue: 'KES', required: true},
            {id: 'amount', type: 'currency', required: true},
            {
                name: 'Amount (KES)', id: 'amount_ksh', type: 'currency', showInTable: true, showInModal: false, preprocessor: function (state, props) {
                    let {form_values: {amount, conversion_rate}, value} = props
                    if (value) {
                        return {value: comma_currency(value)}
                    } else {
                        return {value: comma_currency((amount || 0) * (conversion_rate || 1))}
                    }
                }
            },
            {
                type: 'custom', component: function (props) {
                    let {form_values: {currency_code}} = props
                    return this.populateForm({lines: [[{id: 'conversion_rate', type: 'currency', span: 3, disabled: currency_code === 'KES', required: currency_code !== 'KES'}]]}).populated_form
                }
            }
        ], [
            {id: 'narration', type: 'textarea', span: 3, required: true}
        ], [], [
            {
                type: 'custom', span: 3, component: function (props) {
                    let {form_values: {link_to, customer_id, s_invoice_id, p_invoice_id, supplier_id, voucher_no, request_no}} = props,
                        nothing = {showInModal: false},
                        link_to_obj = {name: 'Link To', id: 'link_to', type: 'select', options: link_options, defaultValue: 'PV'},
                        supplier_obj = {name: 'Supplier / Creditor', id: 'supplier_id', type: 'select', source: 'supplier.name', span: 1,},
                        customer_obj = {name: 'Customer / Debtor', id: 'customer_id', type: 'select', source: 'customers.company_name', span: 1,},
                        supplier_invoice = {name: 'Sales Invoice Number', id: 's_invoice_id', type: 'select', source: 'sales_invoice.invoice_no', span: 1, creatable: true},
                        purchase_invoice = {name: 'Purchase Invoice Number', id: 'p_invoice_id', type: 'select', source: 'purchase_invoice.description', span: 1, creatable: true},
                        payment_voucher = {name: 'Payment Voucher Number', id: 'voucher_no', type: 'select', source: 'payment_voucher.voucher_no', options: {value: 'voucher_no', name: 'voucher_no'}, span: 1, creatable: true},
                        payment_request = {name: 'Payment Request Number', id: 'request_no', type: 'select', source: 'payment_request.request_no', options: {value: 'request_no', name: 'request_no'}, span: 1, creatable: true}
                    return this.populateForm({
                        lines: [
                            (! link_to) ? [link_to_obj, {}]: [nothing],
                            (link_to === 'SUPPLIER' || supplier_id) ? [link_to_obj, supplier_obj] : [nothing],
                            (link_to === 'CUSTOMER' || customer_id) ? [link_to_obj, customer_obj] : [nothing],
                            (link_to === 'SI' || s_invoice_id) ? [link_to_obj, supplier_invoice] : [nothing],
                            (link_to === 'PI' || p_invoice_id) ? [link_to_obj, purchase_invoice] : [nothing],
                            (link_to === 'PV' || voucher_no) ? [link_to_obj, payment_voucher] : [nothing],
                            (link_to === 'PR' || request_no) ? [link_to_obj, payment_request] : [nothing],
                        ]
                    }).populated_form

                }
            },
        ],
        [], [
            {...journal_entries, below: true, always_refresh: true, span: 3}
        ], [
            {
                type: 'custom', span: 3, component: Notes
            }
        ]
    ],
    totals: [
        {
            'debit_account': function () {
                return 'TOTAL'
            },
            'amount_ksh': function (objects) {
                return objects.map(record => ((record['amount'] || 0) * (record['conversion_rate'] || 1))).reduce((partial_sum, a) => partial_sum + a, 0)
            },
        },
    ],
    processor: function (state, props, prevState, prevProps) {
        if (prevState) {
            if (prevState.currency_code !== state.currency_code) {
                if (state.currency_code === 'KES') {
                    return {conversion_rate: 1}
                } else {
                    return {conversion_rate: null}
                }
            }
            if (state.debit_account) {

            }
            let {debit_account, credit_account} = state,
                {variables = {}, form_state} = props,
                {debit_account: stored_debit_account, credit_account: stored_credit_account} = variables
            if (form_state === 'insert' && stored_debit_account && !debit_account) {
                this.setState({debit_account: stored_debit_account})
            }
            if (form_state === 'insert' && stored_credit_account && !credit_account) {
                this.setState({credit_account: stored_credit_account})
            }
            if (form_state === 'insert' && debit_account && debit_account !== stored_debit_account) {
                this.props.dispatch((dispatch) => {
                    dispatch({type: 'SET_VAR', data: {debit_account}})
                })
            }
            if (form_state === 'insert' && credit_account && credit_account !== stored_credit_account) {
                this.props.dispatch((dispatch) => {
                    dispatch({type: 'SET_VAR', data: {credit_account}})
                })
            }
        }
    }
}
