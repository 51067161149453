export let allowed = (roles_allowed = [], user_roles) => {
    return roles_allowed.map(role_allowed => {
        if (typeof role_allowed === 'string') {
            // return false
            return -1 !== user_roles.indexOf(role_allowed)
        } else if (role_allowed instanceof Array) {
            return role_allowed.every(val => user_roles.includes(val))
        }
        return false
    }).some(i => i)
}