import {current_date} from "../../../../components/utilities";
let pay_methods = [
    {value:'CASH'},
    {value:'MPESA'},
    {value:'EFT'},
    {value:'CHEQUE'},
    {value:'BANK TRANSFER'},
    {value:'SWIFT'},
]
let pv_status = [
    {value:'CHECKED'},
    {value:'APPROVED'},
    {value:'DECLINED'},
    {value:'SUSPENDED'},
]
export default {
    type: 'table',
    name: 'pv_update',
    tab_name: 'Payment Voucher',
    fa: 'clock',
    table: 'payment_voucher',
    title: ['voucher_no'],
    lines: [
        [
            {name: 'Payment Method', id: 'payment_method', type: 'select', options: pay_methods, showInTable: true, span: 1, required: true},
            {name: 'Status', id: 'pv_status', type: 'select', defaultValue:'CHECKED',options: pv_status},
            {name: 'Percentage of Total', id: 'payment_percentage', type: 'number', span:1},
            {name: 'Date', id: 'date_approved', type: 'date', defaultValue:current_date()},
        ],
        [
            {name: 'On Account of', id: 'description', type: 'textarea', span:3, required: true},

        ]
    ]
}