import React from 'react'
import {Redirect} from 'react-router-dom'
import {signOut} from "../../store/actions/authactions";
import {connect} from 'react-redux'
import {is_allowed} from "../../components/StyledBody/sidenav/utilities";

let mapStateToProps = (state, ownProps) => {
        return {
            isAuthenticated: state.authReducer.isAuthenticated,
            roles: state.authReducer.roles
        }
    },
    mapDispatchToProps = dispatch => {
        return {
            signOut: () => dispatch(signOut()),
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    return props.isAuthenticated && function () {
        // if (props.roles.includes('superuser') || props.roles.includes('superviewer')) {
        if (is_allowed(['superuser', 'superviewer'], props.roles) > 0) {
            return <Redirect to='/page/orders'/>
        } else {
            return <Redirect to='/page/investor_report'/>
        }
    }()
})

