import React from 'react'
import Header from './header'
import SideNav from './sidenav'
import Settings from './settings'
// import Footer from './footer'


const Style = (Component) => {
    const showSettings = false;
    const color = '#ecf0f5'
    // const color2 ='#222d32'
    let style = {backgroundColor: color, position: 'absolute', minWidth: '100%', minHeight: '100%'}
    style = null
    const applyToAllPages = false
    // const [dimensions, setDimensions] = React.useState({
    //     height: window.innerHeight,
    //     width: window.innerWidth
    // })
    // React.useEffect(() => {
    //     function handleResize() {
    //         setDimensions({
    //             height: window.innerHeight,
    //             width: window.innerWidth
    //         })
    //         window.addEventListener('resize', handleResize)
    //     }
    // })
    if (window.location !== window.parent.location) {
        return (props) => {
            return <div>
                <Component key={props.location.pathname} {...props}/>
            </div>
        }
    } else {
        return (props) => {
            return (
                <div className="App">
                    <div className="wrapper">
                        <Header showSettings={showSettings}/>
                        <SideNav/>
                        <div className="App" style={{height: '100vh'}} /*style={((applyToAllPages || props.location.pathname === '/dashboard') && style) || null}*/>
                            <div className="content-wrapper">
                                <Component key={props.location.pathname} {...props}/>
                            </div>
                        </div>
                        {showSettings ? <Settings/> : ''}
                        {showSettings ? <div className="control-sidebar-bg"/> : ''}
                        {/*<Footer/>*/}
                    </div>
                </div>
            )
        }
    }
};

export default Style