import React from 'react'
import MenuChildless from './menuchildless'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {is_allowed} from "./utilities";

const mapStateToProps = (state, props) => {
    return {
        ...props,
        ...state.authReducer
    }
}


const MenuChildFull = withRouter(connect(mapStateToProps, null)(
    ({menu, parent, ...props}) => {
        let allowed = (menu.roles !== undefined ? is_allowed(menu.roles, props.roles): props.roles.length)
            // roles_defined = menu.roles !== undefined,
            // allowed = roles_defined ? user_roles.filter(value => -1 !== menu.roles.indexOf(value)) : user_roles
        // console.log('Children are ', {allowed})
        // console.log('Generating menu')
        return allowed ? (
            <li className="treeview">
                <a href='/#'>
                    <i className={'fa ' + (menu.fa ? menu.fa : 'fa-circle')} style={{color: 'white'}}/><span style={{color: 'white'}} >{menu.name}</span><span className=" pull-right-container"><i className="fa fa-angle-left pull-right"/></span>
                </a>
                <ul className="treeview-menu">
                    {
                        menu.children.map((menu, index) => {
                            return (
                                <MenuGenerator menu={menu} parent={parent} key={index}/>
                            )
                        })
                    }
                </ul>
            </li>
        ) : null
    }))

export const MenuGenerator = ({menu, parent}) => {
    const parent2 = `${parent ? parent : ""}${parent ? " > " : ""}${menu.name}`
    return menu.children ? (<MenuChildFull menu={menu} parent={parent2}/>) : (<MenuChildless menu={menu} parent={parent2}/>)
}


// export const Multilevel = ({menu}) => {
//     return (
//         <li className="dropdown">
//             <a href="/#">
//                 <span>{menu.name}</span>
//                 <span className=" pull-right-container"><i className="fa fa-angle-left pull-right"/></span>
//             </a>
//             <ul className="treeview-menu">
//                 {
//                     menu.submenus.map((submenu, index) => {
//                         return (
//                             <li key={index}>
//                                 <a href={submenu.url}><i className={'fa ' + submenu.fa}/>{submenu.name}</a>
//                             </li>
//                         )
//                     })
//                 }
//             </ul>
//         </li>
//     )
// }