import stores from "./stores";
import orders from "./orders";
import order_items from "./order_items";
import items from "./items";
import item_category from "./item_category";
import store_stocks, {store_batch, store_batch_items} from "./store_stocks";
import stock_report from "./stock_report";
import investor_report from "./investor_report";
import business_report from "./business_report";
import analytics_report from "./analytics_report";
import daily_sales_report from "./daily_sales_report";
import investor_sales_report from "./investor_sales_report";
import investors, {investors_capital, investors_payout, investors_reinvestment} from "./investors";
import {vehicle_make, vehicle_model} from "./vehicle";
import analytics from "./analytics";
import store_movements from "./store_movements";

export default [
    orders,
    order_items,
    items,
    item_category,
    stores,
    store_stocks,
    store_batch,
    store_batch_items,
    stock_report,
    investors,
    investors_capital,
    investors_payout,
    investors_reinvestment,
    investor_report,
    business_report,
    analytics_report,
    daily_sales_report,
    investor_sales_report,
    vehicle_make,
    vehicle_model,
    analytics,
    store_movements
]