import React from 'react'
import SelectField from "../../../../components/page_route/form/formelements/select";
import foreign_keys from "../../foreign_keys";
import {current_year} from "../../../../components/utilities";
// import TextField from "../../../../components/page_route/form/formelements/textfield";
// import {current_date} from "../../../../components/utilities";
// import Multiselect from "../../../../components/page_route/form/formelements/multiselect";
// import foreign_keys from "../../foreign_keys";

export default function (props) {
    let
        {state, setLocalState} = props,
        search_params = {},
        setState = (obj) => {
            let others = {...state}
            Object.keys(obj).forEach(key => {
                others[key] = obj[key]
            })
            setLocalState(others)
        },
        handleSelect = (object) => {
            setState(object)
        },
        handleText = (e, object) => {
            if (e) {
                e.preventDefault && e.preventDefault()
                setState({[e.target.id]: e.target.value})
            } else {
                setState(object)
            }
        },
        showAlert = _ => {
        },
        onPressEnter = _ => {
        },
        setVariables = _ => {
        },
        select_params = {
            showAlert, fullId: 'department',
            form_values: {...state}, form_state: 'insert', setState,
            onPressEnter, primary_key_columns: [], setVariables, errors: {}, show_button: false, show_pk_in_list: false
        },
        handlePrint = (e) => {
            e && e.preventDefault()
            try {
                window.frames['business_report'].focus()
                window.frames['business_report'].print()
            } catch (e) {
                try {
                    window.print()
                } catch (e) {
                    console.log('Error on printing', e, Object.keys(e))
                    this.props.showAlert(true, e.toString())
                }
            }
            // eslint-disable-next-line no-unused-expressions
            // window.frames[report_target].preview
        },
        options = [
            {value: 'Show All'},
            {value: 'Show Few'}
        ]

    return (
        <div className='row'>
            <div className="col-md-2">
                {/*<SelectField {...select_params} {...{name: 'Show Not Stocked', id: 'show_zero', ...foreign_keys.yes_no_boolean, value: state.show_zero || (search_params || {})['show_zero'], handleChange: handleSelect}} />*/}
                <SelectField {...select_params} {...{name: 'Show', id: 'show', type: 'select', options, value: state.show || (search_params || {})['show'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-1">
                {/*<SelectField {...select_params} {...{name: 'Show Not Stocked', id: 'show_zero', ...foreign_keys.yes_no_boolean, value: state.show_zero || (search_params || {})['show_zero'], handleChange: handleSelect}} />*/}
                <SelectField {...select_params} {...{name: 'Month', id: 'month', type: 'select', ...foreign_keys.month_num, value: state.month || (search_params || {})['month'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-1">
                <SelectField {...select_params} {...{name: 'Year', id: 'year', type: 'select', ...foreign_keys.year(2023, current_year()), value: state.year || (search_params || {})['year'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-4">
                <h3 style={{textAlign: 'center', fontWeight: 1000}}>{state.report_title}</h3>
            </div>
            <div className="col-md-2">
                {/*<TextField {...select_params} {...{name: 'To Date', id: 'to_date', type: 'date', defaultValue: current_date(), value: state.to_date || (search_params || {})['to_date'] || current_date(), handleChange: handleText}} />*/}
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-1 pull-right" style={{minWidth: 100}}>
                <button className="btn btn-primary btn-warning" title="Print Report" onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
            </div>
        </div>
    )
}