import API from '../../../../store/api'
import {element_id} from "../../table/utilities";
import {server_error} from "../../errors";
import configs from "../../../../custom/configs";

export const get_foreign_key = function () {
        let props = this.props
        let {fullId, parentSource = fullId, form_values, reference, token, id, value, setState, setVariables, history, match, disabled, primary_key_value, type, form_state, signOut = () => null, table} = props,
            model = parentSource ? parentSource.split('.')[0] : console.log('No ID Defined for reference', props),
            column = parentSource ? parentSource.split('.')[1] : console.log('No ID Defined for reference', props),
            resource_id = (form_values && (reference !== undefined ? form_values[reference] : form_values['payroll_no'])) || primary_key_value
        if (!value) {
            value = form_values && form_values[reference] // Sorts cases where value is null or is a Boolean value
        }
        if (resource_id && column && model) {
            let columns_parameter = JSON.stringify([column]),
                call = (new_value) => {
                    if (id === 'int_on_int') {
                        console.log(resource_id, column, model)
                    }
                    let split = fullId.split('.')
                    if (form_state !== 'search') {
                        if (
                            !(
                                Boolean(disabled) ||
                                (type === 'reference' && disabled === undefined) ||
                                (reference && type !== 'select' && disabled === undefined)
                            )
                            && new_value !== undefined && form_values[reference] && value !== new_value
                        ) {
                            if (split.length && split.length > 1 && split[0] !== table && disabled) {
                                setVariables && new_value !== undefined && setVariables({[element_id(id)]: new_value})
                            } else {
                                setState && new_value !== undefined && setState({[element_id(id)]: new_value})
                            }
                        } else {
                            if (split.length && split.length > 1 && split[0] === table) {
                                setState && new_value !== undefined && setState({[element_id(id)]: new_value})
                            } else {
                                setVariables && new_value !== undefined && setVariables({[element_id(id)]: new_value})
                            }
                        }

                    }
                }
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;

            /*Todo Get for reference with multiple primary keys : Started but INCOMPLETE*/
            // console.log('Urls built',  {
            //     first: `${build_url([column], form_values, model)}${primary_key_columns.length > 1?'&':'?'}columns=${columns_parameter}`,
            //     second: `/api/v1/${model}/${resource_id}?columns=${columns_parameter}`
            // })
            API.get(`/api/v1/${model}/${escape(resource_id)}?columns=${columns_parameter}`)
                .then(
                    response => {
                        // console.log('Succeeded', response.data[column])
                        this.setState(state => ({
                            value: response.data[column]
                        }), () => call(response.data[column]))
                    }, error => {
                        if (error.response && error.response.status === 401) {
                            signOut()
                            configs.redirect_to_login && history.push('/?next=' + match.url)
                        } else {
                            console.log(server_error(error), id)
                        }
                    }
                )
        } else {
            // Used if source or parent source has not been provided yet is a reference field
            this.setState({value})
        }
    },
    shouldGetForeignKey = function (prevState, prevProps) {
        let {form_values, type, reference, id} = this.props
        if ((type === 'reference' || reference !== undefined) && ((form_values[reference] !== prevProps.form_values[reference]) || (!Object.keys(form_values).includes(id) || form_values[id] === null))) {
            // console.log('States received', {prevstate:prevProps.state && prevProps.state, state})
            if (prevProps.form_values && prevProps.state !== form_values) {
                // console.log('Getting foreign key', {reference, state})
                if (reference in form_values) {
                    if (form_values[reference] !== prevProps.form_values[reference]) {
                        get_foreign_key.bind(this)()
                    }
                }
            }
        }
    },
    defined = function (item) {
        return ![undefined, null].includes(item)
    }