export default {
    type: 'table',
    name: 'item_category',
    tab_name: 'Item Category',
    table: 'item_category',
    title: ['description'],
    lines: [
        [
            {name: 'Name', id: 'description', type: 'text', required: true},{}
        ], [
            {name: 'Notes', id: 'notes', type: 'textarea', span:3}
        ]
    ]
}