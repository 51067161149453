export default {
    type: 'table',
    name: 'order_items',
    tab_name: 'Order Items',
    table: 'order_items',
    lines: [
        [
            {name: 'Order Id', id: 'order_id', type: 'select', source: 'orders.description', required: true},
            {name: 'Item Category', id: 'category_id', type: 'select', source: 'item_category.description', defaultValue: 1, showInTable: false},
            {name: 'Item Type', id: 'item_id', type: 'select', source: 'items.description', showInTable: true, show_pk_in_list: false},
        ], [
            {name: 'Buying Price', id: 'items.buying_price', type: 'currency', reference: 'item_id', disabled: false, col: 3},
            {name: 'Selling Price', id: 'items.selling_price', type: 'currency', reference: 'item_id', disabled: false, required: true, col: 3},
            {name: 'Quantity', id: 'quantity', type: 'number', defaultValue: 1, col: 1},
            {name: 'Discount', id: 'discount', type: 'currency', col: 2},
            {name: 'Total Cost', id: 'total_cost', type: 'currency', disabled: true, col: 3},
            {name: 'Mark up', id: 'mark_up', type: 'currency', disabled: true, col: 3, showInModal: false},
        ], [
            {name: 'Investor', id: 'investor_id', type: 'select', source: 'investors.name', col:4, show_pk_in_list: false, required: true},
            {name: 'Details', id: 'details', type: 'textarea', col: 8, showInTable: false},
        ]
    ],
    totals: [
        {
            'total_cost': function (objects) {
                let ret = objects.map(record => (record['total_cost'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'mark_up': function (objects) {
                let ret = objects.map(record => (record['mark_up'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'investor_profit': function (objects) {
                let ret = objects.map(record => (record['investor_profit'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
        },
    ]
}