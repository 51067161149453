import React, {PureComponent} from 'react'
import Style from "../StyledBody";
import {Formik, Form, Field, ErrorMessage} from "formik";

const Basic = () => {
    const initialValues = {email: '', password: '', list: 'blue'}
    const validate = values => {
        let errors = {};
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    const onSubmit = (values, {setSubmitting}) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 400);
    }

    const CustomInputComponent = ({
                                      field, // { name, value, onChange, onBlur }
                                      form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      ...props
                                  }) => (
        <div>
            <input type="text" {...field} {...props} />
            {touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </div>
    );

    // const render1 = () => (
    //     <div>
    //         <h1>Anywhere in your app!</h1>
    //         <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
    //             {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,/* and other goodies */}) => (
    //                 <form onSubmit={handleSubmit}>
    //                     <input type="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
    //                     {errors.email && touched.email && errors.email}
    //                     <input type="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}/>
    //                     {errors.password && touched.password && errors.password}
    //                     <button type="submit" disabled={isSubmitting}>Submit</button>
    //                 </form>
    //             )}
    //         </Formik>
    //     </div>
    // )

    const render2 = () => (
        <div>
            <h1>Anywhere in your app!</h1>
            <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({isSubmitting}) => (
                    <Form>
                        <Field type="email" name="email" placeholder="Email"/>
                        <ErrorMessage name="email" component="div"/>
                        <Field type="password" name="password" placeholder="Password"/>
                        <ErrorMessage name="password" component="div"/>
                        <Field type="select" name="list" component="select">
                            <option value="red">Red</option>
                            <option value="green">Green</option>
                            <option value="blue">Blue</option>
                        </Field>
                        <ErrorMessage name="list" component="div"/>
                        <Field name="firstName" component={CustomInputComponent} placeholder="First Name"/>
                        <Field name="textarea" component="textarea" placeholder="Add text here..."/>
                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )

    return render2()
};

class Formiks extends PureComponent {
    render() {
        return (
            <div /*className="content-wrapper"*/>
                <section className="content-header">
                    <h4> Formik Page<small> Somethign</small></h4>
                    <ol className="breadcrumb">
                        <li><a href="/#"><i className="fa fa-dashboard"/> Home</a></li>
                        <li className="active">Dashboard</li>
                    </ol>
                </section>
                <section className="content">
                    <Basic/>
                </section>
            </div>

        )
    }
}

export default Style(Formiks)