import React from 'react'
import ReactDOM from 'react-dom'
import Modal from '../modal'
import PageSuper from "../../parent";
import {element_id} from '../../table/utilities'

export default class FormTable extends PageSuper {
    createDummy = (form) => {
        let combined_lines = [].concat.apply([], form.lines)
        let record_object = {}
        combined_lines.filter(item => 'id' in item).forEach(item => {
            let column_id = element_id(item.id)
            record_object[column_id] = 'Dummy ' + column_id
        })
        // console.log('DUMMY -', record_object)
        return [record_object, record_object, record_object]
    }

    Modals = () => {
        let {default_fk, showAlert, form} = this.props,
            {target = form.name} = this.props,
            {show_modal} = this.state,
            {updateDisplayStateAndRefresh, updateDisplayState, toggleModal: hideModal, setIndex, refreshTable} = this,
            modal_params = {
                ...this.state.modal_properties,
                default_fk,
                show_modal,
                form,
                hideModal,
                showAlert,
                updateDisplayStateAndRefresh,
                updateDisplayState,
                target,
                setIndex,
                refreshTable
            }
        return ReactDOM.createPortal(
            show_modal && <Modal key={this.state.modal_key} {...modal_params} />,
            document.body
        )
    }

    render() {
        let
            {table_key, table_properties, show_index, table_actions} = this.state,
            {url, showAlert, form, default_fk, parent_values} = this.props,
            {target = form.name} = this.props,
            {updateDisplayState, updateDisplayStateAndRefresh, refreshTable, export_action} = this,
            params = {
                ...table_properties, showAlert, form, updateDisplayState, updateDisplayStateAndRefresh, url, target, export_action, show_index, table_actions, refreshTable, default_fk, parent_values
            }
        return Object.keys(default_fk).length && [
            <div className="row" key={1}>
                <div className="col-md-12">
                    <this.TableSubComponent key={table_key} {...params} />
                </div>
            </div>
            ,
            <this.Modals key={2}/>
        ]
    }
}