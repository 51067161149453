let status = [
    {value:'ALLOCATED'},
]
let today_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');

export default {
    type: 'table',
    name: 'budget_allocation_form',
    tab_name: `Budget Allocation`,
    fa: 'question-circle',
    table: 'department_budget',
    title: [],
    lines: [
        [
            {name:'Proposed Amount',id:'proposed_amount',type:'text',disabled:true},
            {name:'Allocated Amount',id:'allocated_amount',type:'text', required:true},
            ],
        [
            {name:'Allocation Date',id:'allocation_date',type:'date',defaultValue:today_date},
            {name:'Status',id:'status',type:'text',defaultValue:'ALLOCATED', disabled:true},
        ],
        [
            {name:'Remarks',id:'remarks',type:'textarea',span:3}
        ]
        ]
}