import React from 'react'

export const icon_generator = (fa, label, type) => {
    return (
        <div className="panel" style={{margin: 0}}>
            <div className="panel-heading">
                <div className="row">
                    <div className="col-md-12" style={{textAlign:'center'}}>
                        <i  className={`${type ? type : 'fa'} fa-${fa} fa-3x`}/>
                    </div>
                </div>
                <div className="row" style={{textAlign: 'center'}}>{label}</div>
            </div>
        </div>
    )
}

export default {}