import React from 'react'
import {ReportIframe} from "../../../../components/page_route/report_iframe";
import Filter from "./Filter";

let BusinessReport = function (props) {
    let [state, setLocalState] = React.useState({}),
        updateDisplayState = function(obj){
            let new_state = {...state},
                {report_title} = obj
            if(report_title){
                setLocalState({...new_state, report_title})
            }
        },
        processOptions = function(){
            let args = []
            Object.keys(state).filter(key=>key!=='report_title').forEach(key=>{
                args.push(`${key}=${state[key]}`)
            })
            return args.join('&')
        }(),
        params = {style: {height: window.innerHeight - 110}, src: `/business/daily_sales_report${Object.keys(state).length? `?${processOptions}`: ''}`, showAlert: _ => null, signOut: _ => null, name: 'business_report', updateDisplayState}

    return (
        <>
            <div className={`box box-primary form-box`}>
                <div className="box-header with-border">
                    <div className="box-body">
                        <Filter {...{state, setLocalState}}/>
                    </div>
                    <div className="box-body">
                        <ReportIframe key={processOptions} {...params}/>
                    </div>
                </div>
            </div>
        </>
    )

}
export default {
    name: 'daily_sales_report',
    hideContentHeader: true,
    customComponent: BusinessReport
}