import React from 'react'

const notifications = () => {
    const notices = [

    ]

    let notices_html = notices.map((item, index) => {
        return (
            <li key={index}><a href="/#"><i className={'fa ' + item.fa}/> {item.text}</a></li>
        )
    })

    return (
    null
//        <li className="dropdown notifications-menu">
//            <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
//                <i className="fa fa-bell"/>
//                <span className="label label-warning">{notices.length}</span>
//            </a>
//            <ul className="dropdown-menu">
//                <li className="header">You have {notices.length} notifications</li>
//                <li>
//                    <ul className="menu">
//                        {notices_html}
//                    </ul>
//                </li>
//                <li className="footer"><a href="/#">.</a></li>
//            </ul>
//        </li>
    )
}

export default notifications