import React from 'react'
import SelectField from "../../../../components/page_route/form/formelements/select";
import TextField from "../../../../components/page_route/form/formelements/textfield";
import {escape_characters} from "../../../../components/page_route/form/utilities";

export default function (props) {
    let
        {unit, default_fk={}} = props,
        [state, setLocalState] = React.useState({}),
        setState = (obj) => {
            let others = {...state}
            Object.keys(obj).forEach(key => {
                others[key] = obj[key]
            })
            setLocalState(others)
        },
        handleSelect = (object) => {
            setState(object)
        },
        handleText = (e, object) => {
            if (e) {
                e.preventDefault && e.preventDefault()
                setState({[e.target.id]: e.target.value})
            } else {
                setState(object)
            }
        },
        showAlert = _ => {
        },
        onPressEnter = _ => {
        },
        setVariables = _ => {
        },
        select_params = {
            showAlert, fullId: 'department',
            form_values: {department: unit.department, ...state}, form_state: 'insert', setState,
            onPressEnter, primary_key_columns: [], setVariables, errors: {}, show_button: false
        }
    React.useEffect(_ => {
        if (Object.keys(state).length) {
            this.updateDisplayStateAndRefresh({search_params: escape_characters(state), page: 1})
        }
        console.log('Updating state')
    }, [state])
    React.useEffect(_ => {
        if (!this.state.search_params || !Object.keys(this.state.search_params).length) {
            setLocalState({})
        }
    }, [this.state.search_params])

    return  (
        <div className={`box box-primary form-box`}>
            <div className="box-header with-border">
                <div className="tab-content">
                    <div className='row'>
                        <div className="col-md-3">
                            <SelectField {...select_params} {...{name: 'Department', id: 'department_code', source: 'hr_orgunit.description',default_filter: {category: 'Department'}, value: state.department_code || (this.state.search_params || {})['department_code'], handleChange: handleSelect}} />
                        </div>
                        <div className="col-md-3">
                            <SelectField {...select_params} {...{name: 'Sub Department', id: 'sub_department_code', source: 'hr_orgunit.description',default_filter: {category: 'SubDepartment'}, value: state.sub_department_code || (this.state.search_params || {})['sub_department_code'], handleChange: handleSelect}} />
                        </div>
                        <div className="col-md-3">
                            <SelectField {...select_params} {...{name: 'Financial Year', id: 'year', source: 'financial_year.description', value: state.year || (this.state.search_params || {})['year'], handleChange: handleSelect}} />
                        </div>
                        <div className="col-md-3">
                            <SelectField {...select_params} {...{name: 'Account Classifications', id: 'gl_code', source: 'account_gl.description', value: state.year || (this.state.search_params || {})['gl_code'], handleChange: handleSelect}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}