import React from 'react';
import Mainbody from './components/navigation'

function App() {
  return (
     <Mainbody/>
  );
}

export default App;
