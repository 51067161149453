export let
    comma_currency = amount => {
        // let amount = am.toString(),
        //     length = amount.length
        // console.log('The currency amount is ', amount, amount[1], length)
        if (amount === null || amount === undefined) {
            return amount
        } else if (isNaN(amount)) {
            return amount
        } else {
            return Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})
        }
        // return amount.toLocaleString('en', {minimumFractionDigits: 2})
        // return amount
    },
    current_ymonth = () => {
        let d = new Date(),
            month = d.getMonth() + 1,
            year = d.getFullYear()
        return `${year}-${month.pad()}`
    },
    current_month = () => {
        let d = new Date()
        return d.toLocaleString('default', {month: 'long'})
    },
    current_year = () => {
        return new Date().getFullYear()
    },
    current_date = () => {
        let d = new Date()
        return format_date(d)
    },
    format_date = function (d) {
        return `${d.getFullYear()}-${(d.getMonth() + 1).pad()}-${d.getDate().pad()}`
    },
    add_years = function (dt, n) {
        return new Date(dt.setFullYear(dt.getFullYear() + n));
    },
    add_days = function (dt = new Date(), n) {
        return new Date(dt.setDate(dt.getDate() + n));
    },
    generateNumbers = (start = 1970, stop = new Date().getFullYear()) => {
        return Array.from(Array(stop - start + 1).keys()).map(item => {
            return (item + start)
        })
    },
    english_date = (value) => {
        if (!value) {
            return null
        }
        let dates = value.split('-')
        if (dates[0] !== undefined && dates[1] !== undefined && dates[2] !== undefined) {
            value = `${dates[2]}/${dates[1]}/${dates[0]}`
        }
        return value
    },
    end_of_month = function (dt) {
        let split = dt.split('-'),
            year = split[0],
            month = split[1],
            d = new Date(year, parseInt(month), 0)
        return format_date(d)
    }