import React, {PureComponent} from 'react'
import ReactDOM from 'react-dom'
import API from '../../../../store/api'
import {getFormByTable, table_name} from "../../table/utilities";
import Modal from '../modal'

export class SelectModal extends PureComponent {
    state = {}

    // detailsButton = (item) => <a href="/#" onClick={e => this.handleDetails(e, 'details', item)}>{show_pk_in_list ? `${item.value} | `:''}{item.label}</a>
    detailsButton = (item) => <a href="/#" onClick={e => this.handleDetails(e, 'details', item)}>{item.label}</a>
    createButton = <a href="/#" style={{width: '100%'}} onClick={e => this.handleDetails(e, 'insert')}><i className='fa fa-plus'/></a>

    api_get_options = () => {
        console.log('Getting options')
    }

    Modal = () => {
        let {table, form_state, show_detail} = this.state

        if (table && form_state && show_detail) {
            let
                removeTables = form => {
                    let {lines, ...others} = form,
                        new_lines = lines.map(line => {
                            return line.filter(item => item.type !== 'table')
                        }).filter(line => line.length)

                    return {...others, lines: new_lines, /*readonly: form_state !== 'insert'*/}
                },
                form = /*removeTables*/(this.state.form || getFormByTable(table)),
                hideModal = () => {
                    this.setState({show_detail: false})
                },
                target = form.name,
                modal_params = {
                    show_modal: true,
                    form,
                    showAlert: this.props.showAlert,
                    primary_key_columns: this.state.primary_keys,
                    updateDisplayStateAndRefresh: (params) => {
                        // this.api_get_options()
                        // hideModal()
                    },
                    refreshTable: () => {
                        this.api_get_options()
                        hideModal()
                    },
                    handleChange: (object) => null,
                    hideModal,
                    updateDisplayState: ({form_state}) => {
                        // form_state = 'update'
                        // this.handleDetails(null, form_state = 'update', item)
                        this.setState({
                            form_state
                        })
                    },
                    target,
                    setIndex: e => {
                        e && e.preventDefault()
                    },
                    record_values: this.state.record_values,
                    form_state
                }
            /* Note: removed modal key*/
            return ReactDOM.createPortal(
                <Modal {...modal_params}/>,
                document.body
            )
        } else {
            return null
        }
    }

    handleDetails = (e, form_state = 'details', item) => {
        e && e.preventDefault();
        let
            {parentSource, source} = this.props,
            table = table_name(source),
            form = getFormByTable(table)
        if (!form.table && parentSource) {
            table = table_name(parentSource)
            form = getFormByTable(table)
        }
        let
            getRecordValues = (callback) => {
                API.get(`/api/v1/${table}/${item.value}`)
                    .then(response => {
                            this.setState(state => ({
                                show_detail: true,
                                record_values: response.data,
                            }), callback)
                        },
                        error => {
                            this.setState({
                                show_detail: false
                            })
                        })
            },
            updateComponent = (objects = {}) => {
                this.setState({
                    ...objects,
                    show_detail: true,
                })
            }
        this.setState({form_state, table, item, form});
        ['update', 'details'].includes(form_state) && getRecordValues(updateComponent);
        ['insert'].includes(form_state) && updateComponent({record_values: undefined})
    }

    render() {
        let {item} = this.props
        return <>
            {this.state.show_detail && <this.Modal/>}
            <span className="text-sm">{this.detailsButton(item)}</span>
        </>
    }
}