
import budget_allocation_actions from "./budget_actions/budget_allocation_actions";
import before_table from "../../before_table";
export default {
    type: 'table',
    name: 'department_budget',
    table: 'department_budget',
    tab_name: 'Department Budget',
    fa: 'map-signs',
    title: ['dept_budget_id'],
    readOnly:true,
    lines: [
        [

            {name: 'Budget', id: 'budget_id', type: 'select', source: 'budget.description', required: true, span:1},
            {name: 'Department', id: 'department_code', type: 'select', source: 'hr_orgunit.description', default_filter: {category: 'Department'}, required: true, span:1},
            {name: 'Proposed Amount', id: 'proposed_amount', type: 'currency', span: 1,disabled:true},
        ],
        [
            {name: 'Allocated Amount', id: 'allocated_amount', type: 'currency', span: 1, disabled: true},
            // {name: 'Year', id: 'year', type: 'select',source:'financial_year.year', span: 1},
            {name: 'Status', id: 'status', type: 'text', span: 1, disabled: true},

        ],
        [
            {name: 'Remarks', id: 'remarks', type: 'text', span: 1,showInModal:true},

        ],
        // [{name:'Actions',id:'actions',type: 'custom', component: budget_allocation_actions, span: 1, showInModal: false,showInTable:true}]
    ],
    beforeTable:before_table,
}