import account_sub_category from "./account_sub_category";

export default {
    type: 'table',
    name: 'account_category',
    table: 'account_category',
    tab_name: 'Accounts Categories',
    fa: 'clock',
    lines: [
        [
            {name: 'Group Code', id: 'group_code', type: 'select', source: 'account_group.description', required: true, span: 1},
            {name: 'Category Code', id: 'category_code', type: 'text', required: true},
            {name: 'Description', id: 'description', type: 'text', required: true}
        ], [
        ], [
            {...account_sub_category, below: true}
        ]
    ]
}