import React from 'react'
import order_items from "./order_items";
import store_stocks from "./store_stocks";
import {ReportIframe} from "../../../components/page_route/report_iframe";
import store_movements from "./store_movements";

export const investors_capital = {
    type: 'table',
    name: 'investors_capital',
    tab_name: 'Investors Capital',
    table: 'investors_capital',
    lines: [
        [
            {name: 'Investor', id: 'investor_id', type: "select", source: 'investors.name'},
            {id: 'date_invested', type: "date"},
            {id: 'amount', type: "currency"},
            {id: 'notes', type: "textarea"},
        ]
    ]
}

export const investors_payout = {
    type: 'table',
    name: 'investors_payout',
    tab_name: 'Investors Payout',
    table: 'investors_payout',
    lines: [
        [
            {name: 'Investor', id: 'investor_id', type: "select", source: 'investors.name'},
            {id: 'date_paid', type: "date"},
            {id: 'amount', type: "currency"},
            {id: 'notes', type: "textarea"},
        ]
    ]
}

export const sales_modified = {
                ...order_items, tab_name: 'Sales', lines: [
                    [
                        {name: 'Order Id', id: 'order_id', type: 'select', source: 'orders.description', required: true, showInTable: false},
                        {name: 'Date of Purchase', id: 'date_of_purchase', type: 'date', disabled: true},
                        {name: 'Item Category', id: 'category_id', type: 'select', source: 'item_category.description', defaultValue: 1, showInTable: false},
                        {name: 'Item Type', id: 'item_id', type: 'select', source: 'items.description', showInTable: true, show_pk_in_list: false},
                    ], [
                        {name: 'Buying Price', id: 'items.buying_price', type: 'currency', reference: 'item_id', disabled: false, col: 3},
                        {name: 'Selling Price', id: 'items.selling_price', type: 'currency', reference: 'item_id', disabled: false, required: true, col: 3},
                        {name: 'Quantity', id: 'quantity', type: 'number', defaultValue: 1, col: 1},
                        {name: 'Discount', id: 'discount', type: 'currency', col: 2},
                        {name: 'Total Cost', id: 'total_cost', type: 'currency', disabled: true, col: 3},
                        {name: 'Mark up', id: 'mark_up', type: 'currency', disabled: true, col: 3, showInModal: false},
                        {name: 'Investor Mark up', id: 'investor_profit', type: 'currency', disabled: true, col: 3, showInModal: false},
                    ], [
                        {name: 'Investor Percentage', id: 'percentage', type: 'currency'},
                        {name: 'Investor', id: 'investor_id', type: 'select', source: 'investors.name', col: 4, show_pk_in_list: false, required: true},
                        {name: 'Details', id: 'details', type: 'textarea', col: 8, showInTable: false},
                    ]
                ],
            }

export const investors_reinvestment = {
    type: 'table',
    name: 'investors_reinvestment',
    tab_name: 'Reinvestment',
    table: 'investors_reinvestment',
    lines: [
        [
            {name: 'Investor', id: 'investor_id', type: "select", source: 'investors.name'},
            {id: 'date_paid', type: "date"},
            {id: 'amount', type: "currency"},
            {id: 'notes', type: "textarea"},
        ]
    ]
}

let InvestorReport = function (props) {
    let
        {form_values = {}, primary_key_columns, table, showAlert} = props,
        params = {style: {height: window.innerHeight - 150}, src: `/api/v1/${table}/${form_values[primary_key_columns[0]]}/investor_report`, showAlert, signOut: _ => null}
    return <ReportIframe {...params}/>
}
let InvestorStocks = function (props) {
    let
        {form_values = {}, primary_key_columns, table, showAlert} = props,
        params = {style: {height: window.innerHeight - 150}, src: `/api/v1/${table}/${form_values[primary_key_columns[0]]}/investor_stocks`, showAlert, signOut: _ => null}
    return <ReportIframe {...params}/>
}
export default {
    type: 'table',
    name: 'investors',
    tab_name: 'Investors',
    table: 'investors',
    title: ['name'],
    lines: [
        [
            {name: 'Investor', id: 'investor_id', type: 'integer', disabled: true, showInTable: false, showInModal: false},
            {name: 'Name', id: 'name', type: 'text'},
            {name: 'Profit Percentage', id: 'percentage', type: 'currency'},
            {name: 'Capital', id: 'capital', type: 'currency', disabled: true},
        ], [
            {...investors_capital, tab_name: 'Capital Injected'},
            store_stocks,
            store_movements,
            sales_modified,
            {...investors_payout, tab_name: 'Pay Outs'},
            {...investors_reinvestment, tab_name: 'Reinvestment'},
            {type: 'tab', tab_name: 'Investor Report', fa: 'file-invoice', component: InvestorReport},
            {type: 'tab', tab_name: 'Stock Report', fa: 'file-invoice', component: InvestorStocks}
        ]
    ]
}