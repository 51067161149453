import React, {PureComponent} from 'react'
import {is_allowed, SearchForm} from './utilities'
import {MenuGenerator} from './menuchildfull'
import menu_list from '../../../custom/menus'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {signOut} from "../../../store/actions/authactions";
import {Scrollbars} from 'react-custom-scrollbars'
import {can} from "../../../custom/roles";

class SideNav extends PureComponent {
    state = {}
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight - 50});
    }

    render() {
        const generate_menus = (top_menu) => {
            if (this.props.roles) {
                let allowed = is_allowed(top_menu.roles, this.props.roles)
                return allowed && top_menu.children ? [
                    <li key={'header'} className="header">{top_menu.name}</li>,
                    top_menu.children.map((menu, index) => {
                        return (
                            <MenuGenerator key={index} menu={menu} parent={top_menu.name}/>
                        )
                    })] : null
            } else {
                this.props.signOut()
            }
        }

        return (
            <aside className="main-sidebar" style={{backgroundColor: '#222d32'}}>
                <Scrollbars style={{height: this.state.height}}>
                    <section className="sidebar">
                        <div className="user-panel">
                            <div className="pull-left image">
                                <i className="fa fa-user-circle" style={{color: 'white', fontSize: '2.5em'}}/>
                            </div>
                            <div className="pull-left info">
                                <p>{this.props.first_name} {this.props.last_name}</p>
                                <a href="/#" onClick={e => e.preventDefault()}><i className="fa fa-circle text-success"/> Online</a>
                            </div>
                        </div>
                        <SearchForm/>
                        <ul className="sidebar-menu">
                            <li className="header">MAIN NAVIGATION</li>
                            <li className="treeview"><NavLink to="/dashboard"><i className="fa fa-home"/><span>Home</span></NavLink></li>
                            {
                                /*is_allowed(can('view', 'all_menus'), this.props.roles)?
                                    [{
                                        name: 'All Menus',
                                        roles: can('view', 'all_menus'),
                                        children: [...menu_list]
                                    }].map(item => generate_menus(item))
                                    :*/
                                    menu_list.map(item => generate_menus(item))
                            }
                        </ul>
                    </section>
                </Scrollbars>
            </aside>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        ...state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideNav))