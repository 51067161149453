import account_gl from "./account_gl";

export default {
    type: 'table',
    name: 'account_sub_category',
    table: 'account_sub_category',
    tab_name: 'Accounts Sub Categories',
    fa: 'clock',
    lines: [
        [
            {name: 'Category Code', id: 'category_code', type: 'select', source: 'account_category.description', required: true, span: 1},
            {name: 'Sub Category Code', id: 'sub_category_code', type: 'text', required: true},
            {name: 'Description', id: 'description', type: 'text', required: true}
        ], [
        ], [
            {...account_gl, below: true}
        ]
    ]
}