import React, {PureComponent} from 'react'
import element_style from "./element_style";

export class Calculated extends PureComponent {
    state = {}

    componentDidMount() {
        this.recalculate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.form_values !== this.props.form_values) {
            this.recalculate()
        }
    }

    recalculate() {
        const {preprocessor} = this.props
        preprocessor && this.setState(preprocessor)
    }

    render() {
        let
            {id, name} = this.props,
            value = this.state.value !== undefined ? this.state.value : 'N/A'
        return <>
            <div className="form-control">{value}</div>
        </>
    }

}

export class CalculatedRow extends Calculated {
    render() {
        let
            value = this.state.value !== undefined ? this.state.value : 'N/A'
        return <span>{value}</span>
    }
}

export default element_style(Calculated)