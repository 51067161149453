import React, {PureComponent} from 'react'
import {NavLink} from 'react-router-dom'
import {setTitle} from '../../../store/actions/pageactions'
import {connect} from 'react-redux'
import {is_allowed} from "./utilities";
// import {PrivateRoute} from "../../navigation";
// import {withRouter} from 'react-router-dom'
// import RoutePage from '../../page_route'

class MenuChildless extends PureComponent {
    componentDidMount() {
        let {setTitle, parent: title, menu, roles} = this.props,
            target = menu.target,
            name = menu.name
        setTitle({target, title, name})
    }

    render() {
        let { menu, create_route} = this.props,
            target = menu.target,
            page = menu.page || ('/page/' + target),
            fontawesome = menu.fa ? `fa ${menu.fa}` : 'fa fa-circle'
        return create_route ? (
                <>
                {/*<PrivateRoute exact path="/page/:target" component={withRouter(RoutePage)}/>*/}
                </>
        ): (
            (menu.roles !== undefined ? is_allowed(menu.roles, this.props.roles): this.props.roles.length) ?
            <li>
                <NavLink to={page} activeClassName="active"><i className={fontawesome}/><span>{menu.name}</span></NavLink>
            </li> : null
        )

    }
}

const
    mapStateToProps = (state, ownProps) => {
        return {
            menu: ownProps.menu,
            parent: ownProps.parent,
            roles: state.authReducer.roles
        }
    }


const
    mapDispatchToProps = (dispatch) => {
        return {
            setTitle: (params) => dispatch(setTitle(params))
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(MenuChildless)
