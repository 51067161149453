import axios from 'axios';

// export default axios.create({
//   baseURL: `http://10.0.75.2:83`,
//   timeout: 10000,
//   withCredentials: true,
//   headers: {
//     'Access-Control-Allow-Credentials': true,
//   }
// });
// export default (({getState, dispatch}) =>  {
//     return axios.create({
//         baseURL: `http://192.168.11.30`
//     });
// })

export const url = `/`,
    api_prefix = '/api/v1/'
export default axios.create({
    baseURL: url,
    maxRedirects: 1,
    timeout: 100000,
    withCredentials: true,
    // headers: {
    //     'content-type': 'multipart/form-data',
    //     'Access-Control-Allow-Credentials': true,
    // }
});

