import React from 'react'

export const Pagination = (props) => {
    const {page, total_pages} = props.table_data;
    let navs

    const clickHandler = (e, page) => {
        e.preventDefault()
        page && props.api_get_data({page})
    }

    const ListItem = ({number}) => {
        return (number !== 0) && (
            <li className={page === number ? 'active' : ''}>
                <a href="/#" onClick={e => clickHandler(e, number)} title={`Go to page ${number}`}>
                    {number || '...'}
                </a>
            </li>)
    }

    if (total_pages <= 10) {
        const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

        navs = pages.filter(item => item <= total_pages).map((item, index) =>
            <ListItem key={index} number={item}/>
        )

    } else if (total_pages > 10) {
        let
            middle = key => <li key={key}><a href="/#" onClick={e => e.preventDefault()}>...</a></li>,
            list = [0, 1, 2, 3, 4, 5, 6].map((item, index) => {
                let comparer
                if (page > 5) {
                    comparer = (page + item - 5 || 0) % (total_pages + 1)
                } else {
                    comparer = item
                }
                return comparer
            })
        navs = [
            !list.includes(1) && <ListItem key={-1} number={1}/>,
            !list.includes(2) && middle(-3),
            list.map(num => <ListItem key={num} number={num}/>),
            !list.includes(total_pages - 1) && middle(-4),
            !list.includes(total_pages) && <ListItem key={-2} number={total_pages}/>
        ]
    }
    return (
        <ul className="pagination pagination-sm" style={{margin: '0 0 0 0'}}>
            {navs}
        </ul>
    )
}

// export const PaginationOld = (props) => {
//     const {page, total_pages} = props.table_data;
//
//     const clickHandler = (e, page) => {
//         e.preventDefault()
//         props.api_get_data({page})
//     }
//
//     if (total_pages < 10) {
//         const pages = Array.from(Array(total_pages).keys());
//         console.log('PAGES', pages)
//         return (
//             <ul className="pagination " style={{margin: '0 0 0 0'}}>
//                 {pages.map((item, index) => <li key={index} className={page === item + 1 ? 'active' : ''}><a href="/#" onClick={(e) => clickHandler(e, item + 1)}>{item + 1}</a></li>
//                 )}
//             </ul>
//         )
//     } else if (total_pages > 10) {
//         const left_pages = total_pages - page
//         let first = <li><a href="/#" onClick={(e) => clickHandler(e, 1)}>1</a></li>
//         let last = <li><a href="/#" onClick={(e) => clickHandler(e, total_pages)}>{total_pages}</a></li>
//         let middle = <li><a href="/#" onClick={(e) => clickHandler(e, null)}>...</a></li>
//         let list_before = [4, 3, 2, 1].map((item, index) => (
//             <li key={index}><a href="/#" onClick={(e) => clickHandler(e, page - item)}>{page - item}</a></li>
//         ))
//         let list_after = [1, 2, 3, 4].map((item, index) => (
//             <li key={index}><a href="/#" onClick={(e) => clickHandler(e, page + item)}>{page + item}</a></li>
//         ))
//         if (page > 5) {
//             return (
//                 <ul className="pagination" style={{margin: '0 0 0 0'}}>{first}{middle}{list_before}
//                     <li className="active"><a href="/#">{page}</a></li>
//                     {list_after}{middle}{last}</ul>
//             )
//         } else if (page < 5) {
//             return (
//                 <ul className="pagination" style={{margin: '0 0 0 0'}}>{first}{list_before}
//                     <li className="active"><a href="/#">{page}</a></li>
//                     {list_after}{middle}{last}</ul>
//             )
//         }
//         return (
//             <ul className="pagination">
//                 <li>{left_pages}Design a bigger pagination</li>
//             </ul>
//         )
//     }
// }