import {defined} from "../../../../components/page_route/form/formelements/utilities";

let payment_methods = [
        {value: 'EFT'},
        {value: 'CHEQUE'},
        {value: 'CASH'},
    ],
    fields = [
        {id: 'conversion_rate', type: 'currency', required: true, span: 3},
    ]

export default {
    type: 'table',
    name: 'incoming_payments',
    table: 'incoming_payments',
    tab_name: 'Incoming Payments',
    fa: 'bank',
    title: ['payment_id'],
    processor: function (state, props, prevState, prevProps) {
        if (prevState) {
            if (prevState.currency !== state.currency) {
                if (state.currency === 'KES') {
                    return {conversion_rate: 1}
                } else {
                    return {conversion_rate: null}
                }
            }
        }
    },
    lines: [
        [
            {name: 'Invoice', type: 'select', id: 'invoice_id', source: 'sales_invoice.invoice_description', span: 1, default_filter: {status: 'APPROVED'}},
            {name: 'Payment Method', type: 'select', id: 'payment_method', options: payment_methods, span: 1},
            {name: 'Reference', type: 'text', id: 'reference_no', required: true},
        ],
        [
            {span: 1, name: 'Currency', id: 'currency', type: 'select', source: 'currency_types.description', defaultValue: 'KES', required: true},
            {
                type: 'custom', showInTable: false, component: function (props) {
                    let {form_state, form_values: {currency}} = props,
                        line = []
                    if (currency === 'KES') {
                        line.push({...fields[0], disabled: currency === 'KES'})
                    } else if (defined((currency) && currency !== 'KES')) {
                        line.push({...fields[0], disabled: false, required: true})

                    } else {
                        line.push({...fields[0], disabled: false, required: true})

                    }
                    return this.populateForm({lines: [line]}).populated_form
                }
            },
            {name: 'Amount Paid', type: 'currency', id: 'amount_paid', required: true},
        ],

    ]
}