export default {
    name: 'Business',
    target: 'business',
    fa: 'fa-briefcase',
    roles: ['superuser', 'superviewer'],
    children: [
        {
            name: 'Sales',
            target: 'orders',
            fa: 'fa-phone'
        },/* {
            name: 'Sales Items',
            target: 'order_items',
            fa: 'fa-phone'
        }*/, {
            name: 'Catalogue',
            target: 'items',
            fa: 'fa-images fa-sharp fa-solid'
        }, {
            name: 'Inventory / Stock',
            target: 'store_stocks',
            fa: 'fa-warehouse fa-solid',
            children: [
                {
                    name: 'Batches',
                    target: 'store_batch'
                }, {
                    name: 'Stocks',
                    target: 'store_stocks'
                }
            ]
        }, {
            name: 'Store Movements',
            target: 'store_movements',
            fa: 'fa-warehouse fa-solid'
        },
        {name: 'Stock Report', target: 'stock_report'},
        {name: 'Investor Report', target: 'investor_report'},
        {name: 'Business Report', target: 'business_report'},
        {name: 'Daily Sales Report', target: 'daily_sales_report'},
        {name: 'Investor Sales Report', target: 'investor_sales_report'},
        {name: 'Analytics', target: 'analytics_report'},
        {
            name: 'Investors',
            target: 'investors',
            fa: 'fa-user-tie',
            children: [
                {
                    name: 'Investors',
                    target: 'investors',
                    fa: 'fa-user-tie',
                }, {
                    name: 'Investors Capital',
                    target: 'investors_capital',
                    fa: 'fa-user-tie',
                }, {
                    name: 'Investors Payout',
                    target: 'investors_payout',
                    fa: 'fa-user-tie',
                }
            ]
        },
        /*{
            name: 'Reports',
            target: 'reports',
            children: [
                {name: 'Daily Sales', target: 'daily_sales'},
            ]
        },*/ {
            name: 'Setup',
            target: 'setup',
            children: [
                {
                    name: 'Catalog Categories',
                    target: 'item_category'
                }, {
                    name: 'Vehicle Makes',
                    target: 'vehicle_make'
                }, {
                    name: 'Vehicle Models',
                    target: 'vehicle_model'
                }, {
                    name: 'Analytics Types',
                    target: 'analytics'
                }
            ]
        }, {
            name: 'Reports',
            target: 'reports',
            children: [
                {
                    name: 'Investor Reports',
                    target: 'investor_report'
                }, {
                    name: 'Battery Vehicle Map',
                    target: 'battery_vehicle_map'
                },
            ]
        }
    ],

}
