/*
https://download.geofabrik.de/
https://download.geofabrik.de/
https://carto.com/help/building-maps/basemap-list/
regional OSM data provider*/
import React, {memo} from 'react'
import {Marker, Polyline, Popup} from 'react-leaflet'

export const layers = [
        {name: 'Google Satellite', url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps', checked: true}, // Hybrid
        {name: 'Google Street', url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps'}, // Streets
        // {name: 'Google Satellite', url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps'}, // satellite
        {name: 'Google Terrain', url: 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps'}, // terrain
        {name: 'Google Traffic', url: 'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps'}, // with traffic
        {name: 'Google Street View Coverage', url: 'http://{s}.google.com/vt/lyrs=svv,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], maxZoom: 20, attribution: 'Google Maps'}, // Hybrid
        // {name:'Test Vector Tiles as TileLayer', url:'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.mvt?style=mapbox://styles/mapbox/streets-v11@00&access_token=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'},
        // {name: 'Mapbox Streets', url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'},
        // {name: 'Mapbox Dark', url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'},
        // {name: 'Mapbox Light', url: 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'},
        // {name: 'Mapbox Satellite', url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'},
        {name: 'OSM Basemap', url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png', attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',},
        {name: 'OSM Carto', url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', maxZoom: 22, maxNativeZoom: 18},
        {name: 'Mapnik', url: "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'},
        {name: 'Wikimapia', url: "http://i{x%4+(y%4)*4}.wikimapia.org/?x={x}&y={y}&zoom={z}", attribution: '&copy; <a href="https://wikimapia.org">Wikimapia</a> contributors'},
        // {name: 'wmflabs Hike & Bike', url:'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png'},
        // {name: 'wmflabs Hillshading shading', url:'https://tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png'},
        // {name: 'Mapbox Satellite', url:'https://{s}.tiles.mapbox.com/v3/tmcw.map-j5fsp01s/{z}/{x}/{y}.png'},
        {name: 'Mapquest Dark', url: 'https://{s}.tiles.mapbox.com/v4/mapquest.dark-mb/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoibWFwcXVlc3QiLCJhIjoiY2Q2N2RlMmNhY2NiZTRkMzlmZjJmZDk0NWU0ZGJlNTMifQ.mPRiEubbajc6a5y9ISgydg'},
        {name: 'Carto Dark ("Dark Matter")', url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'}, //https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png
        {name: 'Carto Light ("Positron")', url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'}, // https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png
        {name: 'Carto Voyager', url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'},
        {name: 'OpenTopoMap', url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'},
        // {name: 'Lima Labs', url:'https://cdn.lima-labs.com/{z}/{x}/{y}.png?beta'},
        // {name: 'Bing', url:'https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/3001101021?mkt=en-US&it=G,BX,RL&shading=hill&n=z&og=1108&c4w=1&cstl=vb&src=t'}
    ],
    mapbox_vector = [
        {name: 'Mapbox Street', style: 'mapbox://styles/mapbox/streets-v11'},
        {name: 'Mapbox Dark', style: 'mapbox://styles/mapbox/dark-v10'},
        {name: 'Mapbox Light', style: 'mapbox://styles/mapbox/light-v10'},
        // {name:'Mapbox ', style: 'mapbox://styles/mapbox/streets-v9'},
        // {name: 'Mapbox Satellite', style: 'mapbox://styles/mapbox/satellite-v9'},
        {name: 'Mapbox Outdoor', style: 'mapbox://styles/mapbox/outdoors-v11'},
        {name: 'Mapbox Satellite', style: 'mapbox://styles/mapbox/satellite-streets-v11'},
    ],
    geoJSONStyle = () => {
        return {
            // color: '#211f20',
            color: '#21cb21',
            weight: 1,
            fillOpacity: 0,
            fillColor: '#fff2af',
        }
    },

    onEachFeature = (feature, layer) => {
        const popupContent = `<Popup>${feature.properties.COUNTY || feature.properties.COUNTY_NAM}</Popup>`
        layer.bindPopup(popupContent)
    },
    isFloat = num => {
        return !isNaN(num)
        // return Number(num) === num
    },
    CreateMaker = ({position, createInfo, params}) => {
        let
            {lat, lon} = position
        if (isFloat(lat) && isFloat(lon) && lat !== null && lon !== null) {
            return <Marker position={[lat, lon]} draggable={true} {...params}>
                {
                    createInfo ? createInfo(position) : null
                }
            </Marker>
        } else {
            return null
        }
    },
    calculateCenter = points => {
        let
            pointer = points.filter(point => isFloat(point.lat) && isFloat(point.lon))
        /*points.forEach(point => {
            if (isFloat(point[0].lat) && isFloat(point[0].lon)) {
                center = [point[0].lat, point[0].lon]
            }
        })*/
        return pointer.length ? [pointer[0].lat, pointer[0].lon] : null
    },
    removeDuplicates = array => {
        return [...new Set(array)]
    },
    getPoints = (points, selector = 'plate') => {
        let vehicles = removeDuplicates(points.map(point => point[selector])),
            vehicle_points = vehicles.map(vehicle => {
                return {
                    vehicle: vehicle, positions: points.filter(item => item[selector] === vehicle && item[selector]),
                }
            })

        vehicle_points = vehicle_points.map(point => {
            return {
                ...point, from_to: point.positions.map((position, index) => {
                    if (index === point.positions.length - 1) {
                        return null
                    } else {
                        return [
                            [position.lat, position.lon],
                            [point.positions[index + 1].lat, point.positions[index + 1].lon]
                        ]
                    }
                })

            }
        })
        return vehicle_points
    },
    createPolyline = (points) => {
        let vehicle_points = getPoints(points),
            lines = []
        vehicle_points.forEach((vehicle, plate_index) => {
            lines = [...lines, ...vehicle.from_to.map((from_to, from_to_index) => {
                return from_to ? <Polyline key={`${plate_index}_${from_to_index}`} positions={from_to} color={'blue'} weight={2}/> : null
            })]
        })
        return lines
    },
    defaultInfo = position => {
        let {lat, lon, makemodel, battery_level, speed, altitude, course, imei, timestamp, full_name, rfid, civilian_plate_no, gok_plate_no} = position

        return (<Popup>
            {full_name && <>Driver: {full_name}<br/></>}
            {rfid && <>RFID: {rfid}<br/></>}
            {makemodel && <>{makemodel}<br/></>}
            {civilian_plate_no && <>Civ. Plate: {civilian_plate_no}<br/></>}
            {gok_plate_no && <>GOK Plate: {gok_plate_no}<br/></>}
            {imei && <>IMEI: {imei || 'not set'}<br/></>}
            {timestamp && <>Timestamp: {timestamp || 'not set'}<br/></>}
            {lat && <>Lat: {lat}<br/></>}
            {lon && <>Lon: {lon}<br/></>}
            {battery_level && <>Battery: {battery_level}<br/></>}
            {speed && <>Speed: {speed}<br/></>}
            {altitude && <>Altitude: {altitude}<br/></>}
            {course && <>Course: {course}<br/></>}
        </Popup>)
    },
    PathPlot = memo((props) => {
        let {points, createInfo = defaultInfo} = props,
            only_plates = points.filter(point => point.plate && Boolean(point.imei) === false),
            only_imei = points.filter(point => point.imei && Boolean(point.plate) === false),
            both = points.filter(point => point.imei && point.plate)

        return points && points.length ? [...getPoints(only_plates, 'plate'), ...getPoints(only_imei, 'imei'), ...getPoints(both, 'imei')].map((plate, plate_index) => {
            return plate.from_to.map((from_to, from_to_index) => {
                return from_to ?
                    <Polyline key={`${plate.imei || plate_index}_${from_to_index}`} positions={from_to} color={'blue'} weight={2}/> :
                    <CreateMaker key={`${plate.imei}`} position={plate.positions[0]} createInfo={createInfo}/>
            })
        }) : null

    })

/*
Routing
https://codesandbox.io/s/react-leaflet-routing-machine-s660b?file=/src/RoutingMachine.js

Geocoding - getting name of location
https://codesandbox.io/s/y0znnj58l1

Searching
https://codesandbox.io/s/how-to-render-react-leaflet-search-rh9bl?file=/src/CustomOpenStreetMap.js

Timeline player
https://codesandbox.io/s/covid-19-timeline-map-crqur?file=/src/App.js:4880-4887
* */
