import transactions from './transactions'
import journal_entries from "./journal_entries";
export default {
    type: 'table',
    name: 'account_gl',
    table: 'account_gl',
    tab_name: 'GL Accounts',
    fa: 'clock',
    lines: [
        [
            {name: 'Category Code', id: 'account_sub_category.category_code', type: 'select', source: 'account_category.description', reference: 'sub_category_code', parentSource: 'account_sub_category.category_code', disabled: true, span: 1},
            {name: 'Sub Category Code', id: 'sub_category_code', type: 'select', source: 'account_sub_category.description', required: true, span: 1},
            {name: 'GL Code', id: 'gl_code', type: 'text', required: true, showInTable: true},
            {name: 'Description', id: 'description', type: 'text', required: true},
        ], [
            {...transactions, pkey: 'gl_code', fkey: 'debit_account', tab_name: 'Transactions : Debits', fa: 'inbox-in'},
        ], [
            {...transactions, pkey: 'gl_code', fkey: 'credit_account', tab_name: 'Transactions : Credits', fa: 'inbox-out'},
        ], [
        ], [
            {...journal_entries, pkey: 'gl_code', fkey: 'gl_account_code', tab_name: 'Journal Entries', fa: 'inbox', below: true},
        ], [
            {name: 'Long Description', id: 'account_gl.long_description', type: 'text', reference: 'gl_code', span: 3},
        ]
    ]
}