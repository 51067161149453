export default {
    tab_name: 'User Role(s)',
    type: 'table',
    name: 'PROGRAM roles_users',
    post_url: '',
    table: 'RolesUsers',
    lines: [
        [
            {name: 'User', id: 'RolesUsers.user_id', type: 'select', source: 'User.full_name', span: 1, required: true},
            {name: 'Role Id', id: 'RolesUsers.role_id', type: 'select', source: 'Role.description', span: 1, required: true}
        ]
    ]
}
