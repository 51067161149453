import JournalFilter from "./journal_filter";

let total = (objects, type) => objects.map(record => (record[type] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)

export default {
    type: 'table',
    name: 'journal_entries',
    table: 'journal_entries',
    tab_name: 'Journal Entrie(s)',
    fa: 'clock',
    readOnly: true,
    lines: [
        [
            {id: 'transaction_date', type: 'date', disabled: true},
            {id: 'gl_account', type: 'select', source: 'account_gl.long_description', span: 1},
        ], [
            {name: 'Debit (KSH)', id: 'debit', type: 'currency', disabled: true},
            {name: 'Credit (KSH)', id: 'credit', type: 'currency', disabled: true}
        ], [
            // {name: 'Narration', id: 'transaction_id', type: 'select', source: 'transactions.narration', disabled: true, span: 3},
            {id: 'transactions.narration', type: 'textarea', reference: 'transaction_id', showInTable: true, disabled: true, span: 3},
        ]
    ],
    totals: [
        {
            'gl_account': function () {
                return 'Totals'
            },
            'debit': function (objects) {
                return total(objects, 'debit')
            },
            'credit': function (objects) {
                return total(objects, 'credit')
            },
        },
        {
            'transaction_date': function () {
                return 'Balance B/F'
            },
            'gl_account': function () {
                let {state: {search_params: {gl_account_code}={}}={}} = this
                return gl_account_code
            },
            'debit': function (objects) {
                let debits = total(objects, 'debit')
                let credits = total(objects, 'credit')
                return debits > credits ? debits - credits: 0
            },
            'credit': function (objects) {
                let debits = total(objects, 'debit')
                let credits = total(objects, 'credit')
                return credits > debits ? credits - debits: 0
            },
        },
    ],
    beforeTable: JournalFilter
}