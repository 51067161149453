import React, {useState} from 'react'
import axios from 'axios'
import {connect} from "react-redux";
import {Modal} from "./page_route/form/modal";
import {allowed} from "../role_manager";
import {server_error} from "./page_route/errors";

export let mapStateToProps = (state, ownProps) => {
        return {
            ...ownProps,
            ...state.authReducer
        }
    },

    user_allowed = allowed,
    /*user_allowed = (user_roles, roles_allowed) => {
        // if (roles_allowed.length === 0) {
        //     return true
        // }
        return user_roles.filter(value => -1 !== roles_allowed.indexOf(value)).length
    },*/
    createButtons = (button_list, button_params, table_form_params) => connect(mapStateToProps, null)(function (new_props) {
        let
            {setConfirm, record, show_modal, setState, ...others} = button_params,
            button_generator = (action, index) => {
                let onClick = (e) => {
                    e.preventDefault()
                    let obj = {...action.action(e, record, others), show_modal: true}
                    if (obj.form) {
                        if (show_modal === true) {
                            setConfirm(obj)
                        } else {
                            // setConfirm(obj)
                            setState({action: obj})
                        }
                    }

                }
                return <>
                    {(action.roles === undefined || allowed(action.roles, new_props.roles)) ? (
                        <li key={index}>
                            <a href="/#" key={index} className={`btn btn-${action.color} btn-xs col-xs-24`}
                               onClick={onClick} style={{width: '100%'}}>
                                <div className="row">
                                    <div className="btn-group-vertical col-xs-12">
                                        <i className={`fa fa-${action.fa}`}/> {action.name}
                                    </div>
                                </div>
                            </a>
                        </li>
                    ) : null
                    }
                </>
            }

        // current_user_is = function (role_name) {
        //     return new_props.roles.find(role => role === role_name)
        // },

        return (
            <ul className='no-bullet'>
                {button_list.filter(item => item).map(button_generator)}
            </ul>
        )
    }),
    ModalAction = function (props) {
        let
            params = {
                ...props, hideModal: () => props.setConfirm(false), updateDisplayStateAndRefresh: obj => {
                    props.updateDisplayStateAndRefresh({obj})
                    props.setConfirm(false)
                }, form: props.confirm.form
            }
        return <Modal {...params} form_state={'update'}/>
    },
    Actions = button_list_function => function (props) {
        let
            record = props.record || props.form_values || {},
            // eslint-disable-next-line react-hooks/rules-of-hooks
            [confirm, setConfirm] = useState(false),
            {props: {show_modal}} = this,
            {updateDisplayStateAndRefresh = this.props.updateDisplayStateAndRefresh, showAlert = this.props.showAlert, setIndex, refreshTable, handleDelete, is_a_report} = this,
            {action, search_params, record_index, height: state_height, modal_key, table_data} = this.state,
            {target, page_name, match} = this.props,
            setState = obj => this.setState(obj),
            params = {
                updateDisplayStateAndRefresh, setIndex, refreshTable, handleDelete, is_a_report,
                action, search_params, record_index, height: state_height, modal_key, table_data,
                target, page_name, showAlert, match, setState
            },
            button_params = {
                setConfirm, record, show_modal, setState: obj => this.setState(obj)
            },
            others = {
                updateDisplayStateAndRefresh, showAlert
            },
            Buttons = createButtons(button_list_function(record), {...button_params, ...others})
        return (
            <>
                {confirm && <ModalAction confirm={confirm} setConfirm={setConfirm} {...this.props} show_modal={true}/>}
                <Buttons record={record} setConfirm={setConfirm} show_modal={show_modal}/>
            </>
        )
    },
    ProcessButtons = Buttons => function (process) {
        return function (props) {
            let
                record = props.record || props.form_values || {},
                // eslint-disable-next-line react-hooks/rules-of-hooks
                [confirm, setConfirm] = useState(false),
                {props: {show_modal, showAlert = this.state.showAlert}, updateDisplayStateAndRefresh = this.props.updateDisplayStateAndRefresh} = this
            return (
                <>
                    {
                        confirm &&
                        function () {
                            let
                                params = {
                                    ...this.props, hideModal: () => setConfirm(false), updateDisplayStateAndRefresh: obj => {
                                        this.props.updateDisplayStateAndRefresh({obj})
                                        setConfirm(false)
                                    }, ...confirm, form: confirm.form
                                }
                            return <Modal {...params} form_state={confirm.form.form_state}/>
                        }.bind(this)()
                    }
                    {[undefined, 'details', 'update'].includes(props.form_state) && <Buttons setState={obj => this.setState(obj)} record={record} setConfirm={setConfirm} show_modal={show_modal} showAlert={showAlert} updateDisplayStateAndRefresh={updateDisplayStateAndRefresh} process={process}/>}
                </>
            )
        }
    },
    confirmAction = (e, record, others) => {
    let {act, bool, model, primary_key, updateDisplayStateAndRefresh, showAlert} = others
    if (window.confirm(`Would you like to ${act} entry ${primary_key}?`)) {
        e.preventDefault()
        axios.put(`/api/v1/${model}/${primary_key}`, {[act]: bool})
            .then(
                _ => {
                    updateDisplayStateAndRefresh()
                }, error => {
                    let message = server_error(error)
                    message && showAlert(true, message)
                }
            )
    }
}