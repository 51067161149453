import React, {PureComponent, useState} from 'react'
import API from '../../store/api'
import {NavLink, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {signIn, localSignIn} from '../../store/actions/authactions'
import configs, {login_title, login_title_small} from '../../custom/configs'
import {withRouter, BrowserRouter, Switch, Route} from 'react-router-dom'

const mapStateToProps = (state, props) => {
    return {
        ...props,
        token: state.authReducer.token || null,
        authReducer: state.authReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials)),
        localSignIn: (data) => dispatch(localSignIn(data)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let
        [message, setMessage] = useState(null),
        [state, setState] = useState({}),
        handleChange = (e) => {
            setState({...state, [e.target.id]: e.target.value})
        },
        handleSubmit = e => {
            e.preventDefault()
            API.post('/api/forgot_password', state)
                .then(
                    response => {
                        setMessage({details: response.data, type: 'info'})
                    }, error => {
                        setMessage({details: error.response.data.description, type: 'danger'})
                    })
        }
    return (
        <div style={{backgroundColor: '#00a553'}}>
            <div className="wrapper">
                <center>
                    <section className="content" style={{color: "white", marginTop: "100px"}}>
                        <div className="col-sm-12">
                            <img className='img img-responsive' style={{maxWidth: '200px'}} src="/static/img/logo.jpg"/>
                            <h1 style={{}}><span style={{color: 'black'}}>{login_title}</span></h1>
                            <p className="lead" style={{color: 'black'}}>Forgot Password</p>
                        </div>
                    </section>
                </center>
            </div>

            <div>
                <center>
                    <section className="content">
                        <div className="col-sm-12">
                            <div className="col-md-4 col-md-offset-4">
                                <div className="well">
                                    {(message && message.type === 'info') ? (
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <h4 className="text-success text">{message.details}</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <div style={{textAlign: 'center'}}>
                                                        <span className="fa fa-badge-check fa-4x"/>
                                                    </div>
                                                </div>

                                            </div>) :
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label htmlFor="payroll_no">PF No.</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input className="form-control" id="payroll_no" name="payroll_no" required type="text" value={state.payroll_no || ''} onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-4">
                                                    <label htmlFor="email">Email Address</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="input-group">
                                                        <input className="form-control" id="email" name="email" required type="text" value={state.email || ''} onChange={handleChange}/>
                                                        <span className="input-group-addon" id="sizing-addon2">{configs.domain || 'localhost.com'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6 col-md-offset-3">
                                                    <input className="btn btn-primary form-control" id="submit" name="submit" type="submit" value="Reset Password"/>
                                                </div>
                                            </div>
                                        </form>}
                                    <div>
                                        <NavLink to={'/'}>Login?</NavLink>
                                    </div>

                                    {message && message.type === 'danger' && (
                                        <div className={`alert alert-${message.type}`}>
                                            {message.details}
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </center>
            </div>
        </div>
    )
}))