
import {activity_log, problem_form, report_log, solved_problem_form} from "../../components/page_route/form/forms.global";
import users from "./users/users";
import roles_users from "./users/roles_users";
import roles from "./users/roles";
import projects from "./projects";
import finance from "./finance"
import mind_map from "./mind_map"
import business from "./business";


export const FormProperties = [
 problem_form(undefined),  users, roles_users, roles, solved_problem_form, activity_log, report_log, projects,
    ...finance,
    ...mind_map,
    ...business
]
