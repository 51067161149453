import React from "react";
import budget_filter from "./budget_filter";
import Actions from "../budget/budget_actions/budget_allocation_actions"
import before_table from "../../before_table";

export default {
    type: 'table',
    name: 'vote_heads',
    table: 'budget_vote_heads',
    tab_name: 'Budget Particulars',
    fa: 'tags',
    title: ['vote_id'],
    beforeTable: budget_filter,
    lines: [
        [
            {name: 'Budget', id: 'budget_id', type: 'select', source: 'budget.budget_description', required: true, span: 1},
            {name: 'Department', id: 'department_code', type: 'select', source: 'hr_orgunit.description', default_filter: {category: 'Department'}, disabled: true, span: 1,},
            {name: 'Sub-Department', id: 'sub_department_code', type: 'select', source: 'hr_orgunit.description', default_filter: {category: 'SubDepartment'}, disabled: true, span: 1},
            // {name: 'Year', id: 'year', type: 'select', source: 'financial_year.year', required: true, span: 1, showInModal: true},
        ], [
            {name: 'Vote Head', id: 'description', type: 'text', required: true, span: 3},
        ], [
            {name: 'Proposed Amount', id: 'proposed_amount', type: 'currency', required: true, span: 1},
            {name: 'Allocated Amount', id: 'allocated_amount', type: 'currency', required: true, span: 1},
        ], [
            // {name: 'Allocated Total', id: 'total', type: 'currency', required: true, span: 1, showInModal: false},
            {name: 'Spent Amount', id: 'spent_amount', type: 'currency', disabled: true, span: 1, showInModal: false},
            {name: 'Account Classification', type: 'text', reference: 'gl_code', id: 'account_gl.long_description', showInModal: false },
            {name: 'Actions', id: 'actions', type: 'custom', showInTable: true, component: Actions, showInModal: false, span: 1},
        ], [
            {type: 'html', value: '<u><h4>Account Classification</h4></u>', span: 3}
        ], [
            {name: 'Account Group', id: 'group_code', type: 'select', source: 'account_group.description', required: true, span: 1, default_filter: {transaction_type: 'DEBIT'}, creatable: false, showInTable: false},
            {name: 'Account Category', id: 'category_code', type: 'select', source: 'account_category.description', filterable: true, filter_reference: 'group_code', required: true, span: 1, creatable: false, showInTable: false},
            {name: 'Account Sub Category', id: 'sub_category_code', type: 'select', source: 'account_sub_category.description', filterable: true, filter_reference: 'category_code', required: true, span: 1, creatable: false, showInTable: false},
            {name: 'GL Account', id: 'gl_code', type: 'select', source: 'account_gl.description', filterable: true, filter_reference: 'sub_category_code', required: true, span: 1, creatable: false, showInTable: false},
        ],
    ],

    totals: [
        {
            'proposed_amount': function (objects) {
                let ret = objects.map(record => (record['proposed_amount'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'vat_calc': function () {
                return ''
            },
            'description': function () {
                return 'TOTAL'
            },
            'allocated_amount': function (objects) {
                let ret = objects.map(record => (record['allocated_amount'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
        },
    ],
    afterTable: before_table,


}