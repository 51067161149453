import API from "../api";
import {FormProperties} from '../../custom/forms'

export const createProject = (project) => {
    return (dispatch, getState) => {
        // make async call to database

        API.post('/projects/', {
            ...project,
            createdAt: new Date()
        }).then(() => {
            dispatch({type: 'CREATE_PROJECT', project})
        }).catch((err) => {
            dispatch({type: 'CREATE_PROJECT_ERROR', err})
        })
    }
};

export const getValues = () => {
    return (dispatch, getState) => {
        API.get('/getValue')
            .then((response) => {
                console.log('response')
            })
    }
};

export const setTitle = (params) => {
    return (dispatch) => {
        dispatch({type: 'SET_TARGET_TITLE', params})
        // dispatch({type: 'SET_TITLE', title:params.title})
    }
};

export const setVar = (data) => {
    return (dispatch) => {
        dispatch({type: 'SET_VAR', data})
        // dispatch({type: 'SET_TITLE', title:params.title})
    }
};

export const setTarget = (target) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_TARGET',
            target: target
        })
    }
}
export const getForm = (target) => {
    return (dispatch, getState) => {
        API.post('/hr/page', {target: target || getState().pageReducer.target})
            .then(response => {
                dispatch({
                    type: 'SET_FORM', page_items: response.data.data

                })
            })
            .catch(error => {
                console.log(error)
                dispatch({
                    type: 'SET_FORM', page_items: "********* Error occurred *********"
                })
            })
    }
};

export const postForm = (post_data) => {
    return (dispatch) => {
        API.post('/hr/page_post', post_data)
            .then(response => {
                console.log(typeof (response.data.data))
                dispatch({type: 'POST_FORM', key: null})
            })
            .catch(error => {
                console.log(error)
                dispatch({type: 'POST_FORM', key: null})
            })
    }
};

export const getList = (post_data) => {
    return (dispatch) => {
        API.post('/hr/page_list', post_data)
            .then(response => {
                console.log(typeof (response.data.data))
                dispatch({type: 'POST_FORM', key: null})
            })
            .catch(error => {
                console.log(error)
                dispatch({type: 'POST_FORM', key: null})
            })
    }
};

export const maximize = () => {
    return (dispatch) => {
        dispatch({type: 'MAXIMIZE_MODAL', key: null})
    }
};

// export const global_token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NzM3MjU4OTcsImlhdCI6MTU3MTEzMzg5NywibmJmIjoxNTcxMTMzODk3LCJpZGVudGl0eSI6MX0.S9SDUADqz_a3lsfO_MFa26cxiMlx6FekLKj-oTrsmGM'
const response = {
    "access_token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NzQxNDgyMzMsImlhdCI6MTU3NDE0NjQzMywibmJmIjoxNTc0MTQ2NDMzLCJpZGVudGl0eSI6MX0.8B0XLkt1-gy7o00msOOpjVvktXQmuTb_fq1eTlohQlQ"
}
export const global_token = response["access_token"]

export const api_get_data = ({...args}) => {
    return (dispatch, getState) => {
        console.log('Getting data')
        let {table, page} = args
        table = table || database_table(getState().pageReducer.target)
        page = page || 1
        const token = getState().authReducer.token || global_token
        if (table) {
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;
            // API.defaults.withCredentials = true
            // API.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
            API.get(`/api/v1/${table}?page=${page}&results_per_page=20`)
                .then((response => {
                    dispatch({type: 'TABLE_DATA', data: response.data})
                }), error => {
                    console.log(error)
                })
        } else {
            console.log("page-", page)
            console.log("table-", table)
        }
    }
}

export const api_post_data = ({...args}) => {
    return (dispatch, getState) => {
        const token = args.token || getState().authReducer.token || global_token
        const table = args.table || database_table(getState().pageReducer.target)
        const url = args.url || `/api/v1/${table}`

        API.defaults.headers.common['Authorization'] = `JWT ${token}`;
        API
            .post(url, args.data)
            .then((response => {
                        console.log('PAge Actions Posted')
                        // console.log(response.data)
                        dispatch({type: 'REFRESH TABLE', message: 'Record successfully inserted', data: response.data})
                        // dispatch(api_get_data({}))
                    }
                ), error => {
                    console.log(error)
                }
            )

    }
}

export const api_put_data = ({...args}) => {
    return (dispatch, getState) => {
        const {primary_key, data} = args
        const token = args.token || getState().authReducer.token || global_token
        const table = args.table || database_table(getState().pageReducer.target)
        const url = args.url || `/api/v1/${table}/${primary_key}`

        if (url && table && data && primary_key) {
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;
            API.put(url, data)
                .then((response => {
                            console.log('Page Actions Updated')
                            dispatch({type: 'REFRESH TABLE', message: 'Record successfully updated', data: response.data})
                            // dispatch(api_get_data({}))
                        }
                    ), error => {
                        console.log(error)
                    }
                )
        } else {
            console.log(table || "The table has not been defined")
            console.log(primary_key || "INSERTING, Primary key has not been defined")
        }
    }
}

export const database_table = (target) => {
    const form = FormProperties.find(obj => obj.name === target)
    return form ? form.table : null
}


export const setEntries = entries => {
    return (dispatch, getState) => {
        dispatch({type: 'UPDATE_ENTRIES', data: entries})
    }
};

// const API_with_token = () => {
//
// }
//
