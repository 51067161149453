const initState = {
    isAuthenticated: false
};

const authReducer = (state=initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR': 
            console.log('Login error');
            return {
                ...state, 
                authError: action.data,
                isAuthenticated: false
            };
        case 'LOGIN_SUCCESS':
            console.log('login success');
            let {access_token: token, ...login_details} = action.data
            return {
                ...state,
                token,
                ...login_details,
                authError: null,
                isAuthenticated: true
            };
        case 'SIGNOUT_SUCCESS':
            localStorage.removeItem('persist:root')
            return {
                ...state,
                token:null,
                isAuthenticated: false
            }
        case 'SIGNUP_SUCCESS':
            console.log('signup success');
            return {
                ...state,
                authError: null
            };
        case 'SIGNUP_ERROR':
            console.log('signup error');
            return {
                ...state,
                authError: action.err.message
            };
        default:
            return state
    }
};

export default authReducer