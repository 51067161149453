import React from 'react'
import ReactDOM from 'react-dom'

export const ModalAlert = (props) => {
    let {showModal, alert_status} = props,
        closeModal = (e) => {
            e && e.preventDefault()
            props.showAlert(false)
        },
        marginClicked = (e) => {
            if (e.target === e.currentTarget) {
                props.showAlert(false)
            }
        },
        process_message = (message) => {
            let {error, description} = message
            if (error) {
                return error
            } else if (description) {
                return description
            }
            return typeof message === 'string' ? message : JSON.stringify(message)
        },
        modal_class = `modal fade ${showModal && 'in'}`,
        modal_style = showModal ? {display: 'block', overflow: 'scroll', zIndex: 10000} : null,
        modal_message = process_message(props.message),
        modal_color = alert_status === 'warning' ? 'alert-warning' : alert_status === 'confirm'? 'alert-primary': 'alert-error',
        modal_content = alert_status !== 200 ?
            <div className={`modal-content alert ${modal_color}`}>
                <div className="modal-header">
                    <div className="col-xs-8">
                        <h3 style={{padding: 0, margin: 0}}>{alert_status === 'confirm' ? 'Confirm Dialog': 'Error'}</h3>
                    </div>
                    <div className="col-xs-1 pull-right">
                        <button type="button" className="close" onClick={closeModal} aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                </div>
                <div className="modal-body">
                    {/*{modal_message}*/}
                    {/*<pre className={modal_color} style={{whiteSpace: 'break-spaces'}}>{modal_message}</pre>*/}
                    {modal_message}
                    {
                        alert_status === 'confirm' && (
                            <div className={'row'}>
                                <button className='btn btn-primary col-md-offset-2 col-md-4'>OK</button>
                                <button onClick={closeModal} className='btn btn-default col-md-4'>Cancel</button>
                            </div>
                        )
                    }
                </div>
            </div> :
            <div className="modal-content alert alert-success">
                <div className="modal-body">
                    <button type="button" className="close" onClick={closeModal} aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div className='text-center'>{modal_message}</div>
                </div>
            </div>
    alert_status === 200 && setTimeout(() => closeModal(null), 2000)

    return ReactDOM.createPortal(
        <div onClick={marginClicked} className={modal_class} id="fa_modal_window" tabIndex="1000" role="dialog" aria-labelledby="fa_modal_label" style={modal_style}>
            <div className={`modal-dialog modal-dialog-centered modal-md`} role="document">
                {modal_content}
            </div>
        </div>, document.body
    )
}