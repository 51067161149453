let options = [
        {value: 'O', name: 'Operating Activities'},
        {value: 'I', name: 'Investing Activities'},
        {value: 'F', name: 'Financing Activities'},
        {value: 'C', name: 'Cash and Cash Equivalents'},
    ],
    actions = [
        {value: 'DEBIT', name: 'ADD'},
        {value: 'CREDIT', name: 'SUBTRACT'},
    ],
    cash_flow_setup_accounts = {
        type: 'table',
        name: 'cash_flow_setup_accounts',
        table: 'cash_flow_setup_accounts',
        tab_name: 'Cash Flow Activity Accounts',
        below: true,
        lines: [
            [
                {name: 'Account Categories', id: 'sub_category_code', type: 'select', source: 'account_sub_category.long_description', span: 3, required: true},
            ]
        ]
    }

export default {
    type: 'table',
    name: 'cash_flow_setup',
    table: 'cash_flow_setup',
    tab_name: 'Cash Flow Activities',
    lines: [
        [
            {name: 'Cash Flows Category', id: 'category', type: 'select', options, required: true},
            {name: 'Activity Description', id: 'description', type: 'text', required: true},
            {name: 'Action', id: 'transaction_type', type: 'select', options: actions}
        ], [
        ], [
            cash_flow_setup_accounts
        ]
    ]
}