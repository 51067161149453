import React from 'react'
import {string_processor} from "../../table/utilities";

export default  Component => {
    return (props={}) => {
        let {tooltip = null, id, type = "text", required, form_state, show_label = true, errors={}, value} = props,
            {name = (id && string_processor(id)) || "-"} = props

        return (
            <div className="form-group-sm" data-tip={tooltip}>
                {show_label && name && type !== 'hidden' && <label className={`control-label ${(true || form_state === 'details') && 'text-sm'}`} htmlFor={id}>{name} <span className='text-red blink'>{required && (value === undefined || value === null) && '*'} {errors[id] && errors[id]}</span></label>}
                <Component {...props}/>
            </div>
        )
    }
}
/*
export const a = Component => {
    return props => {
        let {tooltip = null, id, type = "text", required, form_state, show_label = true} = props,
            {name = (id && string_processor(id)) || "-"} = props
        return (id && type) ? (
            <div className="input-container">
                {show_label && name && type !== 'hidden' && <label htmlFor={id}>{name} {required && <span className='text-red'>*</span>}</label>}
                <Component {...props}/>
            </div>
        ) : null
    }
}*/

