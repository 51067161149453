import React from 'react'
import SelectField from "../../../components/page_route/form/formelements/select";
import element_style from "../../../components/page_route/form/formelements/element_style";
import {withRouter} from 'react-router-dom'

export default withRouter(function (props) {
    let
        {state, setLocalState} = props,
        search_params = {},
        setState = (obj) => {
            let others = {...state}
            Object.keys(obj).forEach(key => {
                others[key] = obj[key]
            })
            setLocalState(others)
        },
        handleSelect = (object) => {
            setState(object)
        },
        handleText = (e, object) => {
            if (e) {
                e.preventDefault && e.preventDefault()
                setState({[e.target.id]: e.target.value})
            } else {
                setState(object)
            }
        },
        showAlert = _ => {
        },
        onPressEnter = _ => {
        },
        setVariables = _ => {
        },
        select_params = {
            showAlert, fullId: 'department',
            form_values: {...state}, form_state: 'insert', setState,
            onPressEnter, primary_key_columns: [], setVariables, errors: {}, show_button: false
        },
        handlePrint = (e) => {
            e && e.preventDefault()
            try {
                window.frames['trial_balance_report'].focus()
                window.frames['trial_balance_report'].print()
            } catch (e) {
                try {
                    window.print()
                } catch (e) {
                    console.log('Error on printing', e, Object.keys(e))
                    this.props.showAlert(true, e.toString())
                }
            }
            // eslint-disable-next-line no-unused-expressions
            // window.frames[report_target].preview
        },
        handleRefresh = (e) => {
            e.preventDefault()
            setState({refresh: Math.random()})
        },
        handleGoto = (e) => {
            e.preventDefault()
            props.history.push(`/page/mind?page=1&search_params={"id":${state.id}}`)
        }
    return (
        <div className='row'>
            <div className="col-md-3">
                <SelectField {...select_params} {...{name: 'Mind Root', id: 'id', source: 'mind.list_title', value: state.id || (search_params || {})['id'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-1" style={{minWidth: 100}}>
                {
                    element_style(function () {
                        return <p>
                            <button className="btn btn-sm btn-success" title="Print Report" onClick={handleGoto}><span className="fa fa-file-o"/> <i className='fa fa-arrow-right'/>Goto</button>
                            <button className="btn btn-sm btn-primary" title="Print Report" onClick={handleRefresh}><span className="fa fa-file-o"/> <i className='fa fa-sync'/>Refresh</button>
                        </p>
                    })({name: <></>})
                }
            </div>
            <div className="col-md-4">
                <h3 style={{textAlign: 'center', fontWeight: 1000}}>{state.report_title}</h3>
            </div>
            <div className="col-md-1 pull-right" style={{minWidth: 100}}>
                {
                    element_style(function () {
                        return <p>
                            <button className="btn btn-sm btn-warning" title="Print Report" onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
                        </p>
                    })({name: <></>})
                }
            </div>
        </div>
    )
})