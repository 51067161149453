import React, {useState} from 'react'
import {mapStateToProps, FormSuper} from "../FormSuper"
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import MultiSelectField from "../formelements/multiselect";
import {getFields, getHeaders, getRequired} from "./import_utilities";
import {ReportIframe} from "../../report_iframe";
import {Modal} from "../modal";

export default (props) => {
    let
        {table_data, search_params} = {},
        {showAlert, default_fk, signOut, form, action} = props,
        // results_per_page = this.props.entries || this.state.results_per_page,
        {target = '...', page_name} = props,
        setIndex = () => null,
        reloadPage = () => null,
        {num_results, primary_key: primary_key_column, primary_keys: primary_key_columns, total_pages, page: table_page} = {},

        // eslint-disable-next-line react-hooks/rules-of-hooks
        [show_modal, toggleModal] = useState(true),
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [record_values, setRecordValues] = useState(null),
        // eslint-disable-next-line react-hooks/rules-of-hooks
        [form_state, setFormState] = useState('insert'),
        updateDisplayStateAndRefresh = (params) => {
            Object.keys(params).includes('form_state') && setFormState(params.form_state)
            Object.keys(params).includes('show_modal') && toggleModal(params.show_modal)
        },
        hideModal = () => {
            toggleModal(!show_modal)
            setRecordValues(null)
            setFormState('details')
        },
        updateDisplayState = (args) => {
            setFormState(args.form_state);
            (args.record_values === null) && setRecordValues(null)
        },
        refreshTable = () => {
            setFormState(null)
            toggleModal(false)
            // setRecordValues(null)
            this.props.refreshTable()
        },
        params = {
            form_state, hideModal,
            num_results, page_name, primary_key_column, primary_key_columns, record_index: null, record_values,
            refreshTable, reloadPage, setIndex, showAlert, table_page, target, show_modal, updateDisplayState,
            updateDisplayStateAndRefresh, total_pages,
            show_header: true, default_fk, search_params, clear_search: () => null, form
        },
        ModalForm = withRouter(connect(mapStateToProps, null)(class extends Modal {
                handleSubmit = (e, callback) => {
                    e && e.preventDefault()
                    this.errors = this.validate()
                    if (Object.keys(this.errors).length > 0) {
                        this.props.showAlert(true, Object.values(this.errors).join('. '), 'error')
                        this.forceUpdate()
                        return
                    }
                    // document.getElementById("import_form").addEventListener("submit", _ => {
                    //     console.log('Form submitted')
                    // }, false)
                    document.getElementById("import_form").submit()
                    // toggleModal(!show_modal)
                }
                FormDiv = ({children, ...params}) => (
                    <form action={action} id="import_form" className="col-md-12 blue-back" target='ImportWindow' method='post' encType='multipart/form-data'>
                        {children}
                    </form>
                )
            }
        ))

    return (
        <>
            <ModalForm {...params} form={form} target={'importation'}/>

        </>
    )

}

// let OldForm = _ => {
//     let populated_form = import_lines ? this.populateForm({lines: import_lines}).populated_form : null
//     return (
//         <>
//             <div className="col-md-12 text-red">
//                 <h3>Note:</h3>
//                 <h4>Take caution when making BULK IMPORTS.</h4>
//                 <h4>The process is not reversible if mistakes are made or inputs not put.</h4>
//                 <h4>Confirm everything before submission.</h4>
//             </div>
//             <div className="col-md-12">
//                 <b>Confirm the excel has the following fields (or define some of them as defaults):</b> <br/>{required_names && required_names.join(', ')}
//             </div>
//             <div className="box-body">
//                 <form action={action} id="import_form" className="col-md-12 blue-back" onSubmit={onSubmit} target='ImportWindow' method='post' encType='multipart/form-data'>
//                     {populated_form}
//                     <MultiSelectField id='fields' name='Excel Fields Arrangement' options={options} handleChange={handleFields} defaultvalue={form.import_fields} value={this.state.fields} form_state={form_state} required={true}/>
//                     {/*<File id='file' name='Excel File' handleChange={handleFile} value={file} type='file' form_state={form_state} required={true}/>*/}
//                     <div className="form-group-sm">
//                         <label className={`control-label text-sm'}`} htmlFor={'field'}>Excel File<span className='text-red'>*</span></label>
//                         <input className="form-control" type='file' style={{...{margin: 0, padding: 0}}} id='file' name='file' required={true}/>
//                     </div>
//                     <div className="form-group-sm text-center">
//                         <button type="submit" className="btn btn-primary" style={{width: '50%'}}>Import Data</button>
//                     </div>
//                 </form>
//             </div>
//         </>
//     )
// }