import React, {useState} from 'react'
import {getFields, getHeaders, getRequired} from "./import_utilities";
import ImportForm from "./ImportFormSuper";
import {ReportIframe} from "../../report_iframe";
import {element_id} from "../../table/utilities";
import SelectField from "../formelements/select";

export default (props) => {
    let {show_import, form: passedForm, showAlert, setState, api_get_data, height, signOut} = props,
        {import_lines = [], import_fields = []} = passedForm,
        headers = getHeaders(passedForm) || [{}],
        options = getFields(headers),
        required = getRequired(headers),
        required_names = required.map(req => req.name),
        CustomComponent = function(props){
            let
                {form_values} = props,
                [defaults, setDefaults] = useState({}),
                [show, changeShow] = useState(false),
                [defaultField, changeDefaultField] = useState(null),

                deleteDefault = (e, key) => {
                    changeShow(false)
                    e.preventDefault()
                    this.setState({[key]: null})
                },
                addDefaultValue = (e) => {
                    e.preventDefault();
                    changeShow(!show)
                    changeDefaultField(null)
                },
                {populateForm} = props,
                buildInputField = (form_element) => {
                    return populateForm({
                        lines: [
                            [{...form_element, span: 3, col: 12}]
                        ]
                    }).populated_form
                },
                predefined_fields = getHeaders({lines: import_lines}).map(obj => element_id(obj.id)),
                {fields, ...default_items} = form_values
                predefined_fields.forEach(item => {
                    delete default_items[item]
                })
                Object.keys(default_items).forEach(key => {
                    if (default_items[key] == null){
                        delete default_items[key]
                    }
                })
            return (
                <div className={'row'}>
                    <div className="col-md-12 text-red">
                        <h3>Note:</h3>
                        <h4>Take caution when making BULK IMPORTS.</h4>
                        <h4>The process is not reversible if mistakes are made or inputs not put.</h4>
                        <h4>Confirm everything before submission.</h4>
                    </div>
                    <div className="col-md-12">
                        <b>Confirm the excel has the following fields (or define some of them as defaults):</b> <br/>{required_names && required_names.join(', ')}
                    </div>
                    <div className="col-md-12">
                        <b>Default Values:</b>
                        <a href='/#' onClick={addDefaultValue} title='Add Default Value'><i className={`fa fa-${show ? `minus` : `plus`}`}/></a>
                        <input type="hidden" name="defaults" value={JSON.stringify(default_items)}/>
                    </div>
                    <div className="col-md-12">
                        {Object.keys(default_items).map((item, index) => {
                                let header = headers.find(header => element_id(header.id) === item)
                                return header ? (
                                    <div key={index} className="col-md-3">
                                        {header.name} : {form_values[item]}
                                        <a href='/#' onClick={e => deleteDefault(e, item)}> <i className='fa fa-times'/></a>
                                    </div>
                                ) : null
                            }
                        )}
                    </div>
                    {show && (
                        <div className="">
                            <div className="col-md-6">
                                <SelectField id='field' options={options} name={'Default Field'} handleChange={changeDefaultField} value={defaultField && defaultField['field']}/>
                            </div>
                            <div className="col-md-6">
                                {defaultField && defaultField['field'] && buildInputField(headers.find(item => element_id(item.id) === defaultField['field']))}
                            </div>
                        </div>
                    )}
                    <hr/>
                    <input type="hidden" name='defaults' id="defaults" value={JSON.stringify(defaults)}/>
                </div>
            )
        }
        ,
        default_form = {
            ...passedForm,
            tab_name: 'Import',
            lines: [
                [{
                    type: 'custom', span: 3, component: CustomComponent
                }],
                ...import_lines,
                [
                    {name: 'Excel Fields Arrangement', id: 'fields', type: 'multiselect', options, defaultValue: import_fields, required: true, span: 3}
                ], [
                    {
                        type: 'custom', component: _ => {
                            return (
                                <div className="form-group-sm">
                                    <label className={`control-label text-sm`} htmlFor={'field'}>Excel File<span className='text-red'>*</span></label>
                                    <input className="form-control" type='file' style={{...{margin: 0, padding: 0}}} id='file' name='file' required={true}/>
                                </div>
                            )
                        }
                    }
                ]
            ]
        },
        [form, setImportForm] = React.useState(default_form),
        style = {height: height + 90},
        closeImport = () => {
            setState({show_import: false})
            api_get_data()
        },
        default_action = `/import/${form.table}`,
        [action, setAction] = React.useState(null),
        onLoadEvent = e => {
            let contentDocument = e.target.contentDocument,
                contentType = contentDocument && contentDocument.contentType,
                textContent = contentDocument && contentDocument.documentElement.textContent
            if (contentType === 'application/json' && textContent) {
                let message3 = JSON.parse(textContent)
                if (message3 && message3.form) {
                    setImportForm({...passedForm, ...message3.form})
                }
                if (message3.status_code === 200) {
                    // hideModal()
                    // props.closeImport()
                }
            }
        },
        [show_modal, setShowModal] = React.useState(true),
        [iframe_key, setIframeKey] = React.useState(Math.random()),
        updateDisplayState = obj => {
            let {show_modal} = obj
            show_modal !== undefined && setShowModal(show_modal)
        },
        onClickFormButton = _ => {
            setShowModal(true)
            setState({show_import: true})
        },
        onClickList = _ => {
            setIframeKey(Math.random())
            setAction(default_action)
        }
    React.useEffect(function () {
        setImportForm(default_form)
    }, [show_import])
    return (
        <div className={`box box-primary`} style={{...style, display: show_import ? 'block' : 'none'}}>
            <div className="box-header with-border">
                <h3 className="box-title">Import Data</h3>
                <div className="box-tools pull-right">
                    <button className={'btn btn-success btn-xs'} style={{minWidth: 50, marginRight: 5}} onClick={onClickList}>List</button>
                    <button className={'btn btn-primary btn-xs'} style={{minWidth: 50, marginRight: 5}} onClick={onClickFormButton}>Import Form</button>
                    {/*<button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"/>*/}
                    {/*</button>*/}
                    <button type="button" className="btn btn-box-tool" data-widget="remove" onClick={closeImport}><i className="fa fa-times"/></button>
                </div>
            </div>
            <div className="box-body">
                {show_import && show_modal && <ImportForm form={form} action={default_action} showAlert={showAlert} closeImport={closeImport} signOut={signOut}/>}
                <div className="row" style={{overflow: 'hidden'}}>
                    <div className="col-md-12">
                        <ReportIframe key={iframe_key} style={{minHeight: window.innerHeight - 200}} showAlert={showAlert} src={action} name='ImportWindow' title='ImportWindow' onLoadEvent={onLoadEvent} signOut={signOut} updateDisplayState={updateDisplayState}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
