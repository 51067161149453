import React from 'react'
import {icon_large, icon_small} from '../../../custom/configs'

const logo = () => {
    return (
        <a href="/#" className="logo" onClick={e=>e.preventDefault()}>
            <span className="logo-mini"><b>{icon_small}</b></span>
            <span className="logo-lg"><b>{icon_large} </b></span>
        </a>
    )
}

export default logo