import React from 'react'

let style = {style: {verticalAlign: 'middle'}}

export default function (props) {
    let {form_state} = props
    return form_state === 'insert' ? (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <h3><u>Notes</u></h3>
                </div>
            </div>
            <div className='row'>
                <div className="col-md-6">
                    <h4><u>Rules of Accounting</u></h4>
                    <table className='table table-bordered table-striped'>
                        <thead>
                        <tr>
                            <th>Rule</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1.</td>
                            <td>Receiver</td>
                            <td>Giver</td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>Moneys Coming In</td>
                            <td>Moneys Going Out</td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Expenses & Losses</td>
                            <td>Incomes and Gains</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6">
                    <h4><u>Trial Balance</u></h4>
                    <table className='table table-bordered table-striped'>
                        <thead>
                        <tr>
                            <th>Statements</th>
                            <th>Debit</th>
                            <th>Credit</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td rowSpan={2} {...style}>Financial Position / Balance Sheet</td>
                            <td rowSpan={2} {...style}>Assets</td>
                            <td>Equity</td>
                        </tr>
                        <tr>
                            <td>Liabilities</td>
                        </tr>
                        <tr>
                            <td rowSpan={2} {...style}>Comprehensive Income / P & L</td>
                            <td>Profits</td>
                            <td rowSpan={2} {...style}>Revenue</td>
                        </tr>
                        <tr>
                            <td>Expenses</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    ) : null
}