import {current_date} from "../../../../../components/utilities";


export default {
    type: 'table',
    name: 'budget_allocation_form',
    tab_name: `Budget Allocation`,
    fa: 'question-circle',
    table: 'budget_vote_heads',
    title: [],
    lines: [
        [
            {name:'Allocated Amount',id:'allocated_amount',type:'text',required:true},
            {name:'Allocation Date',id:'allocation_date',type:'date', defaultValue:current_date()},
            ],
            [
                {name:'Remarks',id:'remarks',type:'textarea',span:3},
            ]
        ]
}