import user_menus from "./user_menus";
import finance_menu from "./finance_menu";
// import mindmap_menu from "./mindmap_menu";
import business_menus from "./business_menus";

export default [user_menus, business_menus, {
    name: 'Business',
    target: 'business',
    fa: 'fa-briefcase',
    roles: ['investor'],
    children: [
        {name: 'Investor Report', target: 'investor_report'},
    ]
}, finance_menu]