import capital from "./capital";
import expenses from "./expenses";
import photos from "./photos";
import balance_sheet from "./balance_sheet";

let options = [
    {value: 'ongoing', name: 'Ongoing'},
    {value: 'complete', name: 'Complete'},
]
export default {
    type: 'table',
    table: 'projects',
    name: 'projects',
    tab_name: 'Projects',
    title: ['name', 'location', 'start_date'],
    fa: 'digging fas',
    // oneItemAsForm: true,
    lines: [
        [
            {name: 'Project Name', id: 'name', type: 'text', required: true},
            {name: 'Location', id: 'location', type: 'text', required: true},
        ], [
            {name: 'Start Date', id: 'start_date', type: 'date'},
            {name: 'End Date', id: 'end_date', type: 'date'},
            {name: 'Status', id: 'status', type: 'select', options},
            {name: 'Supervisor', id: 'user_id', type: 'select', source: 'User.full_name'},
        ], [
            {name: 'Project Budget', id: 'cost', type: 'currency'},
            {name: 'Total Capital', id: 'total_capital', type: 'currency', disabled: true},
            {name: 'Total Expense', id: 'total_expenses', type: 'currency', disabled: true},
            {name: 'Total Balance', id: 'total_balance', type: 'currency', disabled: true},
        ], [
            capital,
            expenses,
        ], [
            {type: 'tab', tab_name: 'Balance Sheet', fa: 'file-invoice', component: balance_sheet}
        ], [
            {...photos, span:3}
        ]
    ],
    beforeTable: function () {
        let body = document.body;
        body.classList.add("sidebar-collapse");
        return null
    }
}