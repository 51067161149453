import React from 'react'
import {current_date, english_date} from "../../components/utilities";
import {after_table} from "../../components/page_route/form/utilities";
import {api_prefix} from "../../store/api";

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export default {
    type: 'table',
    table: 'photos',
    name: 'photos',
    tab_name: 'Photos',
    fa: 'camera fas',
    lines: [
        [
            {name: 'Project Name', id: 'project_id', type: 'select', source: 'projects.name'},
            {name: 'Details', id: 'details', type: 'details'},
            {name: 'Date', id: 'date', type: 'date', defaultValue: current_date()},
        ], [
            {name: 'Photo', id: 'photo_path', type: 'file', span: 3, attributes: {width: 'auto', maxHeight: window.innerHeight-300}},
        ], [
            {name: 'Remarks', id: 'remarks', type: 'text', span: 3}
        ]
    ],
    rowComponent: function (props) {
        return null
    },
    afterTable: function ({table_data}) {
        let {objects = [], primary_keys: primary_key_columns} = table_data
        console.log('Props are ', table_data)

        let dates = objects.map(row => row.date).filter(onlyUnique).sort().reverse()
        return <div style={styles.page}>{
            dates.map(date => {
                return [
                    <h5>{english_date(date)}</h5>,
                    <div style={styles.photobox}>{
                        objects.filter(record => record.date === date)
                            .sort(function(a,b) {return (a.photo_id < b.photo_id) ? 1 : ((b.photo_id < a.photo_id) ? -1 : 0);} )
                            .map(record => {
                            let value = `/api/v1/static${record.photo_path}`
                            return (<div style={styles.center}>
                                <a style={styles.photo} onClick={e => this.handleDetails(e, objects.map(obj => obj.photo_id).indexOf(record.photo_id))}><img className='img-responsive img thumbnail' src={value} alt="" style={{maxHeight: '400px'}}/></a>
                            </div>
                            )

                        })
                    }
                    </div>,

                ]
            })
        }</div>
    }
}

let styles = {
    page: {
        padding: 0,
        // backgroundColor: 'white'
    },
    photobox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        flexWrap: 'wrap',
        overflow: 'scroll',
    },
    center: {
        margin: '0 auto'
    },
    photo: {
        flex: window.innerHeight > 1000 ? '33%': '100%',
        // flex: '1 0 33%'
    }
}