import React, {useState} from 'react'

let details = {type: 'textarea', id: 'details', name: 'Details', span: 3, attributes: {maxHeight: 800}},
    options = [
        {value: 'text', name: 'Text'},
        {value: 'text_area', name: 'Text Area'},
        {value: 'date', name: 'Date'},
        {value: 'file', name: 'File'},
    ]
let details_type = {type: 'select', id: 'details_type', name: 'Details Type', options, defaultValue: 'text'}

let DetailsComponent = function (props) {
    let [show_details, setShowDetails] = useState(props.form_values.details)
    let addDetails = function (e) {
        e.preventDefault()
        setShowDetails(true)
    }
    if (show_details) {
        return props.populateForm({
            lines: [
                (props.form_state === 'update') ? [
                    {}, {}, {...details_type}
                ] : [{}], [
                    {...details, type: props.form_values.details_type || 'textarea', span: 3}
                ]]
        }).populated_form
    } else {
        if (props.form_state === 'update')
            return <button className='btn btn-primary' onClick={addDetails}>+ Add Details</button>

    }
}

let Children = function (props) {
    let [show_children, setShowChildren] = useState(false)
    let addDetails = function (e) {
        e.preventDefault()
        setShowChildren(!show_children)
    }
    return [
        <button className='btn btn-primary' onClick={addDetails}>{show_children ? 'Hide' : 'Show'} Children</button>,
        show_children ? props.populateForm({
            lines: [[
                {type: 'table', target: 'mind_table', pkey: 'id', fkey: 'parent_id', label: 'Children', below: true}
            ]]
        }).populated_form : null
    ]
}

let map = {
    type: 'table',
    name: 'mind',
    table: 'mind',
    tab_name: 'Mind',
    title: ['title'],
    oneItemAsForm: true,
    lines: [[
        {type: 'text', id: 'title', name: 'Title', span: 3},
        {...details, showInModal: false}
    ], [
        {type: 'custom', span: 3, component: DetailsComponent},
    ], [
        {type: 'custom', span: 3, component: Children},
    ], [
        // {type: 'table', target: 'mind', below: true, pkey: 'id', fkey: 'parent_id', oneItemAsForm: false}
    ], [
        {}, {type: 'select', source: 'mind.title', id: 'parent_id', name: 'Parent'}
    ]],
    processor: function (state, props, prevState, prevProps) {
        let {parent_id} = state,
            {variables = {}, form_state, default_fk: {parent_id: fk_parent_id}={}} = props,
            {parent_id: stored_parent_id} = variables
        if (form_state === 'insert' && !fk_parent_id && (parent_id === undefined) && (stored_parent_id && !parent_id)) {
            this.setState({parent_id: stored_parent_id})
        }
        if (form_state === 'insert' && parent_id && parent_id !== stored_parent_id) {
            this.props.dispatch((dispatch) => {
                dispatch({type: 'SET_VAR', data: {parent_id}})
            })
        }
    },
    alwaysProcess: true,
    hideContentHeader: (window.location !== window.parent.location)
}

export default [
    map,
    {...map, oneItemAsForm: undefined, name: 'mind_table'}
]