import approval_actions from "./payment_request_actions";
import before_table from "../../before_table";

let pay_methods = [
    {value:'CASH'},
    {value:'MPESA'},
    {value:'EFT'},
    {value:'CHEQUE'},
    {value:'BANK TRANSFER'},
    {value:'SWIFT'},
]
let pv_status = [
    {value:'CHECKED'},
    {value:'APPROVED'},
    {value:'DECLINED'},
    {value:'SUSPENDED'},
]
export default {
    type: 'table',
    name: 'pv_update',
    tab_name: 'Payment Voucher',
    fa: 'clock',
    table: 'payment_voucher',
    title: ['voucher_no'],
    lines: [
        [
            {name: 'Payment Request Number', id: 'request_no', type: 'select', source: 'payment_request.request_no', span: 1,showInTable: true},
            {name: 'Voucher No', id: 'voucher_no', type: 'text'},
        ], [
            {name: 'Payment Method', id: 'payment_method', type: 'select', options: pay_methods, showInTable: true, span: 1},
            {name: 'Percentage', id: 'payment_percentage', type: 'number',showInTable: true,showInModal: true},
            // {name: 'On Account Of', id: 'description', type: 'text',showInTable: true,showInModal: false},
            {name: 'Status', id: 'pv_status', type: 'select', defaultValue: 'CHECKED', options: pv_status},
        ], [
            {name: 'Prepared By', id: 'prepared_by', type: 'select', source: 'pay_employee.full_name', span: 1},
            {name: 'Date Prepared', id: 'date_prepared', type: 'date'},
            {name: 'Checked By', id: 'checked_by', type: 'select', source: 'pay_employee.full_name', span: 1},
            {name: 'Date Approved', id: 'date_checked', type: 'date'},
        ],
        [
            {name: 'On Account of', id: 'description', type: 'textarea', span:3, required: true},

        ],
        [
            {name: 'Actions', id: 'actions', type: 'custom', showInTable: true, component: approval_actions(), showInModal: false, span: 3},
        ]
    ],
    beforeTable:before_table
}