import React, {PureComponent} from 'react'
import {Map, TileLayer, LayersControl, GeoJSON, ScaleControl, GridLayer, withLeaflet} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import {layers, CreateMaker, isFloat, calculateCenter, geoJSONStyle, onEachFeature, defaultInfo, PathPlot, mapbox_vector} from './map_utils'
import * as bar from "./geojson/kenya.json";
import {connect} from 'react-redux'
import MapboxLayer from "./MapboxLayer";
import L from 'leaflet';
// import 'leaflet-draw/dist/leaflet.draw.css'

import {latLng} from "leaflet/dist/leaflet-src.esm";
// <link rel="stylesheet" href="http://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.3/leaflet.css"/>
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MapBoxGLLayer = withLeaflet(class extends GridLayer {
    createLeafletElement = (props) => {
        return L.mapboxGL(props)
    }
})

export const
    setter = zoom => {
        return (dispatch, getState) => {
            (getState().pageReducer.variables.zoom !== zoom) && dispatch({type: 'SET_VAR', data: {zoom}})
        }
    },
    mapStateToProps = (state, ownProps) => {
        let {variables} = state.pageReducer,
            {form} = ownProps,
            {zoom = form ? form.zoom : 11} = variables
        return {
            ...ownProps,
            zoom: zoom,
        }
    },
    mapDispatchToProps = (dispatch) => {
        return {
            setZoom: zoom => {
                dispatch(setter(zoom))
            }
        }
    },
    MyTileLayer = (props) => {
        console.log('Props passed to tile layer are', props)
        return <TileLayer {...props}/>
    },
    OSMMap = class extends PureComponent {
        state = {
            lat: -1.274530,
            lng: 36.811225,
            zoom: 13
        }

        constructor(props) {
            super(props);
            this.mapref = React.createRef()
        }

        center = [-1.274530, 36.811225]

        componentDidMount() {
            let {preprocessor} = this.props
            preprocessor && this.run_preprocessor()
            this.invalidateMap()
            this.setCenter()
        }


        componentDidUpdate(prevProps, prevState, snapshot) {
            let {preprocessor} = this.props
            if (prevProps.form_values !== this.props.form_values) {
                preprocessor && this.run_preprocessor()
            }
            this.invalidateMap()
        }

        run_preprocessor = () => {
            this.setState(this.props.preprocessor(this.state, this.props))
        }

        handleClick = (e) => {
            // this.setState({currentPos: e.latlng});
            let lat_long = e.latlng
            this.updateForm(lat_long)
        }

        updateForm = (lat_long) => {
            this.props.handleChange({
                preventDefault: () => null,
                target: {
                    id: 'lat',
                    value: lat_long.lat
                }
            })
            this.props.handleChange({
                preventDefault: () => null,
                target: {
                    id: 'lon',
                    value: lat_long.lng
                }
            })

        }

        handleDrag = (e) => {
            console.log('Dragged', e)
            let lat_long = e.target._latlng
            this.updateForm(lat_long)
        }

        invalidateMap() {
            this.mapref.current.leafletElement.invalidateSize()
        }

        setCenter = _ => {
            let
                {form_values} = this.props,
                {lat, lon} = form_values || {},
                {points = []} = this.props,
                position = lat && lon && form_values,
                center = (position && isFloat(lat) && isFloat(lon)) ?
                [lat, lon] :
                (points.length > 0 && isFloat(points[0].lat) && isFloat(points[0].lon)) ? [points[0].lat, points[0].lon] : calculateCenter(points) || this.center
            this.setState({center})

        }

        render() {
            let {form_values, createInfo = defaultInfo} = this.props,
                {lat, lon} = form_values || {},
                {setZoom, zoom = this.state.zoom, style = {height: '550px'}} = this.props,
                {points = []} = this.props,
                position = lat && lon && form_values,
                center = this.state.center || this.center
            return (
                <Map center={center} zoom={zoom} onClick={this.handleClick} style={style} ref={this.mapref} onViewportChanged={viewport => setZoom(viewport.zoom)}>
                    {/*<TileLayer {...layers[0]}/>*/}
                    <LayersControl>
                        {mapbox_vector.map((layer, index) => (
                            <LayersControl.BaseLayer key={index} name={`${layer.name}`} checked={Boolean(layer.checked)}>
                                <MapboxLayer
                                    // url={'http://{s}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v7/{z}/{x}/{y}.mvt'}
                                    accessToken={'pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'}
                                    {...layer}
                                    // style='https://api.maptiler.com/maps/streets-v7/style.json?key=pk.eyJ1IjoiZmVqYXA3MTMzNCIsImEiOiJja2UxZWtxM3AxcGNzMzBvYWRmcGJ3cjgwIn0.rEYIACafl3u46JSLTbbB0w'
                                />
                            </LayersControl.BaseLayer>
                        ))}
                        {layers.map((layer, index) => (
                            <LayersControl.BaseLayer key={index} name={layer.name} checked={Boolean(layer.checked)}>
                                <MyTileLayer {...layer}/>
                            </LayersControl.BaseLayer>
                        ))}
                    </LayersControl>
                    <ScaleControl/>
                    <GeoJSON key={1} data={bar.default.features} style={geoJSONStyle} onEachFeature={onEachFeature}/>
                    {position && (isFloat(position.lon) && isFloat(position.lat)) && <CreateMaker position={position} createInfo={createInfo} onDragend={this.handleDrag}/>}
                    {/*{points.length && points.map((position, index) => <CreateMaker key={index} position={position} createInfo={createInfo} onDragend={this.handleDrag}/>)}*/}
                    {/*{points.length && createPolyline(points)}*/}
                    {points.length && <PathPlot {...this.props}/>}
                </Map>
            )
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(OSMMap)


/*<Polyline key={5} positions={[["-1.247423", "36.8858543"], ["-1.222053", "36.836318"],]} color={'red'}/>*/
