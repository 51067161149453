// https://dev.to/achowba/building-a-modal-in-react-15hg
// https://medium.com/@lucksp_22012/pure-react-modal-6e562a317b85
import React, {PureComponent, useState, useEffect} from 'react';
import FormTable from './formelements/form_table'
import {global_token, database_table, maximize} from '../../../store/actions/pageactions'
import {signOut} from '../../../store/actions/authactions'
import {element_id, getForm, calcSize} from '../table/utilities'
import {NavLink} from 'react-router-dom'
import API from '../../../store/api'
import {server_error} from "../errors";
import configs, {buttons_top, oneItemAsForm, subTableBelow as subtablepos} from "../../../custom/configs";
import {build_url, build_primary_key_value, exact_primary_key_column, isSubset, escape_characters, build_default_fk} from './utilities'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {icon_generator} from "../../../custom/forms/icons";
import FormGenerator from "./FormGenerator"
import axios from 'axios'

let subTableBelow = (form = {}) => {
    return form.subtablebelow || subtablepos
}
const report_target = configs.report_target

export class FormSuper extends FormGenerator {
    old = {}

    componentDidMount() {
        super.componentDidMount();
        let {form} = this.props
        Object.keys(form).forEach(key => {
            if (typeof form[key] === 'function') {
                if (this[key]) {
                    this.old[key] = this[key]
                }
                this[key] = form[key].bind(this)
            }
        })
    }

    keyListeners = (e, obj) => {
        if (e.code === 'Escape') {
            let {form_state, default_fk, updateDisplayState, form, show_modal} = this.props,
                main_window = subTableBelow(form) ? 0 : -1
            if (obj) {
                if (default_fk)
                    updateDisplayState({show_modal: false})
                // else if (obj.active !== main_window && default_fk === undefined){
                //     console.log('Should change tab. Object is ', obj, form_state, default_fk, show_modal)
                //     obj.setActive(main_window)
                // }
            } else {
                // console.log('Should close modal. Object is ', obj, form_state, default_fk, show_modal)
                show_modal !== false && updateDisplayState({show_modal: false})
            }
        }
        if (this.props.form_state === 'details' && this.active === (subTableBelow(this.props.form) ? 0 : -1) && this.props.num_results > 1) {
            if (e.code === 'ArrowLeft') {
                this.props.setIndex(this.props.record_index - 1, Object.keys(this.state).length)
                // up arrow
            } else if (e.code === 'ArrowRight') {
                this.props.setIndex(this.props.record_index + 1, Object.keys(this.state).length)
                // down arrow
            }
        }
    }

    onPressEnter = e => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    form_header = () => {
        let is_a_report = this.is_a_report(),
            {form_state, hideModal, form, page_name, form_header = form.tab_name || page_name, dispatch, num_results, search_params, record_index} = this.props,
            {readonly = false} = form,
            // {closeModal} = this,
            new_header = form_header && ((form_state === 'insert' && `Create New ${form_header}`) || (form_state === 'update' && `Update ${form_header}`) || (form_state === 'search' && `Search ${form_header}`)),
            header = (form.tab_name && new_header) || form_header || (is_a_report ? 'Provide report options' : (form_state === 'insert' && 'Create New Record') || (form_state === 'update' && 'Update Record') || (form_state === 'search' && 'Search Record') || (form_state === 'details' && 'View Record')),

            stateEqualToRecordValues = _ =>{
                let state = this.state || {},
                    record = record_values || {}
                return Object.keys(state).every(key => (state)[key]  === record[key])
                // return !Boolean(Object.keys(this.state).length)
            },
            check_if_state = () => {
                if (!stateEqualToRecordValues()) {
                    this.props.showAlert(true, 'Submit changes first in order to proceed', 'warning')
                    console.log('State contains', {state: this.state})
                    return false
                } else {
                    return true
                }
            },
            nextRecord = (e) => {
                e.preventDefault()
                check_if_state() && this.props.setIndex(this.props.record_index + 1)
            },
            previousRecord = (e) => {
                e.preventDefault()
                check_if_state() && this.props.setIndex(this.props.record_index - 1)
            },
            minimize = () => {
                if (Object.keys(this.body_style).length) {
                    this.body_style = {}
                } else {
                    this.body_style = {display: 'none'}
                }
                this.forceUpdate()
            },
            {record_values, primary_key_columns} = this.props,
            record_detail = record_values && primary_key_columns && (() => {
                if (form.title) {
                    return form.title.map(col => ({...record_values, ...this.variables}[col])).filter(a => a).join(' | ')
                }
                let {primary_key_columns} = this.props
                return record_values.description || primary_key_columns.map(pk => record_values[pk])
            })()
        if (this.props.match.url.replace('/page/', '') !== form.name && form_state === 'details') {
            header = <NavLink to={`/page/${form.name}`} title={`View full list of ${header}`}>{header}</NavLink>
        }
        return <>
            {/*!(oneItemAsForm && num_results === 1)  && */}
            <div className="col-xs-24 col-md-2 col-sm-6 pull-right text-right">
                <button type="button" className="btn btn-box-tool" onClick={minimize}><i className='fa fa-minus'/></button>
                <button type="button" className="btn btn-box-tool" onClick={_ => dispatch(maximize())}><i className='fa fa-window-maximize'/></button>
                <button type="button" className="btn btn-box-tool" onClick={hideModal}><i className='fa fa-times'/></button>
            </div>
            <div className="col-xs-24 col-md-2 col-sm-6 pull-left text-left" style={{lineHeight: 'initial'}}>
                <ul className="pagination pagination-sm modal-buttons" style={{margin: 0}}>
                    {(!is_a_report && ['details', 'update'].includes(form_state) && num_results > 1 && stateEqualToRecordValues()) && [
                        <li key={1}>
                            <button href="/#" onClick={previousRecord}>
                                <i className='fa fa-caret-left'/>
                            </button>
                        </li>,
                        <li key={2}>
                            <button href="/#" onClick={nextRecord}>
                                <i className='fa fa-caret-right'/>
                            </button>
                        </li>]}
                    {!(form_state === 'create') && [
                        !readonly && form_state === 'details' && [
                            buttons_top && <li key={3}>
                                <button href="/#" onClick={this.edit_record} title='Edit record'>
                                    <i className='fa fa-edit text-blue'/>
                                </button>
                            </li>,
                            <li key={4}>
                                <button href="/#" onClick={this.new_record} title='Add new record'>
                                    <i className='fa fa-plus text-green'/>
                                </button>
                            </li>],
                        form_state === 'details' && [
                            num_results >= 1 && <li key={5}>
                                <button href="/#" onClick={this.search_record} title='Search records'>
                                    <i className='fa fa-search text-yellow'/>
                                </button>
                            </li>,
                            (search_params || num_results == 1) && <li key={6}>
                                <button href="/#" onClick={this.clear_search} title='Clear Search'>
                                    <i className='fa fa-sync text-purple'/>
                                </button>
                            </li>],
                        !readonly && form_state === 'details' && [
                            buttons_top && (record_index !== undefined || true) && this.props.handleDelete && <li key={9}>
                                <button href="/#" onClick={e => this.delete_record(e, record_index)} title='Delete record'>
                                    <i className='fa fa-trash text-red'/>
                                </button>
                            </li>,
                            /*<li key={6}>
                                <button href="/#" onClick={this.refresh_record}>
                                    <i className='fa fa-sync text-blue'/>
                                </button>
                            </li>*/
                        ],
                        form_state !== 'details' && [
                            <li key={7}>
                                <button href="/#" onClick={this.handleSubmit}>
                                    <i className='fa fa-check text-green'/>
                                </button>
                            </li>,
                            <li key={8}>
                                <button href="/#" onClick={this.handleCancel}>
                                    <i className='fa fa-times text-red'/>
                                </button>
                            </li>
                        ]]}
                </ul>
            </div>

            <div className={!is_a_report ? 'col-xs-24 col-md-8 col-sm-12 hidden-sm hidden-xs' : 'hidden-sm hidden-xs col-xs-6'}>
                <h3 style={{textAlign: 'center', margin: 0}}>{header} <span className="hidden-sm hidden-xs">{record_detail && `(${record_detail})`}</span></h3>
            </div>
            {/*{form_state === 'details' &&*/}
            {/*<div className='col-xs-2'>*/}
            {/*    <this.edit_button style={{marginTop: '20px'}} a_class='btn-sm'/>*/}
            {/*</div>*/}
            {/*}*/}
        </>
    }

    modal_body = ({children}) => {
        let {show_header = true, form_state} = this.props/*,
            style = height ? {minHeight: height + 50} : {}*/
        return (
            <div className={`box ${show_header ? 'box-primary' : ''} ${['insert', 'update', 'search'].includes(form_state) && 'form-box'} form-box`} /*style={style}*/>
                <div className="box-header with-border">
                    {show_header && this.form_header()}
                    {/*<h3 className="box-title"> Employee Details</h3>
                        <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"/>
                            </button>
                            <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"/></button>
                        </div>*/}
                </div>
                <div className="box-body">
                    {/*<canvas id="areaChart" style={{height:' 250px', width: '789px'}} height="250" width="789"/>*/}
                    {children}
                </div>
            </div>
        )
    }

    changeTab = (e, tab_name) => {
        e.preventDefault()
        this.active = tab_name
        this.forceUpdate()
    }

    active = subTableBelow(this.props.form) ? 0 : -1

    FormDiv = ({children, ...params}) => {
        let {default_fk={}} = this.props,
                suffix = Object.values(default_fk).join('')
        return (
        <form id={`modalForm${suffix}`} method="POST" className="col-md-12 blue-back" {...params} encType="multipart/form-data">
            {children}
        </form>
    )}

    render() {
        return <this.form/>
    }

    edit_button = ({a_class, style}) => {
        return (
            <a href='/#' type="submit" className={`btn btn-success ${a_class}`} style={{width: '50%', ...style}} onClick={this.edit_record}>
                <i className="fa fa-edit"/>Edit Record
            </a>
        )
    }

    delete_button = ({a_class, style}) => {
        return (
            <a href='/#' type="submit" className={`btn btn-danger ${a_class}`} style={{width: '50%', ...style}} onClick={this.delete_record}>
                <i className="fa fa-edit"/>Delete Record
            </a>
        )
    }

    validate = () => {
        let {record_values, form} = this.props, {
            validation = function () {
                return {}
            }
        } = this.props.form
        let
            record = {...record_values, ...this.state},
            errors = validation.bind(this)(record)
        form.lines.forEach(line => {
            line.forEach(element => {
                let id = element_id(element.id)
                if ([undefined, true].includes(element.showInModal) && element.required && (record === null || [null, undefined].includes(record[id]))) {
                    errors[id] = `${element.name} is required`
                }
            })
        })
        return errors
    }

    handleSubmit = (e, callback) => {
        e && e.preventDefault()
        let {form_state, record_values, form} = this.props,
            is_a_report = this.is_a_report()
        if (form_state !== 'search') {
            this.errors = this.validate()
            if (Object.keys(this.errors).length > 0) {
                this.props.showAlert(true, Object.values(this.errors).join('. '), 'error')
                this.forceUpdate()
                return
            }
        }

        if (is_a_report) {
            let {default_fk={}} = this.props,
                suffix = Object.values(default_fk).join(''),
                {updateDisplayState} = this.props
            document.getElementById(`modalForm${suffix}`).submit()
            updateDisplayState({search_params: escape_characters(this.state), show_modal: false, form_state: 'details', page: 1})
            // this.props.updateDisplayState({search_params: escape_characters(this.state)})
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            let {form} = this.props,
                {readonly = false} = form,
                {api_post_data, api_put_data} = this,
                {record_index, form_state, updateDisplayStateAndRefresh} = this.props,
                action = form_state || (record_index || record_index === 0 ? 'update' : 'create') // states create, update, search
            if (readonly && form_state === 'create') {
                return
            }
            if (Object.keys(this.state).length) {
                action === 'insert' && api_post_data()
                action === 'update' && api_put_data()
                action === 'search' && updateDisplayStateAndRefresh({search_params: escape_characters(this.state), show_modal: false, form_state: 'details', page: 1})
            } else {
                this.props.showAlert(true, 'No data has been entered in the form', 'warning')
            }
        }
    }

// handleReport = (e) => {
//     let
//         target = this.props.target,
//         url = `/reports/generate?target=${target}`,
//         data = {...this.state}
//         // {action:`/reports/generate?target=${target}`,
//         // onSubmit:()=>{window.open('about:blank','print_popup','width=1000,height=800').focus()},
//         // target:'print_popup', method:'POST'}
//
//     API.post(url, data)
//         .then(response => {
//             console.log('')
//         })
// }

    api_post_data = (successCallback = this.props.form.successCallback ? this.props.form.successCallback.bind(this) : this.api_success) => {
        // Todo Remove global token
        let table = this.props.form ? this.props.form.table : database_table(this.props.target),
            {token = global_token, form} = this.props,
            data = this.state,
            url = form.post_url || `/api/v1/${table}`

        API.defaults.headers.common['Authorization'] = `JWT ${token}`;
        this.cancelTokenSourceCancel && this.cancelTokenSourceCancel('CANCELLED');
        API.post(url, data, {
            cancelToken: new axios.CancelToken(c => {
                this.cancelTokenSourceCancel = c;
            })
        }).then(successCallback, this.api_error) //.catch(this.api_catch)
    }

    api_put_data = (successCallback = this.props.form.successCallback ? this.props.form.successCallback.bind(this) : this.api_success) => {
        let table = this.props.form ? this.props.form.table : database_table(this.props.target),
            data = this.state,
            {primary_key_columns, token = global_token, record_values = data, form} = this.props,
            {api_error} = this,
            url = form.post_url || build_url(primary_key_columns, record_values, table)

        if (url && table && data) {
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;
            this.cancelTokenSourceCancel && this.cancelTokenSourceCancel('CANCELLED');
            API.put(url, data, {
                cancelToken: new axios.CancelToken(c => {
                    this.cancelTokenSourceCancel = c;
                })
            }).then(successCallback, api_error) //.catch(api_catch)
        } else {
            console.log(table || "The table has not been defined")
            console.log(url || "Url has not been defined")
        }

    }

    api_success = response => {
        // this.props.updateDisplayState({modal_key: Math.random()})
        let {refreshTable, form_state} = this.props
        refreshTable && refreshTable()
        this.props.updateDisplayStateAndRefresh({form_state: 'details', show_modal: true})
        // this.props.hideModal()
        // this.state = {}
        this.props.showAlert(true, `Successfully ${form_state === 'update' ? 'updated' : 'created'}`, response.status)
        if (['insert', 'update'].includes(form_state))
            this.props.updateDisplayState({record_values: response.data})
        form_state === 'update' && Object.keys(this.state).length < 2 && this.props.updateDisplayState({show_modal: false})
    }

    api_error = error => {
        if (error.response && error.response.status === 401) {
            console.log('REDIRECTING TO LOGIN')
            this.props.dispatch(signOut())
            configs.redirect_to_login && this.props.history.push('/?next=' + this.props.match.url)
        } else {
            console.log('Inner error handler')
            let message = server_error(error)
            message && this.props.showAlert(true, message)
        }
    }

    api_catch = error => {
        console.log('Outer error handler')
        console.log(JSON.stringify(error))
        this.props.showAlert(true, (error.response && error.response.data) || error)
    }

    handleCancel = (e) => {
        let {record_values, hideModal} = this.props
        e.preventDefault()
        // this.props.hideModal()
        record_values ? this.props.updateDisplayState({form_state: 'details'}) : hideModal()
    }

    edit_record = e => {
        e.preventDefault()
        this.props.updateDisplayState({form_state: 'update', show_modal: true})
    }

    new_record = e => {
        e.preventDefault()
        this.props.updateDisplayState({form_state: 'insert', record_values: null, record_index: null, show_modal: true, modal_key: Math.random()})
    }

    search_record = e => {
        e.preventDefault()
        this.props.updateDisplayState({form_state: 'search', record_values: null, record_index: null, show_modal: true})
    }

    clear_search = e => {
        e.preventDefault()
        // this.props.updateDisplayStateAndRefresh({search_params: null, show_modal: false, record_values: undefined, record_index: undefined})
        this.props.clear_search()
    }

    delete_record = (e, record_index) => {
        this.props.handleDelete(e, record_index)
    }

    refresh_record = e => {
        e.preventDefault()
        // this.props.updateDisplayState({form_state: 'search', record_values:null})
    }
}


export const
    mapStateToProps = (state, props) => {
        let {target, form = getForm(target)} = props
        return {
            form,
            ...state.authReducer,
            ...state.pageReducer,
            ...props,
            max_modal: state.pageReducer.max_modal
        }
    },
    mapDispatchToProps = null
/* (dispatch) => {
 return {
     signOut: () => dispatch(signOut()),
     maximize: () => dispatch(maximize())
 }
}*/

export default withRouter(connect(mapStateToProps, null)(FormSuper))
