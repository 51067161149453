import React from "react";
import {ReportIframe} from "../../../../components/page_route/report_iframe";
import department_budget from "./department_budget";
import vote_heads from "./vote_heads";

let BudgetSummary = function (props) {
    let
        {form_values = {}, primary_key_columns, table, showAlert} = props,
        params = {style: {height: window.innerHeight - 150}, src: `/api/v1/${table}/${form_values[primary_key_columns[0]]}/budget_summary`, showAlert, signOut: _ => null}
    return <ReportIframe {...params}/>
}


export default {
    type: 'table',
    name: 'budget',
    table: 'budget',
    tab_name: 'Budget',
    fa: 'shopping-bag',
    title: ['dept_budget_id'],
    lines: [
        [
            {name: 'Description', id: 'description', type: 'text', span: 1,required:true},
            {name: 'Year', id: 'year', type: 'select', source: 'financial_year.year', span: 1,required:true},
        ],[
            {...department_budget},
            {...vote_heads}
        ], [
            {type: 'tab', tab_name: 'Budget Summary', fa: 'file-invoice', component: BudgetSummary}
        ],
    ]
}