import React from 'react'
import SelectField from "../../../../../components/page_route/form/formelements/select";
import TextField from "../../../../../components/page_route/form/formelements/textfield";
import {current_date} from "../../../../../components/utilities";

export default function (props) {
    let
        {state, setLocalState} = props,
        search_params = {},
        setState = (obj) => {
            let others = {...state}
            Object.keys(obj).forEach(key => {
                others[key] = obj[key]
            })
            setLocalState(others)
        },
        handleSelect = (object) => {
            setState(object)
        },
        handleText = (e, object) => {
            if (e) {
                e.preventDefault && e.preventDefault()
                setState({[e.target.id]: e.target.value})
            } else {
                setState(object)
            }
        },
        showAlert = _ => {
        },
        onPressEnter = _ => {
        },
        setVariables = _ => {
        },
        select_params = {
            showAlert, fullId: 'department',
            form_values: {...state}, form_state: 'insert', setState,
            onPressEnter, primary_key_columns: [], setVariables, errors: {}, show_button: false
        },
        handlePrint = (e) => {
            e && e.preventDefault()
            try {
                window.frames['trial_balance_report'].focus()
                window.frames['trial_balance_report'].print()
            } catch (e) {
                try {
                    window.print()
                } catch (e) {
                    console.log('Error on printing', e, Object.keys(e))
                    this.props.showAlert(true, e.toString())
                }
            }
            // eslint-disable-next-line no-unused-expressions
            // window.frames[report_target].preview
        }

    return (
        <div className='row'>
            <div className="col-md-2">
                <SelectField {...select_params} {...{name: 'Financial Year', id: 'year', source: 'financial_year.description', value: state.year || (search_params || {})['year'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-1">
                <SelectField {...select_params} {...{name: 'Long/Short', id: 'type', options: [{value:'long', name:'Long'}, {value: 'short', name: 'Short'}],value: state.type || (search_params || {})['type'], handleChange: handleSelect}} />
            </div>
            <div className="col-md-6">
                <h3 style={{textAlign: 'center', fontWeight: 1000}}>{state.report_title}</h3>
            </div>
            <div className="col-md-1">
                <TextField {...select_params} {...{name: 'To Date', id: 'to_date', type: 'date', defaultValue: current_date(), value: state.to_date || (search_params || {})['to_date'] || current_date(), handleChange: handleText}} />
            </div>
            <div className="col-md-1">
                <SelectField {...select_params} {...{name: 'Hide Zeros', id: 'hide_zero', options: [{value:true, name:'Yes'}, {value: false, name: 'No'}],value: state.hide_zero || (search_params || {})['hide_zero'], handleChange: handleSelect}} />
            </div>

            <div className="col-md-1 pull-right" style={{minWidth: 100}}>
                <button className="btn btn-primary btn-warning" title="Print Report" onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
            </div>
        </div>
    )
}