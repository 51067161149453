import {current_date} from "../../../components/utilities";

let options = [
    {value: 'jogoo', name: 'Jogoo'},
    {value: 'buru', name: 'Buru'},
    {value: 'quiver', name: 'Quiver'},
]
export default {
    type: 'table',
    name: 'store_movements',
    tab_name: 'Store Movements',
    table: 'store_movements',
    title: ['date', 'item', 'quantity'],
    lines: [
        [
            {name: 'Item', id: 'item_id', type: 'select', source: 'items.description', required: true, showInTable: true, show_pk_in_list: false},
            {name: 'Quantity', id: 'quantity', type: 'number', required: true, defaultValue: 1},
            {name: 'Date', id: 'date', type: 'date', required: true, defaultValue: current_date()},
        ], [
            {name: 'Moved From Branch', id: 'store_id', type: 'select', source: 'stores.description', required: true, span:1, defaultValue: 1},
            {name: 'Moved To', id: 'store_to', type: 'select', options, required: true, span: 1, defaultValue: 'jogoo'},
            {name: 'Investor', id: 'investor_id', type: 'select', source: 'investors.name', span: 1, show_pk_in_list: false, required: true, defaultValue: 11},
            {name: 'Notes', id: 'notes', type: 'textarea', span: 1},
        ]
    ]
}