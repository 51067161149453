import React, {memo} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Select, {createFilter} from 'react-select'
import Creatable from "react-select/creatable";
import {SelectSuper, mapDispatchToProps, mapStateToProps} from "./selectsuper";
import {getFormByTable, table_name} from "../../table/utilities";
import element_style from "./element_style";
import {star_nonexistent_option} from "../../../../custom/configs";
import {defined} from "./utilities";

class SelectField extends SelectSuper {
    showValue = false

    InputField = ({options: passed_options}) => {
        const {id, handleChange, required, form_state, creatable = form_state === 'search', isClearable = true, disabled, hideInput = false, value} = this.props,
            onChange = selected => {
                handleChange({[id]: selected ? selected.value : ''})
            },
            height = {height: '30px !important', minHeight: '30px !important'},
            required_style = (required && (value === undefined || value === null || value === '')) ? {borderColor: 'red'} : {},
            customStyles = {
                control: styles => ({...styles, ...height, borderRadius: '3px', ...required_style}),
                // option: (styles, {data, isDisabled, isFocused, isSelected}) => ({...styles, }),
                input: styles => ({...styles}),
                // placeholder: styles => ({...styles, }),
                singleValue: (styles, {data}) => ({...styles, top: '45%'}),
                // singleValue: (styles, { data }) => ({...styles, top:'35%'}),
                indicatorsContainer: styles => ({...styles, ...height}),
            },
            SelectComponent = (creatable || this.props.is_a_report) ? Creatable : Select
        let options = passed_options
        if (value !== undefined && value !== null && !(value instanceof Array) && options && !options.map(item => item.value).includes(value)) {
            options.push({value, label: star_nonexistent_option ? `*${value}*` : value})
        }
        return id ? (
            <>
                <SelectComponent
                    value={options && options.filter(item => item.value === value)}
                    onChange={onChange}
                    options={options}
                    searchable
                    styles={customStyles}
                    name={hideInput ? undefined : id}
                    isClearable={isClearable}
                    isDisabled={disabled}
                    isRequired={required}
                    // filterOption={createFilter({ignoreAccents: false})}
                />
            </>
        ) : <div>Loading option List</div>

    }


    render() {
        let {id, form_state, source, creatable, parentSource, is_a_report} = this.props,
            value = this.props.value || this.state.value,
            {options} = this.state,
            fieldValue = (options && value !== undefined) && options.find(item => item.value === value),
            show_add,
            form = getFormByTable(table_name(parentSource || source))

        if (form_state === 'details') {
            show_add = false
        } else {
            show_add = source;
        }
        return id ? (
            <>
                {form.name && <this.Modal/>}
                {(form_state === 'details' || this.props.disabled === true) ? (
                    <div className='form-control' style={{height: 'auto'}}>
                        {(fieldValue !== undefined && fieldValue.label && defined(fieldValue.value)) ?
                            (<span>{(source && form.name && table_name(source) !== table_name(parentSource) && fieldValue) ? this.detailsButton(fieldValue) : fieldValue.label}</span>) :
                            <span>{value || '...'}</span>
                        }
                    </div>
                ) : (
                    <div className="input-group" style={{width: '100%'}}>
                        <this.InputField options={options} value={value}/>
                        {!is_a_report && show_add && form.name && <div className="input-group-addon">{this.createButton}</div>}
                    </div>
                )}
            </>
        ) : <div>Loading option List</div>

    }


}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(element_style(SelectField)))