import React from 'react'
import {ReportIframe} from "../../../components/page_route/report_iframe";
import Filter from './filter'

let Mapp = function () {
    let
        [state, setLocalState] = React.useState({}),
        updateDisplayState = function (obj) {
            let new_state = {...state},
                {report_title} = obj
            if (report_title) {
                setLocalState({...new_state, report_title})
            }
        },
        params = {style: {height: window.innerHeight - 120}, src: state.id ? `/api/v1/mind/${state.id}/mind_map` : `/mind_map/map`, showAlert: _ => null, name: 'mind_map', signOut: _ => null, updateDisplayState}

    return <>
        <div className={`box box-primary form-box`}>
            <div className="box-header with-border">
                <div className="box-body">
                    <Filter {...{state, setLocalState}}/>
                </div>
                <div className="box-body">
                    <ReportIframe key={`${state.id}${state.refresh}`} {...params}/>
                </div>
            </div>
        </div>
    </>
}


export default {
    name: 'mapp',
    customComponent: Mapp,
    hideContentHeader: true,
    BeforeTable: function (props) {

    }
}