import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {element_id, string_processor, get_record_values} from './utilities'
import {Pagination} from "./pagination";
import MultiSelectField from "../form/formelements/multiselect";
import SelectField from "../form/formelements/select";
import {withRouter} from 'react-router-dom'
import {RowValue} from "../form/formelements/row_value";
import {CalculatedRow} from "../form/formelements/calculated";
import sort_asc from "./sort_asc.png"
import sort_desc from "./sort_desc.png"
import sort_both from "./sort_both.png"
import DataDisplaySuper, {mapStateToProps, mapDispatchToProps} from "./DataDisplaySuper";
import File from "../form/formelements/file";
import configs, {oneItemAsForm, show_label_pagination} from "../../../custom/configs";
import {comma_currency} from "../../utilities";
import foreign_keys from "../../../custom/forms/foreign_keys";
import form_row_component from "./form_row_component";
import {build_default_fk} from "../form/utilities";

const ExportExcluded = ['file', 'table', 'calculated'],
    report_target = configs.report_target

export const english_date = (value) => {
    let dates = value.split('-')
    if (dates[0] !== undefined && dates[1] !== undefined && dates[2] !== undefined) {
        value = `${dates[2]}/${dates[1]}/${dates[0]}`
    }
    return value
}

export class DataDisplayTable extends DataDisplaySuper {

    showReferences = false

    handleAddColumn = (id, condition) => {
        let {headers, headers_all = headers} = this.props,
            search_column = headers_all.find(item => item.id === id)
        if (search_column) {
            search_column.checked = condition !== undefined ? condition : !headers_all.find(item => item.id === id).checked
            this.forceUpdate()
        }
    }

    processHeaders = (header) => {
        let headers = header,
            {default_fk = {}} = this.props,
            parent_keys = Object.keys(default_fk)
        // headers = this.showReferences ? headers : headers.filter(item => !(['calculated', 'reference'].includes(item.type && item.type.trim()) || (item.disabled && item.disabled)))
        // headers = headers.filter(item => !(['calculated'].includes(item.type && item.type.trim()) || (item.disabled && item.disabled)))
        // headers = this.showReferences ? headers : (() => {
        //     let ids_referenced = headers.filter(item => item.reference).map(item => element_id(item.reference))
        //     return headers.filter(item=>!(ids_referenced.includes(element_id(item.id))))
        // })()
        if (parent_keys.length > 0) {
            headers = headers.filter(item => !([undefined, false].includes(item.showInTable) && parent_keys.includes(element_id(item.id)))).filter(item => !([undefined, false].includes(item.showInTable) && parent_keys.includes(item.reference)))
            headers = headers.filter(item => !(item.type === 'reference' && item.reference === undefined && this.state.table_data.primary_keys.some(key => parent_keys.includes(key))))
        }

        headers = headers.filter(item => item.checked)
        return headers
    }

    form_headers = () => {
        let {rowComponent} = this.props.form
        if (this.props.headers_all && rowComponent === undefined) {
            let headers = this.processHeaders(this.props.headers_all),
                sort_by = (e, column) => {
                    e.preventDefault()
                    this.setState(
                        _ => ({order_by: element_id(column.reference || column.id), order_by_direction: Boolean(!this.state.order_by_direction)}),
                        () => {
                            this.api_get_data({callback: () => column.reference ? this.handleAddColumn(column.reference || column.id, true) : null})
                        },
                    )
                },
                header_name = (column) => {
                    return column.name || string_processor(element_id(column.id))
                },
                process_name = (column) => {
                    let {order_by, order_by_direction} = this.state,
                        is_sorted = order_by === element_id(column.reference || column.id)
                    return <a href={`/${column.reference || column.id}`} onClick={(e) => sort_by(e, column)} style={{display: 'block'}}>
                        <img className='pull-right' src={is_sorted ? order_by_direction ? sort_asc : sort_desc : sort_both} alt=""/>{header_name(column)}
                    </a>
                }
            return headers ? (
                <tr>
                    <th className="text-right text-sm">#</th>
                    {/*{headers.map((column, index) => <th key={index}>{column.reference ? header_name(column): process_name(column.id, header_name(column))}</th>)}*/}
                    {headers.map((column, index) => {
                        let {attributes: {style: attribute_style = {}} = {}} = column
                        return <th key={index} className='text-sm' style={{textAlign: column.type === 'currency' ? 'right' : 'inherit', ...attribute_style}}>{column.type === 'calculated' ? header_name(column) : process_name(column)}</th>
                    })}
                    {configs.show_actions_column && <th>Actions</th>}
                </tr>) : null;
        } else {
            return null
        }
    }

    table_row = (props) => {
        let
            {headers, record, index, record_number, style = {}} = props,
            {table_data} = this.state,
            {showAlert, form} = this.props,
            [contextStyles, setContextStyles] = useState({}),
            [show_context, setShowContext] = useState(false),
            onContextMenu = e => {
                // e.preventDefault()
                this.setState({active_context: index})
                setShowContext(true)
                setContextStyles({left: e.pageX, top: e.pageY - 100, zIndex: 10000})
            },
            onClickRow = e => {
                this.setState({active_context: null})
            },
            actions = this.generate_actions(index),
            styles = {minWidth: '100px'}
        return (
            <tr onDoubleClick={(e) => this.handleDetails(e, index)} onContextMenu={onContextMenu} onClick={onClickRow}>
                <td style={style}>
                    {record_number}
                    {ReactDOM.createPortal(
                        <div className='dropdown-menu' style={{display: (show_context && index === this.state.active_context) ? 'block' : 'none', position: 'absolute', ...contextStyles}}>
                            {actions}
                        </div>
                        ,
                        document.body
                    )}
                </td>
                {headers.map((column, index) => {
                        let value = record[element_id(column.id)],
                            create_params = (new_column) => {
                                let params = {
                                    ...new_column, fullId: new_column.parentSource || new_column.id, id: element_id(new_column.id), token: this.props.token, reference: new_column.reference ? element_id(new_column.reference) : 'payroll_no', form_values: record, showAlert, primary_key_columns: table_data.primary_keys, table: form.table, options: column.options
                                }
                                return params
                            },
                            process_column = (new_column, index) => {
                                return <RowValue key={`${index}${this.state.page}${JSON.stringify(value)}`} {...create_params(new_column)}/>
                            },
                            process_calculated = (column, index) => {
                                return <CalculatedRow key={Math.random()} {...record} {...column} form_values={record} value={value}/>
                            }
                        // if (!value && !(element_id(column.id) in record)) {
                        if (column.type === 'file') {
                            let params = {...column, fullId: column.id, id: element_id(column.id), value, form_values: record, show_label: false, primary_key_columns: table_data.primary_keys, table: form.table}
                            value = <div className='table_file'><File {...params} /></div>
                        } else if (column.type === 'reference' || column.reference !== undefined) {
                            value = process_column({...column, value}, index)
                        } else if (column.type === 'currency') {
                            let {preprocessor} = column
                            if (preprocessor)
                                value = process_calculated(column, index)
                            else
                                value = comma_currency(value)
                        } else if (column.type === 'calculated') {
                            value = process_calculated(column, index)
                        } else if (column.type && column.type === 'date' && value && value.length === 10) {
                            value = english_date(value)
                        } else if (column.type === 'textarea') {
                            value = <pre className='no_style'>{value}</pre>
                        } else if (column.type === 'multiselect') {
                            let params = {...column, parentSource: column.parentSource || column.source, id: element_id(column.id), value, form_values: record, show_label: false, showAlert, form_state: 'details', handleChange: (obj) => null}
                            value = <MultiSelectField {...params}/>
                        } else if (column.type === 'select' || column.source !== undefined || column.options !== undefined) {
                            value = process_column(foreign_keys.create_reference(column), index)
                            // let params = {...column, fullId: column.parentSource || column.id, id: element_id(column.id), value, form_values: record, show_label: false, showAlert, form_state: 'details', handleChange: (obj) => null}
                            // value = <SelectField {...params}/>
                        } else if (column.type === 'custom' && column.showInTable && column.component) {
                            let {component: Component} = column
                            Component = Component.bind(this)
                            value = <Component record={record} form_values={record}/>
                        }
                        let {attributes: {style: attribute_style = {}} = {}} = column
                        return <td key={index} style={{textAlign: column.type === 'currency' ? 'right' : 'inherit', ...style, ...attribute_style || {}}}>{value}</td>
                    }
                )
                }
                {configs.show_actions_column && <td>
                    <div className="col-md-4 dropdown">
                        <a href='/#' onClick={() => false} className="btn btn-primary dropdown-toggle btn-xs" type="button" data-toggle="dropdown">Actions<span className="caret"/></a>
                        <ul className="dropdown-menu" style={styles}>
                            {actions}
                        </ul>
                    </div>
                </td>}
            </tr>
        )

    }

    form_row_component = form_row_component.bind(this)

    form_rows = () => {
        if (this.state.table_data && this.props.headers_all) {
            let
                {table_data} = this.state,
                {rowComponent: RowComponent = this.rows_as_form() ? this.form_row_component : this.table_row, totals} = this.props.form
            let headers = this.processHeaders(this.props.headers_all),
                results_per_page = this.props.entries || this.state.results_per_page,
                {primary_keys: primary_key_columns} = table_data || {},
                rows = table_data.objects.map((record, index) => {
                    let record_number = (results_per_page * (table_data.page - 1)) + index + 1,
                    {modal_key} = this.state,
                    key=JSON.stringify(build_default_fk({}, record || {}, primary_key_columns))
                    return (<RowComponent key={`${key}`} headers={headers} index={index} record={record} record_number={record_number}/>)
                })
            if (totals && rows.length) {
                // let calculated_totals = totals.map
                Object.keys(totals).forEach((total_key, index) => {
                    let total = totals[total_key],
                        obj = {},
                        key = `${99999999}${index}`
                    Object.keys(total).forEach(key => {
                        if (total[key] === 'sum') {
                            obj[key] = this.state.table_data.objects.map(record => record[key]).reduce((partial_sum, a) => partial_sum + a, 0)
                        } else {
                            obj[key] = total[key].bind(this)(this.state.table_data.objects)
                        }
                    })
                    rows.push(<RowComponent key={rows.length} headers={headers} index={key} record={obj} record_number={null} style={{fontWeight: 'bold'}}/>)
                })
            }
            return rows
        } else {
            return <div>Loading...</div>
        }
    }

    ChangeEntries = () => {
        let handleChange = (object) => {
                this.props.setEntries(object.entries)
            },
            value = this.props.entries || 20,
            nums = [10, 15, 20, 25, 30, 50, 100],
            numbers = (() => {
                if (nums.includes(value)) {
                    return nums
                } else {
                    return [...nums, value].sort((a, b) => a - b)
                }
            })(),
            options = numbers.map(item => ({value: item, name: `${item} entries`}))
        return <SelectField source={''} id={'entries'} value={value} options={options} handleChange={handleChange} creatable={true} isClearable={false} show_label={false}/>
    }

    HeadSection = () => {
        let {headers, headers_all = headers, export_action, default_fk, form} = this.props,
            {readonly = false} = form,
            handleCreate = (e) => {
                e.preventDefault()
                this.setState({
                    show_modal: true,
                    form_state: 'insert',
                    record_values: null,
                    record_index: null
                })
            },
            handleSearch = (e) => {
                e.preventDefault()
                this.setState({show_modal: true, form_state: 'search', record_index: undefined, record_values: undefined, reloadPage: this.refreshTable})
            },
            refreshPage = (e) => {
                e.preventDefault()
                this.setState({show_modal: false})
                this.refreshTable(this.state.total_pages)
                // this.forceUpdate()
            },
            handlePrint = (e) => {
                e && e.preventDefault()
                try {
                    window.frames[report_target].focus()
                    window.frames[report_target].print()
                } catch (e) {
                    try {
                        window.print()
                    } catch (e) {
                        console.log('Error on printing', e, Object.keys(e))
                        this.props.showAlert(true, e.toString())
                    }
                }
                // eslint-disable-next-line no-unused-expressions
                // window.frames[report_target].preview
            },
            handleImport = (e) => {
                e.preventDefault()
                // this.setState({show_modal: true, form_state: 'import', record_index: undefined, record_values: undefined, reloadPage: this.refreshTable})
                this.setState({show_import: true})
            },
            style = {width: '100%'},
            minWidth = {minWidth: 100}

        if (!this.is_a_report() && headers_all) {
            if (headers_all) {
                let
                    headers_all_filtered = headers_all.filter(item => !(['table'].includes(item.type) || item.table)),
                    column_list = headers_all_filtered.map((header, index) =>
                        <li key={index}>
                            <input type="checkbox" id={`header${header.id}`} name={`header${header.id}`} defaultChecked={header.checked} onChange={() => this.handleAddColumn(header.id)}/>
                            <label htmlFor={`header${header.id}`}>{header.name}</label>
                        </li>
                    ),
                    showPagination = (this.state.table_data && this.state.table_data.total_pages > 1),
                    {search_params = {}, table} = this.state,
                    export_params = () => {
                        let columns = headers_all_filtered.filter(item => !(ExportExcluded.includes(item.type) || item.table || ((item.type === 'reference' || item.reference) && item.disabled === undefined))).map(item => ({value: item.id, label: item.name}))
                        return {search_params: {...search_params, ...this.props.default_fk}, table, columns}
                    },
                    navigateTo = ({page, callback}) => {
                        this.setState({page})
                        this.api_get_data({page, callback})
                    },
                    {name, tab_name, label} = this.props.form,
                    pagination = !(this.state.data) && (
                        <>
                            {showPagination ?
                                <div className="col-xs-12 col-md-4">
                                    <div className="row text-center">
                                        <div className="col-md-12">
                                            <Pagination table_data={this.state.table_data} api_get_data={navigateTo}/>
                                        </div>
                                    </div>
                                </div> : show_label_pagination && <div className='col-xs-12 col-md-4 text-center'><u>{label || tab_name || name}</u></div>}
                            {
                                false && default_fk && (
                                    <div className="col-xs-4">
                                        <div className="row text-center">
                                            <div className="col-md-12">
                                                {JSON.stringify(default_fk)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )


                return (
                    <div className="">
                        <div className="col-xs-12 col-md-4">
                            <ul className="pagination pagination-sm modal-buttons" style={{margin: '0px'}}>
                                <li>
                                    <button className="" type="button" data-toggle="dropdown" title='show columns'><i className="fa fa-eye text-blue"/></button>
                                    <ul className="dropdown-menu">{column_list}</ul>
                                </li>
                                <li>
                                    {!readonly && <button href="/#" className="" title="Add New Record" onClick={handleCreate}><i className="fa fa-plus text-green"/></button>}
                                </li>
                                <li>
                                    <button href="/#" onClick={handleSearch} className="" title="Search for a Record"><i className="fa fa-search text-yellow"/></button>
                                </li>
                                {this.state.search_params ?
                                    <li>
                                        <button href="/#" onClick={this.clear_search} className="" title="Clear Search"><i className="fa fa-sync text-purple"/></button>
                                    </li> :
                                    <li>
                                        <button href="/#" onClick={refreshPage} className="" title="Refresh list"><i className="fa fa-sync text-purple"/></button>
                                    </li>
                                }
                            </ul>
                        </div>
                        {pagination}
                        {export_action &&
                        <ul className="pagination pagination-sm modal-buttons pull-right" style={{margin: '0px'}}>
                            <li>
                                <button href="/#" onClick={(e) => {
                                    e.preventDefault();
                                    export_action(export_params())
                                }} title="Export List"><i className="fa fa-download text-red"/>
                                </button>
                            </li>
                            <li>
                                <button href="/#" onClick={handleImport} className="" title="Import Data"><i className="fa fa-upload text-green"/></button>
                            </li>
                        </ul>
                        }
                        <div className="col-xs-12 col-sm-6 col-md-2 pull-right">
                            <this.ChangeEntries/>
                        </div>
                        {this.state.table_data && this.state.table_data.total_pages > 1 &&
                        <div className="col-xs-12 col-sm-6 col-md-1 pull-right" style={{textAlign: 'right', marginTop: '5px'}}>
                            <span className="pull-right badge bg-blue">
                            {this.state.table_data.num_results} Record(s)
                            </span>
                        </div>}
                    </div>
                )
            } else {
                return null
            }
        }
        let report_key = this.state.report_key,
            reset_report = e => {
                e.preventDefault()
                this.setState({report_key: report_key ? report_key + 1 : 1})
            }
        return (
            <div className="row">
                <div className="col-md-1 " style={minWidth}>
                    <button className="btn btn-primary btn-info" title="Generate Report" style={style} onClick={handleCreate}><span className="fa fa-file"/> Get Report</button>
                </div>
                <div className="col-md-1 pull-right" style={minWidth}>
                    <button className="btn btn-primary btn-danger" title="Print Report" style={style} onClick={e => e.preventDefault()}><span className="fa fa-file-o"/> Download</button>
                </div>
                <div className="col-md-1 pull-right" style={minWidth}>
                    <button className="btn btn-primary btn-warning" title="Print Report" style={style} onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
                </div>
                <div className="col-md-1 " style={minWidth}>
                    <button className="btn btn-primary btn-success" title="reset report" style={style} onClick={reset_report}><span className="fa fa-file-o"/> List</button>
                </div>
                {this.state.report_title && <div className="col-md-8" style={minWidth}>
                    <h3 style={{textAlign: 'center', margin: 0, fontWeight: 1000}}>{this.state.report_title}</h3>
                </div>}
            </div>
        )
    }

    generate_actions = (record_index) => {
        let actions = this.actions(),
            {actions: customActions = []} = this.props.form,
            create_action = (action, index) => {
                let onClick = (e) => action.action(e, record_index, this.state.table_data)
                return (
                    <li key={index}>
                        <div className="row">
                            <div className="btn-group-vertical col-xs-12">
                                <a href="/#" key={index} className={`btn btn-${action.color} btn-sm col-xs-24`} onClick={onClick} style={{width: '100%'}}>
                                    <i className={`fa fa-${action.fa}`}/> {action.name}
                                </a>
                            </div>
                        </div>
                    </li>
                )
            },
            actions_list = actions.map(create_action),
            custom_action_list = customActions.map(create_action)
        return [
            custom_action_list,
            actions_list
        ]
    }

    render() {
        let
            {form_headers: FormHeaders, form_rows: FormRows} = this,
            {rowComponent = this.rows_as_form()} = this.props.form,
            table_has_records = (this.state.table_data && this.state.table_data.objects.length),
            is_a_report = this.is_a_report()
        return (
            <this.DisplayBody>
                {!is_a_report && (
                    table_has_records ?
                        rowComponent ? (
                                <FormRows/>
                            ) :
                            <table id='section-to-print' className="table table-bordered table-striped table-condensed right_first_col">
                                <thead><FormHeaders/></thead>
                                <tbody><FormRows/></tbody>
                                {/*<tfoot><FormHeaders/></tfoot>*/}
                            </table>
                        :
                        <div className='text-center'>The table has no records to display</div>
                )}
            </this.DisplayBody>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataDisplayTable))
