import React from 'react'
import Notifications from './notifications'
import Logout from './logout'
const topnav = (props) => {
    return (
        <nav className="navbar navbar-static-top">
            <a href="/#" className="sidebar-toggle" data-toggle="offcanvas" role="button">
                <i className='fa fa-bars'/>
                <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-custom-menu">
                <ul className="nav navbar-nav">
                    <Notifications/>
                    {props.showSettings && <li>
                        <a href="/#" data-toggle="control-sidebar"><i className="fa fa-gears"/></a>
                    </li>}
                    <Logout/>
                </ul>
            </div>
        </nav>
    )
}

export default topnav