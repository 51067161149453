import React from 'react'
import {connect} from 'react-redux'
import {signOut} from '../../../store/actions/authactions'
import {withRouter} from 'react-router-dom'

const Logout = (props) => {
    const signOut = (e) => {
        e.preventDefault()
        props.signOut()
        // console.log('HISTORY ON LOGOUT', {props})
        props.history.push('/')
    }
    return (
        <li className="dropdown user user-menu">
            <a href="/#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-user-circle" style={{color: 'white', fontSize: '1.4em'}}/>
                <span className="hidden-xs">{props.first_name} {props.last_name}</span>
            </a>
            <ul className="dropdown-menu">
                <li className="user-header">
                    <i className="fa fa-user-circle" style={{color: "white", fontSize: "7em"}}/>
                    <p>{props.roles && props.roles.map((item, index) => index < props.roles.length-1 ? `${item}, `: item)}<small>Administration Department</small></p>
                </li>

                <li className="user-footer">

                    <div className="pull-left">
                        <a href="/#" className="btn btn-default btn-flat" onClick={signOut}>Sign out</a>
                    </div>
                </li>
            </ul>
        </li>
    )
};

const mapStateToProps = (state, props) => {
    return {
        ...props,
        ...state.authReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout))