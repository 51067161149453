import React from 'react'
import {ModalCreator} from "../../components/modal_creator";

export default function (_) {
    let
        {updateDisplayStateAndRefresh, setIndex, refreshTable, handleDelete, is_a_report} = this,
        {action, search_params, record_index, height: state_height, modal_key, table_data} = this.state,
        {target, page_name, showAlert, match} = this.props,
        setState = obj => this.setState(obj),
        params = {
            updateDisplayStateAndRefresh, setIndex, refreshTable, handleDelete, is_a_report,
            action, search_params, record_index, height: state_height, modal_key, table_data,
            target, page_name, showAlert, match, setState
        }
    return <ModalCreator {...params}/>
}
