import React, {PureComponent} from 'react'
import Modal from './form/modal'
import {export_list} from "./form/forms.global";
import {oneItemAsForm} from "../../custom/configs";
import DataDisplayTable from "./table/DataDisplayTable";

export default class Parent extends PureComponent {
    state = {
        modal_properties: null,
        table_properties: null,
        table_key: 1,
        modal_key: 1,
        show_alert: false,
        alertMessage: null,
        show_modal: false,
        showColumns: true,
        production: true,
        table_actions: []
    }
    scrollToTop = false

    updateDisplayState = obj => {
        this.setState(obj)
    }

    toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        })
    }

    showAlert = (bool, message = null, status = null) => {
        this.setState({
            show_alert: bool,
            alertMessage: message,
            alert_status: status
        })
    }

    export_action = (search_params) => {
        let {columns, ...others} = search_params
        this.setState({
            show_export: true,
            export_columns: columns,
            export_params: JSON.stringify(others)
        })
    }

    Exporter = () => {
        let {show_export, modal_properties, export_params} = this.state,
            {showAlert, updateDisplayState} = this
        return show_export ? (() => {
            let params = {
                ...modal_properties,
                showAlert,
                updateDisplayState: ({show_modal: show_export}) => {
                    updateDisplayState({show_export})
                },
                show_modal: show_export,
                form: export_list(export_params, this.state.export_columns),
                size: 'sm',
                hideModal: () => this.setState({show_export: false}),
                form_state: 'insert',
                form_header: 'Export the List',
                target: 'REPORT',
                default_fk: {output: 'WEB'}
            }
            return <Modal {...params}/>
        })() : <></>
    }

    TableSubComponent = (props) => {
        let
            {form, target} = props,
            {type = 'table', customComponent: CustomComponent} = form
        if (['table', 'report'].includes(type) && !Boolean(CustomComponent)) {
            let DataDisplay = DataDisplayTable
            return target ? (<DataDisplay {...props}/>) : <></>
        } else if (CustomComponent) {
            if (typeof CustomComponent === 'string') {
                return <DataDisplayTable {...props}/>
            }
            return target ? <CustomComponent {...props}/> : <></>
        }
    }
}

