import React, {useState} from 'react'
import {connect} from 'react-redux'
import {signOut} from '../../store/actions/authactions'
import loader from './loader.css'

export const
    ReportIframe = (props) => {
        let
            {style:passedStyle, src: passedSrc, showAlert, title = 'Report', name, updateDisplayState, show_modal} = props,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            {height, ...other_styles} = passedStyle,
            [style, setStyle] = useState(other_styles),
            [src, setSrc] = useState(passedSrc),
            // eslint-disable-next-line react-hooks/rules-of-hooks
            [show_iframe, showIframe] = useState(false),
            onLoadEvent = (e) => {
                // e.preventDefault()
                let contentDocument = e.target.contentDocument,
                    contentType = contentDocument && contentDocument.contentType,
                    textContent = contentDocument && contentDocument.documentElement.textContent
                // message = e.target.contentDocument.documentElement.textContent,
                // message2 = e.target.contentDocument.documentElement.innerText,
                console.log('Content Document', contentDocument)
                if (contentType === 'application/json' && textContent) {
                    showIframe(true)
                    setStyle({...style, display: 'none'})
                    let message3 = JSON.parse(textContent)
                    if (message3 && message3.description) {
                        showAlert(true, message3.description, message3.status_code)
                        if (message3.status_code === 401) {
                            props.signOut()
                        }
                        setSrc(passedSrc)
                    }
                } else if (textContent) {
                    setStyle({...style, display: 'block'})
                    showIframe(true)
                    updateDisplayState && updateDisplayState({show_modal: false, report_title: contentDocument.title})
                } else if (contentDocument === null) {
                    showIframe(true)
                    setStyle({...style, display: 'block'})
                    updateDisplayState && updateDisplayState({show_modal: false})
                }
                props.onLoadEvent && props.onLoadEvent(e)
            }
            React.useEffect(() => {}, [show_modal])
        height = height && height - 50
        let
            loader = (
                <div className='loader-parent' style={{height: style.height && style.height - 300, display: !show_modal ? 'none' : 'block', margin: 'auto', position:'absolute', width:'100%'}}>
                    <div className='loader' style={{marginTop: style.height && Math.floor(style.height / 4)}}/>
                    <div className='load-message text-blue'>Loading Report...</div>
                </div>
            ),
            Iframe = props => {
                React.useEffect(() => {}, [src, show_modal])
                return (
                    <iframe key={src} id='section-to-print' name={name} title={title} src={src} style={{display: 'block' , minHeight: height, height: height ? height + 35: undefined, ...other_styles}} width='100%' frameBorder="0" onLoad={onLoadEvent}/>
                )
            }
        return (
            <>
                {loader}
                {Iframe({})}
            </>
        )
    },
    mapStateToProps = (state, props) => {
        return {
            props
        }
    },
    mapDispatchToProps = (dispatch) => {
        return {
            signOut: () => dispatch(signOut()),
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(ReportIframe)