import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {mapStateToProps, mapDispatchToProps, TextFieldSuper} from "./textfieldsuper";
import {api_prefix} from "../../../../store/api";
import ReportIframe from "../../report_iframe";
import element_style from "./element_style";
import {after_table, build_url} from "../utilities";
import {defined} from "./utilities";

const
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })

export const get_file_type = (value) => {
        if (['/jpeg', '/jpg', '/png', '.jpeg', '.jpg', '.png'].some(i => value.includes(i))) {
            return 'image'
        } else if (['/pdf', '/html', '/png', '.pdf', '.html', '.png'].some(i => value.includes(i))) {
            return 'pdf'
        } else if (['/mp3', '/ogg', '/mpeg', '.mp3', '.ogg', '.mpeg'].some(i => value.includes(i))) {
            return 'audio'
        } else if (['/mp4', '/mkv', '/3gp', '.mp4', '.mkv', '.3gp'].some(i => value.includes(i))) {
            return 'video'
        } else {
            return 'doc'
        }
    },
    FileImage = ({value, filetype = value && get_file_type(value), attributes = null, download_name=value}) => {
        return value ? <div>
            {filetype === 'image' && <a href={value} target='_blank' rel="noopener noreferrer" download={download_name}><img className='img-responsive img thumbnail' src={value} alt="" style={{maxHeight: '250px', margin: '0 auto', ...attributes}}/></a>}
            {filetype === 'video' && <>
                <video className="col-md-12" controls>
                    <source src={value} type={`video/${value.split('.')[1]}`}/>
                    Your browser does not support the audio element.
                </video>
                <a target='_blank' rel="noopener noreferrer" href={value} download={value.split('/').pop()}>Download Video</a>
            </>}
            {filetype === 'pdf' && <>
                <ReportIframe className='img-responsive' title='Report' src={value} style={{height: 750, resize: 'both', overflow: 'auto', ...attributes, width: '100%'}} name='file-iframe'/>
                <a target='_blank' rel="noopener noreferrer" href={value} download={value.split('/').pop()}>Download PDF</a>
            </>}
            {filetype === 'audio' && <>
                <audio controls>
                    <source src={value} type={`audio/${value.split('.')[1]}`}/>
                    Your browser does not support the audio element.
                </audio>
                <a target='_blank' rel="noopener noreferrer" href={value} download={value.split('/').pop()}>Download Audio</a>
            </>}
            {/*{filetype === 'pdf' && <a href={value} target='_blank'><img className='img-responsive img thumbnail' src={value} alt="" style={{maxHeight: '250px', margin: '0 auto'}}/></a>}*/}
            {(filetype === 'doc') && <a target='_blank' rel="noopener noreferrer" href={value} download={value.split('/').pop()}>
                {
                    ['xls', 'xlsx', 'csv', 'ods'].some(i => value.includes(i)) ? (
                        <span className='text-center' style={{textAlign: 'center', color: 'green'}}>
                            <i className={`fad fa-file-excel fa-4x`}/>
                        </span>
                    ) : ['doc', 'docx', 'odt'].some(i => value.includes(i)) ? (
                        <span className='text-center' style={{textAlign: 'center', color: 'blue'}}>
                            <i className={`fad fa-file-word fa-4x`}/>
                        </span>
                    ) : ['pdf'].some(i => value.includes(i)) ? (
                        <span className='text-center' style={{textAlign: 'center', color: 'red'}}>
                            <i className={`fad fa-file-pdf fa-4x`}/>
                        </span>
                    ) : (
                        <span className='text-center' style={{textAlign: 'center', color: 'white'}}>
                            <i className={`fad fa-file fa-4x`}/>
                        </span>
                    )
                }
            </a>}
        </div> : <></>
    }

export class File extends TextFieldSuper {
    render() {
        let {id, value, handleChange, type, name, disabled, required, form_state, reference, attributes, table, form_values, primary_key_columns, filetype} = {...this.props, ...this.state},
            download_name = null,
            onChange_to_base64 = async (e) => {
                e.preventDefault()
                let new_e = {
                    preventDefault: () => null,
                    target: {
                        id,
                        value: await toBase64(e.target.files[0])
                    }
                }
                handleChange(new_e)
            },
            other_styles = (() => {
                return (required && !Boolean(value)) ? {borderColor: 'red'} : {}
            })()
        if (value && typeof (value) === 'object') {
            value = value ? value.name : ''
        }
        if (defined(value) && !value.startsWith('data:')) {
            value = `${api_prefix}static${value}`
        } else {
            if (
                (['details', undefined].includes(form_state) && primary_key_columns) ||
                (['update', undefined].includes(form_state) && !Object.keys(form_values).includes(id) && primary_key_columns)
            ) {
                console.log('running option 2')
                value = `${api_prefix}static/${table}/${id}${after_table(primary_key_columns, form_values, table)}`
                value = `${value}${value.includes('?') ? '&' : '?'}timestamp=${Date.now()}`
                download_name = primary_key_columns.map(key=>form_values[key]).join('_')
            }
        }
        return (
            <>
                {['search', 'update', 'insert'].includes(form_state) && reference === undefined && <input className="form-control" type={'file'} style={{...{margin: 0, padding: 0}, ...other_styles}} id={id} name={name} onChange={onChange_to_base64} value={type !== 'file' ? value || '' : undefined} disabled={disabled} required={required && (value === undefined || value === null || value === '')}/>}
                <FileImage value={value} attributes={attributes} filetype={filetype} primary_key_columns={primary_key_columns} form_values={form_values} download_name={download_name}/>
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(element_style(File)))
