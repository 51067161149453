export default {
    type: 'table',
    table: 'expenses',
    name: 'expenses',
    tab_name: 'Expenses',
    fa: 'money-check-edit-alt far',
    lines: [
        [
            {name: 'Project Name', id: 'project_id', type: 'select', source: 'projects.name'},
            {name: 'Particulars', id: 'particulars', type: 'text'},
        ], [
            {name: 'Date', id: 'date', type: 'date'},
            {name: 'Amount', id: 'amount', type: 'currency'},
        ], [
            {name: 'Remarks', id: 'remarks', type: 'text', span: 3}
        ], [
            {name: 'Receipt', id: 'receipt', type: 'file', span: 3}
        ]
    ],
    totals: [
        {
            particulars: function () {
                return 'Total'
            },
            amount: function (rows) {
                return rows.map(item=>item.amount).reduce((sum, item) => sum + item, 0)
            }
        }
    ]
}