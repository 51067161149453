import foreign_keys from "../../foreign_keys";
import {current_year} from "../../../../components/utilities";

export let financial_year_closing_balance = {
    type: 'table',
    name: 'financial_year_closing_balances',
    table: 'financial_year_closing_balances',
    tab_name: 'Financial Year Closing Balances',
    lines: [
        [
            {id: 'year', type: 'select', source: 'financial_year.description', span:1},
            {id: 'gl_code', type: 'select', source: 'account_gl.long_description', span:1},
            {name: 'Closing Balance', id: 'amount', type: 'currency'},

        ]
    ]
}

export default {
    type: 'table',
    name: 'financial_year',
    table: 'financial_year',
    tab_name: 'Financial Year',
    title: ['description'],
    lines: [
        [
            {id: 'year', type: 'select', ...foreign_keys.year(2015, current_year()), creatable: true},
            {id: 'description', type: 'text'}
        ], [
            {id: 'date_start', type: 'date'},
            {id: 'date_end', type: 'date'},
        ], [
        ], [
            {...financial_year_closing_balance, below: true}
        ]
    ]
}