export default {
    type: 'table',
    name: 'analytics',
    tab_name: 'Items',
    table: 'analytics',
    title: ['name'],
    lines: [
        [
            {id: 'id', type: 'number',},
            {id: 'name', type: 'text'}
        ]
    ]
}