export default {
    type: 'table',
    name: 'stores',
    tab_name: 'Stores',
    table: 'stores',
    title: ['description'],
    lines: [
        [
            {name: 'Name', id: 'description', type: 'text', required: true},
            {name: 'Location', id: 'location', type: 'text'},
        ], [
            {name: 'Contact Person', id: 'contact_person', type: 'text'},
            {name: 'Phone', id: 'phone_no', type: 'text'},
        ], [
            {name: 'Notes', id: 'notes', type: 'textarea', span: 3}
        ]
    ]
}