import React from 'react'
import {ReportIframe} from "../../components/page_route/report_iframe";
export default function (props) {
        let
            {form_values = {}, primary_key_columns, table, showAlert} = props,
            [state, setLocalState] = React.useState({}),
            updateDisplayState = function (obj) {
                let new_state = {...state},
                    {report_title} = obj
                if (report_title) {
                    setLocalState({...new_state, report_title})
                }
            },
            params = {style: {height: window.innerHeight - 150}, src: `/api/v1/${table}/${form_values[primary_key_columns[0]]}/balance_sheet`, showAlert, name: 'balance_sheet',  signOut: _ => null, updateDisplayState},
            handlePrint = (e) => {
                e && e.preventDefault()
                try {
                    window.frames['balance_sheet'].focus()
                    window.frames['balance_sheet'].print()
                } catch (e) {
                    try {
                        window.print()
                    } catch (e) {
                        console.log('Error on printing', e, Object.keys(e))
                        this.props.showAlert(true, e.toString())
                    }
                }
                // eslint-disable-next-line no-unused-expressions
                // window.frames[report_target].preview
            }
        return <>
            <div className={`box box-primary form-box`}>
                <div className="box-header with-border">
                    <div className="box-body">
                        <div className='row'>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <h3 style={{textAlign: 'center', fontWeight: 1000}}>{state.report_title}</h3>
                            </div>
                            <div className="col-md-1 pull-right" style={{minWidth: 100}}>
                                <button className="btn btn-primary btn-warning" title="Print Report" onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <ReportIframe {...params}/>
                    </div>
                </div>
            </div>
        </>
    }