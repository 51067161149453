import React from 'react'
import {ReportIframe} from "../../../../components/page_route/report_iframe";
import approval_actions from "./payment_request_actions";
import before_table from "../../before_table";
import payment_voucher from "./payment_voucher";


let purchase_req_form = function (props) {
        let
            {form_values = {}, primary_key_columns, table, showAlert} = props,
            params = {style: {height: window.innerHeight - 150}, src: `/api/v1/purchase_requisitions/${form_values['requisition_id']}/purchase_requisition_form`, showAlert, signOut: _ => null}
        return <ReportIframe {...params}/>
    },
    POrderForm = function (props) {
        let
            {form_values = {}, primary_key_columns, table, showAlert} = props,
            params = {style: {height: window.innerHeight - 150}, src: `/api/v1/purchase_orders/${form_values['po_no']}/purchase_order_form`, showAlert, signOut: _ => null}
        return <ReportIframe {...params}/>
    },
    GRNForm = function (props) {
        let
            {form_values = {}, primary_key_columns, table, showAlert} = props,
            params = {style: {height: window.innerHeight - 150}, src: `/api/v1/procurement_delivery_note/${form_values['delivery_note_id']}/grn_form`, showAlert, signOut: _ => null}
        return <ReportIframe {...params}/>
    },
    PVForm = function (props) {
        let
            {form_values = {}, primary_key_columns, table, showAlert} = props,
            params = {style: {height: window.innerHeight - 150}, src: `/api/v1/payment_voucher/${form_values['voucher_no']}/pv_form`, showAlert, signOut: _ => null}
        return <ReportIframe {...params}/>
    }

let fields = [
    {name: 'Purchase Order No', id: 'po_no', type: 'select', source: 'purchase_orders.po_no'},
    {name: 'Invoice', id: 'invoice_id', type: 'select', source: 'purchase_invoice.description', creatable: false},
]

export default (requestor) => ({
    type: 'table',
    name: `payment_request_${requestor}`,
    tab_name: 'Payment Request',
    table: 'payment_request',
    fa: 'address-book',
    readonly: requestor !== 'finance',
    lines: [
        [
            {name: 'Pay For', span: 1, id: 'code', type: 'select', source: 'payment_request_types.description', default_filter: requestor === 'admin' ? {code: '003'} : {code: ['001', '002','003']}, showInModal: true, showInTable: false},

            {
                type: 'custom', showInTable: false,span:1, component: function (props) {
                    let {form_state, form_values: {code}} = props
                    if (code === '003') {
                        return this.populateForm({
                            lines: [[
                                {name: 'Invoice', id: 'invoice_id', type: 'select', source: 'purchase_invoice.description', creatable: false},
                                {name: 'To be Paid To', id: 'paid_to', type: 'text',required:true,span:1},

                            ]]
                        }).populated_form
                    } else if(code === '001' || code === '002') {
                        return this.populateForm({
                            lines: [[
                                {name: 'Purchase Order No', id: 'po_no', type: 'select', source: 'purchase_orders.po_no'},

                            ]]
                        }).populated_form

                    }
                }
            }
        ],
        [
            {name: 'Pay For', id: 'code', type: 'select', source: 'payment_request_types.description', span: 1, showInModal: false},
            {name:'Invoice No.', id: 'invoice_id', type: 'select', source:'purchase_invoice.invoice_no',span:1,showInModal: false,showInTable: true},
            {name: 'Purchase order No', id: 'po_no', type: 'select', source: 'purchase_orders.po_no', span: 1, showInModal: false},
            {name: 'Request No', id: 'request_no', type: 'text', showInModal: false, showInTable: true},
            {name: 'Requisition No', id: 'requisition_id', type: 'select',source:'purchase_requisitions.description', showInModal: false, showInTable: true},
            {name: 'Quotation No', id: 'quotation_id', type: 'select',source:'purchase_quotations.quotation_no', showInModal: false, showInTable: true},
            {name: 'Delivery Note No', id: 'delivery_note_id', type: 'text', showInModal: false, showInTable: true},
            {name: 'Amount', id: 'invoice_amount', type: 'currency', showInModal: false, showInTable: true},
            {name: 'Status', id: 'status', type: 'text', showInModal: false, showInTable: true},
            // {name:'GRN No',id:'delivery_note_no',type:'text',showInModal:false},
            // {name: 'Invoice No', id: 'invoice_no', type: 'text', showInModal: false, showInTable: true},
        ],
        [
            {
                type: 'custom', showInTable: false, component: function (props) {
                    let {form_state, form_values: {code,requisition_id}} = props
                    console.log("CODE", code)
                    if ((code === '001') && requisition_id != null ) {
                        return this.populateForm({
                            lines: [[
                                {type: 'tab', tab_name: 'Requisition Form', fa: 'file-invoice', component: purchase_req_form},
                                {type: 'tab', tab_name: 'Purchase Order Form', fa: 'file-invoice', component: POrderForm},
                                {type: 'tab', tab_name: 'GRN Form', fa: 'file-invoice', component: GRNForm},
                                {type: 'tab', tab_name: 'PV Form', fa: 'file-invoice', component: PVForm}
                                ]]
                        }).populated_form
                    }else if(code==='002' && requisition_id!=null){
                        return this.populateForm({
                            lines: [[
                                {type: 'tab', tab_name: 'Requisition Form', fa: 'file-invoice', component: purchase_req_form},
                                {type: 'tab', tab_name: 'Purchase Order Form', fa: 'file-invoice', component: POrderForm},
                                {type: 'tab', tab_name: 'PV Form', fa: 'file-invoice', component: PVForm}
                                ]]
                        }).populated_form
                    }else if(code==='003'){
                        return this.populateForm({
                            lines: [[
                                {type: 'tab', tab_name: 'PV Form', fa: 'file-invoice', component: PVForm}
                                ]]
                        }).populated_form
                    }
                }
            },

        ], [
            {...payment_voucher, readonly: false}
        ], [
            {name: 'Actions', id: 'actions', type: 'custom', showInTable: true, component: approval_actions(requestor), showInModal: false, span: 3},
        ]
    ],
        beforeTable: before_table
})