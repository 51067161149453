import React from 'react'
import Modal from "./page_route/form/modal";
import ReactDOM from 'react-dom'

export let ModalCreator = (props) => {
    let
        {
            updateDisplayStateAndRefresh, setIndex, refreshTable, handleDelete, is_a_report,
            action, search_params, record_index, height: state_height, modal_key, table_data,
            target, page_name, showAlert, match, setState
        } = props,

        {show_modal, form, form_state, record = {}} = action || {},
        height = ((match.url.replace('/page/', '') && !is_a_report()) === target) ? state_height : null,
        params = {
            showAlert, show_modal, target, hideModal: () => setState({action: null}), updateDisplayStateAndRefresh: obj => {
                updateDisplayStateAndRefresh({obj, show_modal: false})
                setState({action: null})
            }, updateDisplayState: obj => {
                setState({action: null})
            }, setIndex, refreshTable, page_name, default_fk: {}, form_state: 'update', search_params, record_index, height, form, handleDelete, ...action
        }
    return (
        <>
            {show_modal && ReactDOM.createPortal(
                <Modal key={modal_key} {...params} record_values={record || {}} form_state={form_state} primary_key_columns={table_data.primary_keys}/>,
                document.body
            )
            }
        </>
    )
}