import React from 'react'
import {string_processor} from "../../table/utilities";
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {english_date} from "../../table/DataDisplayTable";
import {mapDispatchToProps, mapStateToProps, TextFieldSuper} from "./textfieldsuper";
import {comma_currency} from "../../../utilities";
import element_style, {Style} from "./element_style";
import {defined} from "./utilities";

export const
    process_date = date => {
        let
            dates
        if (date.includes('/')) {
            dates = date.split('/')
            if (dates.length === 3 && dates[2].length === 4) {
                date = `${dates[2]}-${dates[1]}-${dates[0]}`
            }
        }
        return date
    },
    process_date_query = e => {
        let val = e.target.value,
            full_val
        if (val.includes(' ')) {
            full_val = val.split(' ')
        } else {
            full_val = [val]
        }
        full_val = full_val.map(item => process_date(item))
        e.target.value = full_val.join(' ')
        return e
    }


class TextField extends TextFieldSuper {
    InputField = () => {
        let {id, type = "text", handleChange, onPressEnter, required, form_state, attributes = {}, reference} = {...this.props, ...this.state},
            {disabled = this.props.disabled !== undefined ? this.props.disabled : (type === 'reference' || reference)} = this.state || {},
            value = defined(this.props.value) ? this.props.value : (this.state && this.state.value),
            onChange = e => {
                if (type === 'date' && form_state === 'search') {
                    e = process_date_query(e)
                }
                handleChange(e)
            },
            new_type = form_state === 'search' ? 'text' : type,
            form_value = type !== 'file' ? defined(value) ? value: '' : undefined,
            style = (required && (value === undefined || value === null || value === '')) ? {borderColor: 'red'} : undefined,
            input_attributes = {
                ...attributes,
                type:new_type,
                id,
                name:id,
                onKeyPress:onPressEnter,
                onChange,
                value:form_value,
                disabled:form_state === 'search'? false: disabled,
                required,
                style:{...style, ...attributes.style}
            }
        return <input className="form-control" {...input_attributes} />

    }

    render() {
        let {id, type = "text", form_state} = {...this.props, ...this.state},
            value = (defined(this.props.value)) ? this.props.value : (this.state && this.state.value)

        if (form_state === 'details' && value) {
            if (type === 'date' && value.length === 10) {
                value = english_date(value)
            } else if (type === 'currency') {
                value = comma_currency(value)
            }
        }
        return (id && type) ? (
            <>
                {form_state !== 'details' && <this.InputField/>}
                {(form_state === 'details' && type !== 'hidden') && <div className='form-control' style={{textAlign: (type === 'currency') ? 'right' : 'left'}}><span>{(type !== 'password' && defined(value) && value.toString()) || '...'}</span></div>}
            </>
        ) : null
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(element_style(TextField)))
