import React from 'react'
import {mapDispatchToProps, mapStateToProps, SelectSuper} from './selectsuper'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Select from 'react-select'
import Creatable from "react-select/creatable";
import {getFormByTable, table_name} from "../../table/utilities";
import element_style from "./element_style";
import {star_nonexistent_option} from "../../../../custom/configs";


class MultiSelectField extends SelectSuper {
    InputField = ({options, value}) => {
        let {id, handleChange, disabled, all, required, form_state, creatable = form_state === 'search'} = this.props,
            SelectComponent = (creatable || this.props.is_a_report) ? Creatable : Select,
            // selected_values = options && options.filter(option_item => value.includes(option_item.value)).map(item=>item.value),
            selected_values = (() => {
                if (options && value !== undefined && value !== null) {
                    if (!(value instanceof Array)) {
                        value = [value]
                    }
                    return value.map(value_item => {
                        let matching_option = options.find(item => item.value === value_item)
                        if (matching_option) {
                            return {label: matching_option['label'], value: value_item}
                        } else {
                            return {label: `*${value_item}*`, value: value_item}
                        }
                    })
                } else if (!value && all) {
                    return options
                } else {
                    return []
                }
            })() || [],
            onChange = selected => {
                handleChange({[id]: selected ? selected.map(item => item.value) : null})
            },
            height = {minHeight: '30px !important'},
            customStyles = {
                control: styles => ({...styles, ...height}),
                singleValue: (styles, {data}) => ({...styles, top: '45%'}),
                indicatorsContainer: styles => ({...styles, ...height}),
                multiValue: styles => ({...styles, color: 'rgb(0, 184, 217)', backgroundColor: 'rgba(0, 184, 217, 0.1)',}),
                multiValueLabel: styles => ({...styles, color: 'rgb(0, 184, 217)',}),
            };
        if (value !== undefined && value !== null && !(value instanceof Array) && options && !options.map(item => item.value).includes(value)) {
            options.push({value, label: star_nonexistent_option ? `*${value}*` : value})
        }


        return id ? (
            <>
                <SelectComponent
                    closeMenuOnSelect={false}
                    value={selected_values}
                    onChange={onChange}
                    options={options}
                    searchable
                    styles={customStyles}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isMulti
                    isDisabled={disabled}
                    isRequired={required}
                />
                <input type="hidden" name={id} value={JSON.stringify(selected_values.map(item => item.value))}/>
            </>
        ) : <div>Loading option List</div>

    }

    render() {
        let {id, name, form_state, source, parentSource, creatable, disabled, value, required, show_label = true, is_a_report} = this.props,
            {options} = this.state,
            fieldValue = (options && value) && options.filter(item => value instanceof Array && value.includes(item.value)),
            show_add,
            form = getFormByTable(table_name(parentSource || source))
        // selected_values = options && options.filter(option_item => value.includes(option_item.value)).map(item=>item.value),
        if (form_state === 'details') {
            show_add = false
        } else {
            show_add = source && creatable === undefined;
        }

        return id ? (
            <>
                {this.state.show_detail && this.Modal()}
                {(form_state === 'details' || disabled) ? (
                        <div className='form-control' style={{height: 'auto'}}>
                            {(source && form.name && fieldValue) ?
                                fieldValue.map((item, index) => (<span key={index}>{index ? ', ' : ''}{this.detailsButton(item)}</span>)) :
                                '...'
                            }
                        </div>
                    )
                    : (
                        <div className="input-group" style={{width: '100%'}}>
                            <this.InputField options={options} value={value}/>
                            {(!is_a_report && show_add && form.name) && <div className="input-group-addon">{this.createButton}</div>}
                        </div>
                    )
                }
            </>
        ) : <>Loading option List</>

    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(element_style(MultiSelectField)))