import React from 'react'
import API from '../../../../store/api'
import {global_token} from "../../../../store/actions/pageactions";
import {signOut} from '../../../../store/actions/authactions'
import {server_error} from "../../errors"
import configs, {show_pk_in_list} from "../../../../custom/configs";
import {SelectModal} from "./selectmodal";
import {get_foreign_key, shouldGetForeignKey} from "./utilities";
import authReducer from "../../../../store/reducers/authreducer";


let filter_preprocessor = (state, props) => {
    let
        {filter_reference, form_values} = props
    return {search_params: {[filter_reference]: form_values[filter_reference]}}
}

export class SelectSuper extends SelectModal {
    state = {
        list: null,
        primary_key: null
    }

    get_columns = () => {
        let {source, options = null} = this.props,
            new_options = []
        if (Boolean(options)) {
            options.value && new_options.push(options.value)
            options.name && new_options.push(options.name)
            options.label && new_options.push(options.label)

        } else {
            let source_broken = source.split('.')
            if (source_broken.length === 2) {
                // console.error(`Source broken. Found ${source_broken[1]}`)
                new_options.push(source_broken[1].trim())
            }
        }
        if (new_options.length === 0) {
            new_options = ["l_name", "description", "desc"]
        }
        return new_options
    }
    // filter_preprocessor = (state, props) => {
    //     let {filter_reference, form_values, primary_key_columns} = props
    //     let search_params = {}
    //
    //     console.log('running default filter preprocessor')
    //     if (filter_reference) {
    //         if (form_values[filter_reference])
    //             search_params[filter_reference] = form_values[filter_reference]
    //     }else{
    //         primary_key_columns.forEach(pk => {
    //             if (form_values[pk]) {
    //                 search_params[pk] = form_values[pk]
    //             }
    //         })
    //     }
    //     if (Object.values(search_params))
    //         console.log('Search params made are', search_params, filter_reference, form_values)
    //     return search_params
    // }

    componentDidMount() {
        let {preprocessor, reference, value, filterable} = this.props
        !filterable && this.api_get_options()
        if ((reference !== undefined) && (Boolean(value) === false)) {
            get_foreign_key.bind(this)()
        }
        (preprocessor || filterable) && this.run_preprocessor()
    }

    api_get_options = () => {
        const {source, options, token, id, default_filter = {}, disabled} = this.props,
            {search_params = {}} = this.state,
            value = this.props.value || this.state.value,
            // get_search_params = (search_params || default_filter) ? `&search_params=${escape(JSON.stringify({...search_params, ...default_filter, ...((value && disabled) ? {[id]: value} : {})}))}&source="form_search"` : ''
            get_search_params = (search_params || default_filter) ? `&search_params=${escape(JSON.stringify({...search_params, ...default_filter}))}&source="form_search"` : ''
        if (options instanceof Array) {
            this.setState(_ => ({list: options, value}), _ => {
                this.setState({options: this.allOptions()})
            })
            return
        } else {
            const source_table = source ? source.split('.')[0] : console.log('Source not defined for', this.props)
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;
            // Todo Doesnt return a full list
            let columns = JSON.stringify(this.get_columns())
            API.get(`/api/v1/${source_table}?columns=${columns}&results_per_page=0${get_search_params}`)
                .then(response => {
                    if (response.data && response.data.objects) {
                        this.setState(_ => ({
                            list: response.data.objects,
                            primary_key: response.data.primary_key,
                            primary_keys: response.data.primary_keys,
                            value
                        }), _ => {
                            this.setState({options: this.allOptions()})
                        })
                    } else {
                        /*Todo Check if this is the correct error handing*/
                        console.log('Error occurred', {id, value, source_table})
                        this.props.showAlert(true, response)
                        let columns = this.get_columns()
                        this.setState(_ => ({
                            list: [columns.map(item => ({[item]: 'error'}))],
                            primary_key: columns,
                            value
                        }), _ => {
                            this.setState({options: this.allOptions()})
                        })
                    }
                }, error => {
                    console.log('INNER ERROR IN LIST')
                    if (error.response && error.response.status === 401) {
                        console.log('REDIRECTING TO LOGIN')
                        this.props.signOut()
                        configs.redirect_to_login && this.props.history.push('/?next=' + this.props.match.url)
                    } else {
                        console.log(server_error(error))
                    }
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {preprocessor, filterable, form_values, filter_reference} = this.props,
            checkFilterable = function () {
                if (filter_reference instanceof Array) {
                    let not_equals = filter_reference.map(key => prevProps.form_values[key] !== form_values[key])
                    // console.log('Not equals', form_values, prevProps.form_values, not_equals, not_equals.some(item => item))
                    return not_equals.some(item => item)
                }
                return prevProps.form_values[filter_reference] !== form_values[filter_reference]
            }

        shouldGetForeignKey.bind(this)(prevState, prevProps)
        if ((prevProps && prevProps.value !== this.props.value && preprocessor) || (filterable && checkFilterable())) {
            this.run_preprocessor()
        } else {
            if (prevProps && this.props.isAuthenticated && !prevProps.isAuthenticated) {
                this.update_options_on_search_params()
            }
        }
    }

    update_options_on_search_params = () => {
        let {search_params = {}} = this.state,
            {filterable, preprocessor = filterable ? filter_preprocessor : _ => null} = this.props,
            param_values = Object.values(search_params)
        if (search_params) {
            // filterable && console.log(' params there ', this.props.id, param_values.some(item => Boolean(item)))
            if (filterable && !(param_values.length > 1) && !param_values.some(item => Boolean(item))) {
                this.setState({list: []})
                return
            }
            this.api_get_options()
        }
    }
    /* caroline 09 march 0900 pipeline 08 1100
    * official dress code
    * */
    run_preprocessor = () => {
        let {filterable, preprocessor = filterable ? filter_preprocessor : _ => null} = this.props,
            state_values = preprocessor(this.state, this.props)
        this.setState(_ => state_values, (state_values && Object.keys(state_values).includes('search_params') && JSON.stringify(state_values.search_params) !== JSON.stringify(this.state.search_params)) ? this.update_options_on_search_params : undefined)
    }

    allOptions = () => {
        return (() => {
            let {source, options, value, id} = this.props

            if (this.state.list && !(options instanceof Array)) {
                let description = source ? source.split('.')[1] : console.log('Source not defined for', this.props),
                    option_value_object_name = (options && options.value !== undefined && options.value.trim()) || this.state.primary_key,
                    label_column = (() => {
                        if (options && options.name) {
                            return options.name
                        } else if (description) {
                            return description
                        } else {
                            let source_broken = source.split('.')
                            if (source_broken.length === 2) {
                                return source_broken[1].trim()
                            } else {
                                return false
                            }
                        }
                    })(),

                    createSingleOption = (item, index) => {
                        let option_value = item[option_value_object_name] || item[id],
                            option_name = label_column ? item[label_column] : item.description || item.desc || item[Object.keys(this.state.list[0]).filter(item => item !== this.state.primary_key)[0]],
                            {show_pk_in_list: show_pk = true} = this.props,
                            label = (option_value === option_name || !show_pk_in_list || !show_pk) ? option_name : `${option_value} | ${option_name}`
                        // logSimilar(option_value, value)
                        // return <option key={index} value={option_value}>{option_value} | {option_name}</option>
                        return {label: label, value: option_value}
                    }

                options = this.state.list.map(createSingleOption).filter(item => item.value)

            } else if (options instanceof Array) {
                options = options.map(item => {
                    return {label: item.name || item.label || item.value, value: item.value}
                })
            } else {
                options = []
            }
            // if (this.constructor.name !== 'MultiSelectField') {
            // if (value !== undefined && value !== null && !(value instanceof Array) && !options.map(item => item.value).includes(value)) {
            //     options.push({value, label: star_nonexistent_option ? `*${value}*` : value})
            // }
            // }
            if (this.props.form_state === 'search') {
                options.push({value: 'null', label: 'NULL'})
            }
            // else { Todo Adding non existent value to options in case of multiselect. Seems to be DONE in Multiselect itself
            // if (value) {
            //     let not_included = options.filter(option => !value.includes(option.value)),
            //         values_not_in_options = value.filter(val => !options.map(item => item.value).includes(val))
            //     values_not_in_options.forEach(item => options.push({value:item, label:star_nonexistent_option ? `*${item}*` : item}))
            //     console.log('Multiselect value is ', value, values_not_in_options)
            // }
            // }
            return options.filter((option, index, self) =>
                index === self.findIndex((t) => (
                    t.value === option.value
                ))
            )
        })()

    }

    createOptions = () => {
        let showOption = (option, index) => {
                let label = () => {
                    if (option.label) {
                        return option.value !== option.label && `${option.value} | ${option.label || option.value}`
                    } else {
                        return option.value
                    }
                }
                return (<option key={index} value={option.value}>{label()}</option>)
            },
            all_options = this.allOptions()
        return all_options ? all_options.map(showOption) : null
    }


    // render3() {
    //     const {id, name, handleChange, value, required} = this.props
    //
    //     return id ? (
    //         <div className="form-group-sm">
    //             <label className="control-label" htmlFor={id}>{name} {value && `(${value})`}</label>
    //             <select className="form-control" id={id} name={name || id} onChange={handleChange} value={value || 'disabled'} required={required}>
    //                 <option disabled="disabled" value="disabled">--Select Option--</option>
    //                 {this.createOptions()}
    //             </select>
    //         </div>
    //     ) : <div>Loading option List</div>
    // }
}

export const mapStateToProps = (state, ownProps) => {
    const {token = global_token} = state.authReducer
    return {
        token,
        ...state.authReducer,
        ...ownProps
    }
};

export const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
};

