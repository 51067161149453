import React from "react";
import {show_link_in_table, show_pk_in_table} from "../../../../custom/configs";
import {TextFieldSuper} from "./textfieldsuper";
import {SelectModal} from "./selectmodal";
import {getFormByTable, table_name, element_id} from "../../table/utilities";
import {comma_currency} from "../../../utilities";
import {defined} from "./utilities";

export class RowValue1 extends TextFieldSuper {
    render() {
        let
            {value} = {...this.props, ...this.state},
            {form_values, reference} = this.props
        return <span className="text-sm">{show_pk_in_table && `${form_values[reference]} | `}{value}</span>
    }
}

export class RowValue extends TextFieldSuper {
    render() {
        let
            {value, showAlert, fullId, options} = {...this.props, ...this.state},
            source = fullId,
            {form_values, reference} = this.props,
            form = source && getFormByTable(table_name(source)),
            // new_value = form_values[reference],
            new_value = (defined(this.props.value)) ? this.props.value : (this.state && this.state.value), // form_values[reference],

            item = {
                label: `${(show_pk_in_table && new_value) ? `${new_value} | ` : ''}${value || ''}`,
                value: new_value
            }
        if (this.props.source) {
            let
                {source} = this.props,
                params = {fullId: source, id: element_id(source), reference: element_id(fullId), parentSource: source, form_values: {[element_id(fullId)]: value}, showAlert, table: form.table}
            return <this.constructor {...params}/>
        }
        if (source && form.name && value !== undefined) {
            if (this.props.type === 'currency')
                return item.label && comma_currency(item.label)
            return (show_link_in_table && value && item.value) ? <SelectModal item={item} showAlert={showAlert} source={source} primary_key_columns={[reference]}/> : <span>{item.label || item.value}</span>
        } else {
            if (options && options.length) {
                let found = options.find(option => option.value === new_value)
                return found ? (found.name || found.label || found.value.toString()) : null
            } else {
                if (this.props.type === 'currency')
                    return comma_currency(new_value) || item.label
                return (<span>{new_value || item.label}</span>)
            }
        }
    }
}

// const aggregation = (baseClass, ...mixin) => {
//     class base extends baseClass {
//         constructor (...args) {
//             super(...args);
//             mixin.forEach((mixin) => {
//                 copyProps(this,(new mixin));
//             });
//         }
//     }
//     let copyProps = (target, source) => {  // this function copies all properties and symbols, filtering out some special ones
//         Object.getOwnPropertyNames(source)
//               .concat(Object.getOwnPropertySymbols(source))
//               .forEach((prop) => {
//                  if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length|props)$/))
//                     Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
//                })
//     }
//     mixin.forEach((mixin) => { // outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
//         copyProps(base.prototype, mixin.prototype);
//         copyProps(base, mixin);
//     });
//     return base;
// }
//
//
// export class Aggregate extends aggregation(SelectModal, TextFieldSuper){
//     render() {
//         let
//             {source, value} = {...this.props, ...this.state},
//             {form_values, reference} = this.props,
//             form = source && getFormByTable(table_name(source)),
//             item = {label:form_values[reference], value: value}
//         return <SelectModal item={item}/>
//     }
// }

