import {can} from "../../../roles";
import {Actions, confirmAction} from "../../../../components/action_utilities";
import pvupdate_form from "./pv_update_form"


let handleApproval = (e, record, others) => {
        return {
            form_state: 'update',
            record,
            show_modal: true,
            form: pvupdate_form
        }
    }


export default requestor => Actions(record => [
    requestor==='finance' &&  record.received_by === null && {name:'Receive', fa: 'pencil', action: (e, record, others) => confirmAction(e, record, {act: 'receive', bool: true, model: 'payment_request', primary_key: record['request_no'], ...others}), color: 'primary', roles: can('receive', 'payment_request')},
    requestor==='finance' && record.status==='RECEIVED BY FINANCE' && record.received_by != null && {name:'Generate PV', fa: 'pencil', action: (e, record, others) => confirmAction(e, record, {act: 'generate_pv', bool: true, model: 'payment_request', primary_key: record['request_no'], ...others}), color: 'primary', roles: can('generate_pv', 'payment_request')},
    requestor==='finance' && record.status==='PV GENERATED' && record.received_by != null && record.approved_by===null && {name:'Check PV', fa: 'pencil', action: handleApproval, color: 'primary', roles: can('generate_pv', 'payment_request')},
    // requestor==='md' && record.approved_by === null && record.received_by != null && {name:'Approve', fa: 'pencil', action: (e, record, others) => confirmAction(e, record, {act: 'approve', bool: true, model: 'payment_request', primary_key: record['request_no'], ...others}), color: 'primary', roles: can('approve', 'payment_request')}
    record.prepared_by!==null && record.checked_by===null && {name:'Check PV', fa: 'pencil', action: handleApproval, color: 'primary', roles: can('generate_pv', 'payment_request')},

    ]
)