import {PureComponent} from 'react'
import {signOut} from '../../../../store/actions/authactions'
import {get_foreign_key, shouldGetForeignKey} from "./utilities";

export const
    mapStateToProps = (state, ownProps) => {
        return {
            ...ownProps
        }
    }, mapDispatchToProps = (dispatch) => {
        return {
            signOut: () => dispatch(signOut()),
        }
    };

export class TextFieldSuper extends PureComponent {
    state = {}

    componentDidMount() {
        let {type, preprocessor, reference, value} = this.props
        if ((type === 'reference' || reference !== undefined) && (Boolean(value) === false)) {
            get_foreign_key.bind(this)()
        }
        preprocessor && this.run_preprocessor()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let {preprocessor} = this.props
        shouldGetForeignKey.bind(this)(prevState, prevProps)
        if (preprocessor) {
            if (prevProps.form_values !== this.props.form_values) {
                preprocessor && this.run_preprocessor()
            }
        }
    }

    run_preprocessor = () => {
        this.setState(this.props.preprocessor.bind(this)(this.state, this.props))
    }


}
