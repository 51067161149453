import React from 'react'

import Logo from './logo'
import TopNavigation from './topnav'

const index = (props) => {
    return (
        <header className="main-header">
            <Logo/>
            <TopNavigation {...props}/>
        </header>
    )
};


export default index