import React from 'react'
import {TextFieldSuper} from "./textfieldsuper";
import {string_processor} from "../../table/utilities";
import element_style from "./element_style";

export default element_style(class TextArea extends TextFieldSuper {
    render() {
        let {id, required, form_state, disabled, handleChange, attributes={}} = {...this.props, ...this.state},
            {name = (id && string_processor(id)) || "-"} = this.props,
            value = this.props.value || this.state.value || undefined
        return (
            <>
                {form_state !== 'details' && <textarea className="form-control" id={id} name={name} {...attributes} onChange={handleChange} value={value} required={required} disabled={disabled}/>}
                {form_state === 'details' && <div className='form-control'><pre>{value || '...'}</pre></div>}
            </>
        )
    }
})




