import account_groups from "./chart_of_accounts/account_groups";
import account_category from "./chart_of_accounts/account_category";
import account_sub_category from "./chart_of_accounts/account_sub_category";
import account_gl from "./chart_of_accounts/account_gl";
import transactions from "./chart_of_accounts/transactions";
import journal_entries from "./chart_of_accounts/journal_entries";
import profit_loss from "./chart_of_accounts/profit_loss";
import balance_sheet from "./chart_of_accounts/balance_sheet";
import cash_flow from "./chart_of_accounts/cash_flow";
import trial_balance from "./chart_of_accounts/trial_balance";
import trial_balance_transactions from "./chart_of_accounts/trial_balance/transactions";
import financial_year, {financial_year_closing_balance} from "./chart_of_accounts/financial_year";
import vote_heads from "./budget/vote_heads";
import budget from "./budget/budget";
import incoming_payments from "./banking/incoming_payments";
import budget_expenditure from "./budget/budget_expenditure";
import outgoing_payments from "./banking/outgoing_payments";
import payment_request from "./payment_requests/payment_request";
import payment_voucher from "./payment_requests/payment_voucher";
import cash_flow_setup from "./chart_of_accounts/cash_flow/setup";

export default [
    account_groups,
    account_category,
    account_sub_category,
    account_gl,
    transactions,
    journal_entries,
    profit_loss,
    balance_sheet,
    cash_flow,
    trial_balance,
    trial_balance_transactions,
    financial_year,
    vote_heads,
    budget,
    incoming_payments,
    budget_expenditure,
    outgoing_payments,
    payment_request(),
    payment_voucher,
    cash_flow_setup,
    financial_year_closing_balance
]