import React from 'react'
import {FormProperties} from '../../../custom/forms'
import {hide_reference_column} from "../../../custom/configs";

const no_form = {
    type: 'table',
    table: null,
    lines: [
        [{type: 'heading', name: <div style={{textAlign: 'center'}}>No form has been created for the target</div>, span: 3}]
    ],
    readonly: true
}

export const getForm = (target) => {
    return FormProperties.find(obj => obj.name === target) || no_form
}
export const getFormByTable = (table_name) => {
    return FormProperties.find(obj => obj.table === table_name) || no_form
}

export const generateHeaders = (target, search_params = undefined) => {
    return formToHeaders(getForm(target), search_params)
}


export const formToHeaders = (form, search_params = undefined) => {
    let combined_form_lines = form ? [].concat.apply([], form.lines) : null // Combine array of arrays
    if (combined_form_lines) { // Confirm header has an id element
        combined_form_lines = combined_form_lines.filter(item => 'id' in item && !['table', 'heading'].includes(item.type)).filter(item => !(item.type==='custom' && Boolean(item.showInTable) === false))
    }

    combined_form_lines = removeCalculated(combined_form_lines)
    // combined_form_lines = removeDisabled(combined_form_lines)
    combined_form_lines = hideReferences(combined_form_lines)
    if (search_params && combined_form_lines) {
        Object.keys(search_params).forEach(search_param => {
            combined_form_lines.filter(item => element_id(item.id) === search_param).forEach(item => item.checked = false)
            combined_form_lines.filter(item => 'reference' in item && element_id(item.reference) === search_param).forEach(item => item.checked = false)
            combined_form_lines.filter(item => !('reference' in item) && item.type === 'reference' && 'payroll_no' in search_params).forEach(item => item.checked = false)
        })

    }

    return {/*headers: combined_form_lines.slice(0, 15),*/ headers_all: combined_form_lines}
}

const removeCalculated = (headers) => {
    return headers.filter(item => (item.showInTable !== undefined && item.showInTable) || !(['calculated'].includes(item.type && item.type.trim())))
}

const removeDisabled = (headers) => {
    return headers.filter(item => !(item.showInTable === undefined && item.disabled && item.disabled))
}

const hideReferences = (headers) => {
    if (hide_reference_column) {
        let
            mustShowInTable = headers.filter(item => item.showInTable && item.showInTable === true).map(item => element_id(item.id)),
            ids_referenced = headers.filter(item => (item.reference) && !mustShowInTable.includes(element_id(item.reference))).map(item => element_id(item.reference)),
            // headers = this.showReferences ? headers : headers.filter(item => !(['calculated', 'reference'].includes(item.type && item.type.trim()) || (item.disabled && item.disabled)))
            not_in_ids_referenced = item => !(ids_referenced.includes(element_id(item.id))),
            show_in_table = item => {
                if (item.showInTable !== undefined) {
                    return item.showInTable
                }
                return true
            }
        headers.filter(item => not_in_ids_referenced(item)).filter(item => show_in_table(item)).slice(0, 15).forEach(item => {
            item.checked = true
        })
    } else {
        headers.filter(item => item.reference === undefined).slice(0, 15).forEach(item => {
            item.checked = true
        })
    }

    return headers
}

export const element_id = (input_box_id) => {
    if (input_box_id === null || input_box_id === undefined) {
        console.log("Input box id is null")
        return ''
    }
    let value = input_box_id.includes('.') ? input_box_id.split('.')[1] : input_box_id
    return value.trim()
}

export const table_name = (input_box_id) => {
    if (input_box_id === null || input_box_id === undefined) {
        return null
    }
    return input_box_id.includes('.') ? input_box_id.split('.')[0].trim() : null
}

// export const element_id = (input_box_id) => {
//     return input_box_id.includes('.') ? input_box_id.split('.')[1] : input_box_id
// }

export const get_record_values = (table_data, record_index, target) => {
    if (record_index !== undefined && record_index !== null) {
        const form_values = table_data.objects[record_index] || table_data.objects[table_data.objects.length - 1]
        // const input_boxes = getInputBoxes(target)
        // let state_values = {}
        //
        // input_boxes.forEach((input_box, index) => {
        //     const input_id = element_id(input_box.id)
        //     state_values[input_id] = form_values[input_id] || ''
        //     if (!(input_box.id in form_values)) {
        //         console.log(`Input box ${input_box.id} not in form_values`)
        //     }
        // })
        // return state_values
        return form_values

    } else if (table_data.objects.length === 1) {
        return table_data.objects[0]
    } else {
        return null
    }
}

export const getInputBoxes = (target) => {
    const form = getForm(target)
    if (form) {
        const combined_form_lines = [].concat.apply([], form.lines)
        return combined_form_lines.filter(input_box => 'id' in input_box)
    } else {
        return null
    }
}

export const get_primary_key_column = (target) => {
    const form = getForm(target)
    return form.primary_key || null
}

export const get_primary_key_value = (record_values, target) => {
    const primary_key_column = get_primary_key_column(target)
    return record_values[primary_key_column]
}


export const calcSize = (line) => {
    // return 4
    switch (line.length) {
        case 1:
            return 4
        case 2:
            return 6
        case 3:
            return 4
        case 4:
            return 3
        case 6:
            return 2
        default:
            return 12
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.toProperCase = function () {
    return this.replace('_', ' ').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const camelcase = string => {
    return string.toProperCase()
}

export const string_processor = (string) => {
    if (string.includes('_')) {
        let new_string = string.toProperCase()
        if (new_string.includes('_')) {
            return string_processor(new_string)
        }
        return new_string
    }
    return string.toProperCase()
}

export const fix_number = (number, total) => {
    if (number !== undefined && number === 0) {
        // console.log('Con 1:THE NUMBER IS ', {number, total})
        return number
    } else if (number < 0) {
        // console.log('Con 2:THE NUMBER IS ', {number:number+total})
        return number + total
    } else {
        // console.log('Con 3:THE NUMBER IS ', {number:number%total, total})
        return number % total
    }
}