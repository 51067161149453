export const server_error = error => {
    if (error.response) {
        if (error.response.data) {
            return error.response.data
        }
        return error.response
    } else {
        try{
            return error.toJSON().message
        }catch (e) {
            console.log(error)
        }
    }
}