
export default {
    type: 'table',
    name: 'budget_expenditure',
    table: 'budget_transactions',
    tab_name: 'Budget Expenditure',
    fa: 'clock',
    title: ['po_no','transaction_id'],
    readonly:true,
    lines: [
        [
            {name:'Department', id:'department_code', type:'text'},
            {name:'Purchase Order', id:'po_no', type:'select',source:'purchase_orders.description',creatable: false, span:1}
        ],[

            {name:'Transaction Date', id:'transaction_date', type:'date'},
            {name:'Financial', id:'year', type:'text'},
            {name:'Amount Spent', id:'amount_drawn', type:'currency'},
            ]
    ],
    totals: [
        {
            'amount_drawn': function (objects) {
                let ret = objects.map(record => (record['amount_drawn'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'vat_calc': function () {
                return ''
            },
            'year': function () {
                return 'TOTAL'
            }
        },
    ]
}