import account_category from "./account_category";

export default {
    type: 'table',
    name: 'account_group',
    table: 'account_group',
    tab_name: 'Accounts Types(s)',
    fa: 'clock',
    lines: [
        [
            {name: 'Group Code', id: 'group_code', type: 'text', required: true},
            {name: 'Description', id: 'description', type: 'text', required: true},
            {name: 'TB Side', id: 'transaction_type', type: 'select', source: 'transaction_types.transaction_type', required: true, span:1}
        ], [
        ], [
            {...account_category, below: true}
        ]
    ]
}